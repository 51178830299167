import { combineReducers } from '@reduxjs/toolkit';
import {
  AnyFormsAction,
  FormsActionTypes,
  FormsState,
  FormValueEntries,
  MultiValueUpdatePayload,
  SingleValueUpdatePayload
} from './types';
import _omit from 'lodash/omit';

// Generic action creators
export const formsSetFormValue = (data: SingleValueUpdatePayload) => ({
  data,
  type: FormsActionTypes.SET_FORM_VALUE
});

export const formsSetFormValues = (data: MultiValueUpdatePayload) => ({
  data,
  type: FormsActionTypes.SET_FORM_VALUES
});

export const formsClearForm = (data: string) => ({
  data,
  type: FormsActionTypes.CLEAR_FORM
});

export function list(
  list: Record<string, FormValueEntries> = {},
  action: AnyFormsAction
): Record<string, FormValueEntries> {
  switch (action.type) {
    case FormsActionTypes.CLEAR_FORM:
      return _omit(list, action.data);
    case FormsActionTypes.SET_FORM_VALUE: {
      const { formName, value, valueName } = action.data;
      return {
        ...list,
        [formName]: { ...list[formName], [valueName]: value }
      };
    }
    case FormsActionTypes.SET_FORM_VALUES: {
      const { formName, values } = action.data;
      return {
        ...list,
        [formName]: values
      };
    }
    default:
      return list;
  }
}

export function ids(ids: string[] = [], action: AnyFormsAction): string[] {
  switch (action.type) {
    case FormsActionTypes.CLEAR_FORM:
      return ids.filter((id) => id === action.data);
    case FormsActionTypes.SET_FORM_VALUES:
      // eslint-disable-next-line no-case-declarations
      const { formName } = action.data;
      return ids.includes(formName) ? ids : [...ids, formName];
    default:
      return ids;
  }
}

export const forms = combineReducers<FormsState>({ list, ids });
