import { FILE_UPLOAD_ERROR } from './types';
import { readFileHandler } from './util';

export const useFileUploadManager = (
  handleUpload: (file: File) => Promise<void>,
  supportedExtensions?: string[],
  requiredCsvHeaders?: string[]
) => {
  const uploadFile = async (file: FileList) => {
    const fileItem = file.item(0);

    if (!fileItem) return Promise.reject(FILE_UPLOAD_ERROR.BAD_FILE_FORMAT);

    if (supportedExtensions && !supportedExtensions.some((ext) => fileItem.name.endsWith(ext))) {
      return Promise.reject(FILE_UPLOAD_ERROR.BAD_FILE_TYPE);
    }

    await readFileHandler(file, requiredCsvHeaders);

    try {
      return handleUpload(fileItem);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log('Upload file server error', error);
      return Promise.reject(FILE_UPLOAD_ERROR.SERVER_ERROR);
    }
  };

  return { uploadFile };
};
