// Global
import React from 'react';
import './styles.scss';

// Components
import { Anchor } from 'components/Anchor';
import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { ModalFileUploadEnrollment } from 'modals/ModalFileUploadEnrollment';
import { Paragraph } from 'components/Paragraph';

// Types
import { FileDetailsErrorProps } from './types';

// Assets
import alertImage from 'assets/media/alert_outline.svg';
import sampleFile from 'assets/media/enrollment_file_sample.zip';

const FileDetailsError: React.FC<FileDetailsErrorProps> = ({ errorMessage }) => {
  // Hooks - State
  const [isUploadFileModalVisible, setIsUploadFileModalVisible] = React.useState(false);

  const renderError = () => {
    if (!errorMessage) return 'Something went wrong.';

    const errorMessageWithoutBrackets = errorMessage.replace(/(\[\[)|(]])/g, '');

    return (
      <>
        Here&apos;s what went wrong:
        <br />
        {errorMessageWithoutBrackets}
      </>
    );
  };

  return (
    <div className="eb-file_details_error">
      <img alt="Alert image" className="eb-file_details_error-icon" src={alertImage} />
      <Heading align="center" className="eb-file_details_error-heading" size={26} type="h6">
        We couldn&apos;t process this file
      </Heading>
      <Paragraph align="center" className="eb-file_details_error-message">
        {renderError()}
      </Paragraph>
      <Paragraph align="center" className="eb-file_details_error-message">
        More instructions are available in your{' '}
        <Anchor download="enrollment_file_sample" href={sampleFile} intent="secondary">
          File specifications
        </Anchor>
        .
      </Paragraph>
      <Button
        className="eb-file_details_error-button"
        intent="primary"
        onClick={() => setIsUploadFileModalVisible(true)}
      >
        Upload new file
      </Button>

      <ModalFileUploadEnrollment
        hide={() => setIsUploadFileModalVisible(false)}
        isVisible={isUploadFileModalVisible}
      />
    </div>
  );
};

export { FileDetailsError };
