// Global
import React from 'react';

// Util
import {
  getFileRecordActionDisplayName,
  getFileStatusDisplayName,
  getFileStatusTag
} from 'utils/statuses';

// Types
import { TableColumn } from 'components/Table/types';
import { TagStatus } from 'components/TagStatus';
import { FileRecord } from 'services/fileDetails/types';
import { Anchor } from 'components/Anchor';

export const TABLE_COLUMNS: TableColumn<FileRecord>[] = [
  {
    title: 'Row',
    dataIndex: 'rowNumber',
    key: 'rowNumber'
  },
  {
    title: 'Company User ID',
    dataIndex: 'companyUserId',
    width: 172,
    render: (_, { companyUserId, companyCode, userId }) => (
      <div className="eb-file_details-item-id" title={companyUserId}>
        <Anchor href={`/${companyCode}/people/${userId}`}>
          {companyUserId}
        </Anchor>
      </div>
    )
  },
  {
    title: 'Company Code',
    dataIndex: 'companyCode',
    width: 150,
    render: (_, { companyCode }) => (
      <div className="eb-file_details-code" title={companyCode}>
        {companyCode}
      </div>
    )
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: 180,
    render: (_, { name }) => {
      if (!name?.last && !name?.first) return '—';
      const middleName = name?.middle ? ` ${name.middle}` : '';

      return (
        <div className="eb-file_details-item-name">
          {name?.first ?? ''} {middleName} {name?.last ?? ''}
        </div>
      );
    }
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (_, { action }) => getFileRecordActionDisplayName(action) ?? '—'
  },
  {
    title: 'Record status',
    dataIndex: 'status',
    key: 'status',
    render: (_, { recordStatus }) => {
      const tagStatus = getFileStatusTag(recordStatus);
      const fileStatusName = getFileStatusDisplayName(recordStatus);
      return recordStatus ? <TagStatus status={tagStatus}>{fileStatusName}</TagStatus> : '—';
    }
  }
];

export const DATE_FORMAT = 'MMM d, yyyy, hh:mm a';
