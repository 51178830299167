// Global
import React from 'react';
import './styles.scss';

// Components
import { Anchor } from 'components/Anchor';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';

// Types
import { SummaryCardProps } from './types';

const SummaryCard: React.FC<SummaryCardProps> = ({ title, total, tooltip, icon, path, button }) => {
  const renderHeading = () => {
    return (
      <div className="eb-summary_card-heading">
        <Heading size={16} type="h6">
          {title}
        </Heading>
        {tooltip && (
          <Tooltip
            className="eb-summary_card-heading-tooltip"
            pointer="bottomLeft"
            tooltipContent={tooltip}
          >
            <Icon name="info" />
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <div className="eb-summary_card">
      {renderHeading()}
      <div className="eb-summary_card-summary">
        <div className="eb-summary_card-summary-info">
          {icon && <Icon className="eb-summary_card-summary-info-icon" name={icon} />}
          <span className="eb-summary_card-summary-info-total">{total}</span>
        </div>
        {path && (
          <Anchor className="eb-summary_card-summary-link" path={path}>
            View
          </Anchor>
        )}
        {button}
      </div>
    </div>
  );
};

export { SummaryCard };
