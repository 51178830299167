// Global
import './styles.scss';
import React, { FC } from 'react';

// Misc
import clsx from 'clsx';
import { getInitials } from 'utils/getInitials';

// Types
import { IconAccountProps } from './types';

// Component
const IconAccount: FC<IconAccountProps> = ({ id, className, firstName, lastName }) => {
  // Vars
  const color = id + 1 < 6 ? String(id + 1) : String((id + 1) % 5);
  const classes = clsx(
    'eb-icon_account',
    {
      'eb-icon_account-color-1': color === '1',
      'eb-icon_account-color-2': color === '2',
      'eb-icon_account-color-3': color === '3',
      'eb-icon_account-color-4': color === '4',
      'eb-icon_account-color-5': color === '5' || color === '0'
    },
    className
  );
  const initials = getInitials(firstName, lastName);

  // Render
  return <div className={classes}>{initials}</div>;
};

export { IconAccount };
