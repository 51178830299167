// Globals
import React from 'react';
import './styles.scss';

// Components
import { DropdownFilter } from 'components/DropdownFilter';
import { Icon } from 'components/Icon';

// Misc
import clsx from 'clsx';
import { getPaginationOptions } from './util';
import onKeyDown from '@isubscribed/fat-tony/onKeyDown';

// Types
import { DropdownEvent } from 'components/DropdownFilter/types';
import { PaginationProps } from './types';

// Const
const MIN_PAGE_SIZE = 25;
const DEFAULT_OPTIONS = [
  { label: '25 per page', value: 25 },
  { label: '50 per page', value: 50 },
  { label: '100 per page', value: 100 }
];

// Component
function Pagination({
  pagesTotal,
  itemsTotal,
  pageSize = MIN_PAGE_SIZE,
  setPageSize,
  setPage,
  page = 0
}: PaginationProps) {
  // Vars
  const pages = getPaginationOptions(page, pagesTotal);
  const itemFirst = page * pageSize + 1;
  const itemLast = itemFirst + pageSize > itemsTotal ? itemsTotal : page * pageSize + pageSize;

  // Handlers
  function handlePageOnClick(page: string) {
    if (page === '...') {
      return;
    }
    // Pages in UI start at 1 but pages from API are indexed at 0, so subtract 1
    setPage(Number.parseInt(page, 10) - 1);
  }
  const handleNumberOnKeyDown = (
    event: React.KeyboardEvent<HTMLSpanElement>,
    mappedPage: string
  ) => {
    onKeyDown([
      {
        event,
        key: 'Enter',
        handler: () => handlePageOnClick(mappedPage)
      }
    ]);
  };
  const handleIconOnKeyDown = (event: React.KeyboardEvent, increment: number) => {
    onKeyDown([
      {
        event,
        key: 'Enter',
        handler: () => handleIconOnClick(increment)
      }
    ]);
  };

  function handleDropdownOnChange(event?: DropdownEvent) {
    setPageSize((event?.dropdownFilter?.value as number) ?? MIN_PAGE_SIZE);
    setPage(0);
  }

  // Util
  function formatIconClass(isDecrementIcon?: boolean) {
    const disabled = isDecrementIcon ? page === 0 : page === pagesTotal - 1;
    return clsx('eb-table_pagination-control-chevron', {
      'eb-table_pagination-control-chevron-disabled': disabled
    });
  }

  function handleIconOnClick(increment: number) {
    // Decrement
    if (increment < 0 && page === 0) {
      // First page - cannot decrement
      return;
    }
    // Increment
    if (increment > 0 && page + 1 === pagesTotal) {
      // Last page - cannot increment
      return;
    }
    setPage(page + increment);
  }

  // Render
  if (itemsTotal < MIN_PAGE_SIZE) return null;

  return (
    <div className="eb-pagination">
      <div className="eb-pagination-items-per-page">
        {itemFirst} — {itemLast} of {itemsTotal}
      </div>
      <div className="eb-pagination-control">
        <Icon
          className={formatIconClass(true)}
          name="arrow_left"
          onClick={() => handleIconOnClick(-1)}
          onKeyDown={(event) => handleIconOnKeyDown(event, -1)}
          role="button"
        />
        {pages.map((mappedPage, index) => {
          const classes = clsx('eb-pagination-control-page', {
            'eb-pagination-control-page-selected': mappedPage === (page + 1).toString(),
            'eb-pagination-control-page-ellipsis': mappedPage === '...'
          });
          return (
            <span
              className={classes}
              key={`${mappedPage}/${index.toString()}`}
              onClick={() => handlePageOnClick(mappedPage)}
              onKeyDown={(event) => handleNumberOnKeyDown(event, mappedPage)}
              role={mappedPage === '...' ? undefined : 'button'}
              tabIndex={0}
            >
              {mappedPage}
            </span>
          );
        })}
        <Icon
          className={formatIconClass()}
          name="arrow_right"
          onClick={() => handleIconOnClick(1)}
          onKeyDown={(event) => handleIconOnKeyDown(event, 1)}
          role="button"
          tabIndex={0}
        />
      </div>
      <DropdownFilter
        onChange={handleDropdownOnChange}
        options={DEFAULT_OPTIONS}
        value={pageSize}
      />
    </div>
  );
}

export { Pagination };
