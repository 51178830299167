import React from 'react';
import { Input } from 'components/Input';

interface RowCheckboxProps {
  checked: boolean;
  disabled?: boolean;
  name: string;
  onChange(isChecked: boolean): void;
}

const RowCheckbox: React.FC<RowCheckboxProps> = ({ checked, disabled, name, onChange }) => {
  // Handlers
  const handleClick = (e: React.MouseEvent) => e.stopPropagation();

  const handleChange = React.useCallback(
    (e: React.ChangeEvent) => {
      const { checked } = e.target as HTMLInputElement;
      onChange(checked);
    },
    [onChange]
  );

  // Render
  return (
    <div className="eb-table-row-checkbox" onClick={handleClick}>
      <Input
        checked={checked}
        disabled={disabled}
        name={name}
        onChange={handleChange}
        type="checkbox"
      />
    </div>
  );
};

export { RowCheckbox };
