// Globals
import './styles.scss';
import React from 'react';
import { PasswordTooltipProps, ValidationProps } from './types';

// Misc
import clsx from 'clsx';
import FormContext from 'components/Form/context';

// Constants
const LOWER = /[a-z]/;
const UPPER = /[A-Z]/;
const NUMBER = /\d/;
const SYMBOL = /[^a-z\d\s]/i;

// Sub-component
const Validation: React.FC<ValidationProps> = ({ children = '', isValid }) => {
  const classes = clsx('eb-password_tooltip-validation-indicator', {
    'eb-password_tooltip-validation-indicator-valid': isValid
  });
  return (
    <div className="eb-password_tooltip-validation">
      <div className={classes} />
      {children}
    </div>
  );
};

// Component
const PasswordTooltip: React.FC<PasswordTooltipProps> = ({ className, isFocused }) => {
  // Hooks - context
  const {
    values: [values]
  } = React.useContext(FormContext);

  // Vars
  const classes = clsx(
    'eb-password_tooltip',
    {
      'eb-password_tooltip-hidden': !isFocused
    },
    className
  );

  const password: string = values.password || '';

  // Render
  return (
    <div className={classes}>
      <Validation isValid={LOWER.test(password)}>1 lowercase character</Validation>

      <Validation isValid={SYMBOL.test(password)}>1 symbol</Validation>

      <Validation isValid={password?.length >= 8}>8+ characters</Validation>

      <Validation isValid={UPPER.test(password)}>1 uppercase character</Validation>

      <Validation isValid={NUMBER.test(password)}>1 number</Validation>
    </div>
  );
};

export { PasswordTooltip };
