import * as endpoints from 'endpoints';
import { ApiDomain } from 'services/api/types';
import { eb } from 'services/api/async';
import { EbThunk } from 'store/types';
import { setApiSpecs } from "./reducers";

doGetPartnerApiSpec.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.PARTNER_SPEC_GET,
  method: 'POST'
};

export function doGetPartnerApiSpec(): EbThunk<Promise<void>> {
  return async (dispatch) => {
    const {
      data: { endpoint, method }
    } = doGetPartnerApiSpec;

    const response = await dispatch(
      eb<any>({
        endpoint,
        method
      })
    );
    dispatch(setApiSpecs(response));
  };
}
