// Global
import React from 'react';
import './styles.scss';

// Components
import { AriaLink } from 'components/AriaLink';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { TagStatus } from 'components/TagStatus';

// Misc
import clsx from 'clsx';
import { FilListItemProps } from './types';
import { getFileStatusDisplayName, getFileStatusTag } from 'utils/statuses';
import { isFileClickable } from './util';

const FileListItem: React.FC<FilListItemProps> = ({
  fileId,
  fileName,
  companyCode,
  className,
  icon,
  status,
  updated,
  errorsCount = 0,
  footer
}) => {
  // Vars
  const classes = clsx('eb-file_list_item', className);
  const tagStatus = getFileStatusTag(status, errorsCount);
  const fileStatusName = getFileStatusDisplayName(status);
  const isClickable = isFileClickable(tagStatus, updated);

  const children = (
    <>
      {icon && (
        <div className="eb-file_list_item-icon">
          <Icon name={icon} />
        </div>
      )}
      <div className="eb-file_list_item-info">
        <Heading className="eb-file_list_item-info-heading" size={18} type="h6">
          {fileName}
        </Heading>
        {footer}
      </div>
      {fileStatusName && (
        <TagStatus className="eb-file_list_item-status" status={tagStatus}>
          {fileStatusName}
        </TagStatus>
      )}
    </>
  );

  return isClickable && companyCode ? (
    <AriaLink className={classes} path={`/${companyCode}/file-management/${fileId}`}>
      {children}
    </AriaLink>
  ) : (
    <div className={classes}>{children}</div>
  );
};

export { FileListItem };
