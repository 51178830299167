// Globals
import './styles.scss';
import React from 'react';

// Components
import { Anchor } from 'components/Anchor';
import { ButtonToggle } from 'components/ButtonToggle';
import { Heading } from 'components/Heading';
import { ModalFileUpload } from 'modals/ModalFileUpload';
import { Paragraph } from 'components/Paragraph';

// Service
import { getOrganizationIsProdEnv } from 'services/organizations/selectors';
import { doUploadFile } from 'services/upload/async';

// Misc
import { useFileUploadManager } from 'hooks/useFileUploadManager';
import { useDispatch, useSelector } from 'react-redux';

// Types
import { EbDispatch } from 'store';
import { EnvType } from 'services/upload/types';
import { ModalFileUploadEnrollmentProps } from './types';

// Assets
import sampleFile from 'assets/media/enrollment_file_sample.zip';

const buttonToggleOptions = [
  {
    label: 'Test file',
    value: EnvType.TEST
  },
  {
    label: 'Submit file',
    value: EnvType.PROD
  }
];
const FILE_EXTENSIONS = ['.csv', '.csv.pgp'];
// Allows  to upload V3, V2 and V1 enrollment files
const REQUIRED_CSV_HEADERS = ['firstname', 'lastname'];

// Component
const ModalFileUploadEnrollment: React.FC<ModalFileUploadEnrollmentProps> = ({
  hide,
  isVisible,
  onSuccessClose
}) => {
  // Hooks
  const dispatch: EbDispatch = useDispatch();

  // Hooks - selectors
  const isProdEnv = useSelector(getOrganizationIsProdEnv);

  // Hooks - state
  const [env, setEnv] = React.useState<string>(EnvType.TEST);

  // Hook - useEffect
  React.useEffect(() => {
    setEnv(isProdEnv ? EnvType.PROD : EnvType.TEST);
  }, [isProdEnv]);

  // Handlers
  const handleUpload = React.useCallback(
    async (file: File) => {
      return dispatch(doUploadFile(file, env as EnvType));
    },
    [env, dispatch]
  );

  // Hooks - custom
  const { uploadFile } = useFileUploadManager(handleUpload, FILE_EXTENSIONS, REQUIRED_CSV_HEADERS);

  const renderHeader = () => (
    <div className="eb-modal_file_upload_enrollment-button-toggle">
      <ButtonToggle
        active={env}
        disabled={!isProdEnv}
        onClick={setEnv}
        options={buttonToggleOptions}
      />
    </div>
  );

  const renderFooter = () => {
    return (
      <div>
        <Heading size={18} type="h3">
          Updating enrollment
        </Heading>

        {isProdEnv ? (
          <Paragraph className="eb-modal_file_upload_enrollment-paragraph" size={14}>
            Select <strong>Submit</strong> to make changes to enrollment, or <strong>Test</strong>{' '}
            the file first to be sure of its results. Please add all entries in the template we
            provide, then export it in CSV format.{' '}
            <Anchor download="sample_file.zip" href={sampleFile} intent="secondary">
              Download template
            </Anchor>
          </Paragraph>
        ) : (
          <Paragraph className="eb-modal_file_upload_enrollment-paragraph" size={14}>
            Please add all entries in the template we provide, then export it in CSV format. Since
            you&apos;re in Onboarding, this file won&apos;t impact enrollment.{' '}
            <Anchor download="sample_file.zip" href={sampleFile} intent="secondary">
              Download template
            </Anchor>
          </Paragraph>
        )}
      </div>
    );
  };

  return (
    <ModalFileUpload
      footer={renderFooter()}
      handleUploadFile={uploadFile}
      header={renderHeader()}
      hide={hide}
      isVisible={isVisible}
      onSuccessClose={onSuccessClose}
      title="Upload an Enrollment file"
    />
  );
};

export { ModalFileUploadEnrollment };
