// Global
import React from 'react';
import './styles.scss';

// Components
import { Button } from 'components/Button';
import { InputCode } from 'components/InputCode';
import { Form } from 'components/Form';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';

// Types
import { MfaCodeProps } from './types';

// Assets
import image from 'assets/media/tech-support.svg';

const MfaCode: React.FC<MfaCodeProps> = ({ sendMfaCode, isPending }) => {
  const [error, setError] = React.useState<string | undefined>();
  const [values, setValues] = React.useState<string[]>([]);

  // Handlers
  const onSubmit = async () => {
    // Filter any empty string
    const nonEmptyNumericValuesList = values.filter((value) => Boolean(value.length));
    if (nonEmptyNumericValuesList.length < 6) {
      setError('Must be a 6 digit numeric code');
      return;
    }

    setError(undefined);
    sendMfaCode(values.join(''));
  };

  return (
    <div className="eb-mfa_code">
      <img alt="MFA code image" className="eb-mfa_code-image" src={image} />
      <Heading align="center" className="eb-mfa_code-heading" size={26} type="h4">
        Check your email
      </Heading>
      <Paragraph className="eb-mfa_code-text">
        Confirm your sign-in with a security code sent to your email address.
      </Paragraph>
      <Form
        error={error}
        id="eb-mfa_code"
        label="Enter the code sent to your device"
        name="eb-mfa_code-form"
        onSubmit={onSubmit}
      >
        <InputCode
          className="eb-mfa_code-inputs"
          error={error}
          isControlled
          numberOfInputs={6}
          onChange={setValues}
        />
        <Button
          className="eb-mfa_code-button"
          display="inline"
          intent="primary"
          isLoading={isPending}
          type="submit"
        >
          Confirm
        </Button>
      </Form>
    </div>
  );
};

export { MfaCode };
