import './styles.scss';
import React from 'react';

// Services
import {
  getEntitlementEbPortalCompanyCode,
  getEntitlementEbUserAccessLevel
} from 'services/auth/selectors';
import { getOrganizationsSelected } from 'services/organizations/selectors';

// Misc
import { AccessLevel } from 'services/auth/types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Home: React.FC = () => {
  // Hooks - selectors
  const userGroupAccessLevel = useSelector(getEntitlementEbUserAccessLevel);
  const userCompanyCodes = useSelector(getEntitlementEbPortalCompanyCode);
  const selectedCompanyCode = useSelector(getOrganizationsSelected);

  let companyCode = selectedCompanyCode ? selectedCompanyCode : userCompanyCodes[0] || '';

  const renderChildren = () => {
    switch (userGroupAccessLevel) {
      case AccessLevel.FULL:
        return <Navigate to="/organizations" />;
      case AccessLevel.HR:
        return <Navigate to={`/${companyCode}/people`} />;
      default:
        return <Navigate to={`/${companyCode}/dashboard`} />;
    }
  };

  return <div className="eb-home">{renderChildren()}</div>;
};

export { Home };
