import { createSelector } from 'reselect';
import { EbState } from 'store';
import {
  FileRecord,
  FileDetailsParamState,
  FileRecordsState,
  FileDetailsState,
  FileSummary
} from './types';

export const initialParams: FileDetailsParamState = {
  pageSize: 100,
  page: 0,
  totalPages: 0,
  total: 0,
  sortField: 'status',
  sortOrder: -1
};

export const initialFileState: FileSummary = {
  fileName: '',
  fileStatus: null,
  fileUploadedAt: ''
};

export const initialRecordsState: FileRecordsState = {
  params: initialParams,
  list: [],
  acceptedRecordCount: 0,
  rejectedRecordCount: 0,
  noActionRecordCount: 0
};

export const getFileDetailsState = createSelector(
  (state: EbState) => state?.fileDetails,
  (fileDetails): FileDetailsState => fileDetails
);

export const getFileDetailsSummary = createSelector(
  getFileDetailsState,
  (fileState) => fileState.summary || {}
);

export const getFileDetailsName = createSelector(
  getFileDetailsSummary,
  (fileSummary): string => fileSummary?.fileName ?? ''
);

export const getFileDetailsStatus = createSelector(
  getFileDetailsSummary,
  (fileSummary) => fileSummary?.fileStatus ?? null
);

export const getFileDetailsUploadedIsoDate = createSelector(
  getFileDetailsSummary,
  (fileSummary): string => fileSummary?.fileUploadedAt ?? ''
);

export const getFileDetailsFailureReason = createSelector(
  getFileDetailsSummary,
  (fileSummary): string => fileSummary?.failureReason ?? ''
);

export const getFileDetailsRecords = createSelector(
  getFileDetailsState,
  (fileState) => fileState.records
);

export const getFileDetailsRecordsList = createSelector(
  getFileDetailsRecords,
  (fileRecordsState): FileRecord[] => fileRecordsState.list
);

export const getFileDetailsParams = createSelector(
  getFileDetailsRecords,
  (fileState): FileDetailsParamState => fileState?.params ?? initialParams
);

export const getFileDetailsParamsPageSize = createSelector(
  getFileDetailsParams,
  (params): number => params.pageSize
);

export const getFileDetailsParamsPage = createSelector(
  getFileDetailsParams,
  (params): number => params.page
);

export const getFileDetailsParamsTotal = createSelector(
  getFileDetailsParams,
  (params): number => params.total || 0
);

export const getFileDetailsParamsTotalPages = createSelector(
  getFileDetailsParams,
  (params): number => params.totalPages
);
