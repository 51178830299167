import { createSelector } from 'reselect';
import { EbState } from '../../store';

export const getAdminUsers = createSelector(
  (state: EbState) => state?.adminUsers,
  (adminUsers) => adminUsers
);

export const getUsers = createSelector(getAdminUsers, (adminUsers) => adminUsers.users);

export const getParams = createSelector(getAdminUsers, (adminUsers) => adminUsers.params);

export const getTotalUsers = createSelector(getParams, (params) => params.total);
