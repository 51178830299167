// Globals
import './styles.scss';
import React from 'react';

// Components
import { ErrorServer } from 'components/ErrorServer';
import { Heading } from 'components/Heading';
import { Loading } from 'components/Loading';
import { Pagination } from 'components/Pagination';

// Services
import {
  getEndpointsAllSuccess,
  getEndpointsAnyFailure,
  getEndpointsAnyPending
} from 'services/api/selectors';

// Types
import { ApiRequest } from 'services/api/types';
import { TableListProps } from './types';

// Misc
import clsx from 'clsx';
import { EbDispatch, EbState } from 'store';
import { scrollTo } from 'utils/scrollTo';
import { useDispatch, useSelector } from 'react-redux';

// Custom hooks
function useRequests(requests: Array<ApiRequest>, page: number, pageSize: number) {
  // Hooks
  const dispatch: EbDispatch = useDispatch();

  // Hooks - effects
  React.useEffect(() => {
    // Dispatch requests sequentially to prevent multiple refresh token requests for auth-protected endpoints
    const dispatchRequests = async () => {
      for await (const request of requests) {
        await dispatch(request());
      }
    };
    void dispatchRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);
}

// Component
const TableList: React.FC<TableListProps> = ({
  children,
  className = '',
  empty = null,
  heading = '',
  loadingText = 'Loading...',
  requests,
  selectorItemsTotal,
  selectorItemsTotalWithoutFilters = selectorItemsTotal,
  selectorPage,
  selectorPageSize,
  selectorPagesTotal,
  setPage,
  setPageSize,
  title
}) => {
  // Hooks - selectors
  const allRequestsSuccessful = useSelector((state: EbState) =>
    getEndpointsAllSuccess(state, requests)
  );
  const anyRequestFailure = useSelector((state: EbState) =>
    getEndpointsAnyFailure(state, requests)
  );
  const anyRequestPending = useSelector((state: EbState) =>
    getEndpointsAnyPending(state, requests)
  );

  const itemsTotal: number = useSelector(selectorItemsTotal);
  const itemsTotalWithoutFilters: number = useSelector(selectorItemsTotalWithoutFilters);
  const pagesTotal: number = useSelector(selectorPagesTotal);
  const page: number = useSelector(selectorPage);
  const pageSize: number = useSelector(selectorPageSize);

  // Hooks - effects
  useRequests(requests, page, pageSize);

  // Scroll to top of table when page changes
  React.useEffect(() => {
    scrollTo('eb-layout_page');
  }, [page]);

  // Vars
  const classes = clsx('eb-table_list', className);

  const paginationProps = {
    itemsTotal,
    pagesTotal,
    page,
    pageSize,
    setPage,
    setPageSize
  };

  const renderChildren = () => {
    switch (true) {
      case anyRequestPending:
        return <Loading className="eb-table_list-loading" isCentered>{loadingText}</Loading>;
      case anyRequestFailure:
        return <ErrorServer />;
      default:
      case allRequestsSuccessful: {
        if (itemsTotalWithoutFilters === 0) return empty;

        return (
          <>
            <div className="eb-table_list-body">
              <Heading size={22} type="h2">
                {heading}
              </Heading>
              {children}
            </div>
            <Pagination {...paginationProps} />
          </>
        );
      }
    }
  };

  // Render
  return (
    <div className={classes}>
      {title && itemsTotal > 0 && (
        <Heading className="eb-table_list-heading" size={22} type="h2">
          {title}
        </Heading>
      )}
      {renderChildren()}
    </div>
  );
};

export { TableList };
