import {
  CompanyPeopleRecord,
  MemberDetails,
  PeopleFilters,
  PeopleParamState,
  PeopleState
} from './types';
import { createSelector } from 'reselect';
import { EbState } from 'store';
import { initialMemberState, initialParams } from './initialState';

export const getPeople = createSelector(
  (state: EbState) => state?.people,
  (people): PeopleState => people
);

export const getCurrentCompanyPeople = createSelector(
  getPeople,
  (peopleState): CompanyPeopleRecord[] => peopleState?.list || []
);

export const getPeopleParams = createSelector(
  getPeople,
  (people): PeopleParamState => people?.params ?? initialParams
);

export const getMemberDetails = createSelector(
  getPeople,
  (people): MemberDetails => people.memberDetails ?? initialMemberState
);

export const getPeopleParamsPageSize = createSelector(
  getPeopleParams,
  (params): number => params.pageSize
);

export const getPeopleParamsPage = createSelector(getPeopleParams, (params): number => params.page);

export const getPeopleParamsTotal = createSelector(
  getPeopleParams,
  (params): number => params.total || 0
);

export const getPeopleParamsTotalWithoutFilters = createSelector(
  getPeopleParams,
  (params): number => params.totalWithoutFilters || 0
);

export const getPeopleParamsTotalPages = createSelector(
  getPeopleParams,
  (params): number => params.totalPages
);

export const getPeopleParamsFilters = createSelector(
  getPeopleParams,
  (params): PeopleFilters => params.filters ?? {}
);

export const getPeopleParamsSearch = createSelector(getPeopleParams, (params) => params.search);

export const getPeopleEligibleCount = createSelector(
  getPeople,
  (people): number => people.eligibilityUserCount
);

export const getPeopleEnrolledCount = createSelector(
  getPeople,
  (people): number => people.enrolledUserCount
);
