import './styles.scss';
import React, { useContext, useState } from 'react';

// Components
import { InputCheckbox } from './Checkbox';
import { InputNumber } from './Number';
import { InputPassword } from './Password';
import { InputRadio } from './Radio';
import { InputSelect } from './Select';
import { InputText } from './Text';

// Misc
import clsx from 'clsx';
import { EbTrackingEventType } from 'services/analytics/types';
import FormContext from 'components/Form/context';
import { useTracking } from 'hooks/useTracking';
import { InputProps } from './types';
import { NumberFormatValues } from 'react-number-format';

// Constants
const Types = {
  checkbox: InputCheckbox,
  number: InputNumber,
  password: InputPassword,
  text: InputText,
  select: InputSelect,
  radio: InputRadio
};

// Util
function isCheckbox(type: string) {
  switch (type) {
    case 'checkbox':
    case 'toggle':
      return true;
    default:
      return false;
  }
}

// Component
const Input: React.FC<InputProps> = (props) => {
  const {
    className,
    disabled,
    format,
    icon,
    id,
    inputId,
    inputMode,
    isFormattedValue,
    label,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    mask,
    name,
    onBlur,
    onChange,
    onFocus,
    onInput,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pattern,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    placeholderIsDisabled,
    prefix,
    readOnly,
    thousandSeparator,
    tracking,
    trackingEnabled,
    type = 'text',
    ...otherProps
  } = props;

  // Vars
  let errors = [];
  let isSubmitted: boolean | undefined = false;
  let values = {};
  let setValues: React.Dispatch<React.SetStateAction<Object>> = () => null;

  // Hooks
  const [focus, setFocus] = useState(false);
  const formContext = useContext(FormContext);
  if (formContext) {
    ({
      errors: { [name]: errors },
      isSubmitted,
      values: [values, setValues]
    } = formContext);
  }
  const trackEvent = useTracking();

  // Handlers
  const handleOnFocus = (event: React.FocusEvent) => {
    if (readOnly) return;

    if (trackingEnabled) {
      let prefix = 'inp';

      // don't check focus on select/checkbox/radio/toggle
      switch (type) {
        case 'checkbox':
          return;
        default:
          prefix = 'inp';
      }

      trackEvent({
        ...tracking,
        action: tracking?.action && `${prefix}_${tracking?.action}`,
        event: EbTrackingEventType.ui_click
      });
    }

    if (onFocus) {
      onFocus(event);
    }
    setFocus(true);
  };

  const handleOnBlur = (event: React.FocusEvent) => {
    // Connected to form - trim input value
    const valueByName: any = values[name as keyof typeof values];
    if (formContext && valueByName) {
      const value = typeof valueByName === 'string' ? valueByName.trim() : valueByName;
      setValues({
        ...values,
        [name]: value
      });
    }
    if (onBlur) {
      onBlur(event);
    }
    setFocus(false);
  };

  // Handlers - onChange, non-number inputs
  const handleOnChange = (event: React.ChangeEvent) => {
    // Connected to form - set input value in form
    if (formContext) {
      const input = event.target as HTMLInputElement;
      let { value } = input;

      // Handle checkbox
      if (isCheckbox(input.type)) {
        value = input.checked ? value || 'on' : '';
      }

      // Handle custom select
      if (input.getAttribute?.('data-type') === 'input-autocomplete') {
        // @ts-ignore
        value = event?.autocomplete?.value;
      }

      setValues({ ...values, [name]: value });
    }

    if (onChange) {
      onChange(event);
    }
  };

  // Handlers - onChange, number input
  const handleOnValueChange = (data: NumberFormatValues) => {
    // Connected to form - set input value in form
    if (formContext) {
      const { value, formattedValue } = data;

      const valueToUse = isFormattedValue ? formattedValue : value;
      setValues({ ...values, [name]: valueToUse });
    }
  };

  // Handlers
  // Use onInput as autocomplete value change does not trigger onChange
  const handleOnInput = (event: React.FormEvent) => {
    // Connected to form and InputSelect event - set input value in form
    // @ts-ignore
    if (formContext && event?.autocomplete) {
      // data-attribute instead of "autocomplete"
      const {
        // @ts-ignore
        autocomplete: { name, value }
      } = event;

      setValues({ ...values, [name]: value });
    }
    if (onInput) {
      onInput(event);
    }
  };

  // Vars
  const Component = Types[type];
  const value = values[name as keyof typeof values] ?? '';
  const error = errors?.length > 0 ? errors[0] : '';
  const isError = isSubmitted && error;
  const classes = clsx('eb-input', className, {
    'eb-input-focus': focus,
    'eb-input-error': isError,
    'eb-input-readonly': readOnly
  });
  const inputLabel = `input-label-${label}`;
  const inputProps = {
    className: 'eb-input-control',
    disabled,
    format,
    icon,
    id: inputId,
    inputLabel,
    inputMode,
    name,
    readOnly,
    onBlur: handleOnBlur,
    onChange: handleOnChange,
    onFocus: handleOnFocus,
    onInput: handleOnInput, // Capture autocomplete value change in InputSelect
    prefix,
    value,
    tracking,
    trackingEnabled,
    ...otherProps
  };

  if (type === 'number') {
    inputProps.onValueChange = props.onValueChange ?? handleOnValueChange;
    // @ts-ignore
    inputProps.thousandSeparator = thousandSeparator;
  }

  // Render
  return (
    <div className={classes} id={id}>
      <label>
        {label && (
          <div className="eb-input-label" id={inputLabel}>
            {label}
          </div>
        )}
        <div className="eb-input-container">
          <Component {...inputProps} />
          {isError && <div className="eb-input-error">{error}</div>}
        </div>
      </label>
    </div>
  );
};

export { Input };
