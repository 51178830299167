import * as endpoints from 'endpoints';
import { ApiDomain } from 'services/api/types';
import { eb } from 'services/api/async';
import { EbThunk } from 'store/types';
import { getRefreshTokenUserId } from 'services/auth/selectors';
import { getOrganizationsSelected } from 'services/organizations/selectors';
import {
  FileReviewStatus,
  FilesListRequestParams,
  FileUpdateRequestParams,
  GetFilesListResponse,
  GetOrgFilesResponse,
  OrgFilesRequestParams
} from './types';
import { setCompanyFiles, setFiles, updateListFile } from './reducers';
import { getCompanyFilesParams, getFilesParams } from './selectors';

doGetCompanyFiles.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.ORG_FILES_GET,
  method: 'GET'
};

doListFiles.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.FILES_LIST,
  method: 'POST'
};

doUpdateFileMetadata.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.FILE_UPDATE,
  method: 'POST'
};

export function doGetCompanyFiles(): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      data: { endpoint, method }
    } = doGetCompanyFiles;
    const { page, pageSize, search } = getCompanyFilesParams(getState());
    const companyCode = getOrganizationsSelected(getState());

    const urlParams = new URLSearchParams();
    const params: OrgFilesRequestParams = { page, pageSize, search };

    Object.keys(params).forEach((param: string) => {
      const paramVal = params[param as keyof OrgFilesRequestParams];

      if (paramVal !== undefined) {
        urlParams.append(param, paramVal.toString());
      }
    });

    const files = await dispatch(
      eb<GetOrgFilesResponse>({
        endpoint,
        method,
        params: urlParams,
        pathParams: [companyCode]
      })
    );
    dispatch(setCompanyFiles(files, companyCode));
  };
}

export function doListFiles(): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      data: { endpoint, method }
    } = doListFiles;
    const { page, pageSize, statusColor, uploadedAt, companyCode } = getFilesParams(getState());

    const data: FilesListRequestParams = {
      page,
      pageSize,
      statusColor,
      uploadedAt,
      companyCode
    };

    const response = await dispatch(
      eb<GetFilesListResponse>({
        endpoint,
        method,
        data
      })
    );
    dispatch(setFiles(response));
  };
}

export function doUpdateFileMetadata({
  reviewStatus,
  fileId
}: {
  fileId: string;
  reviewStatus?: FileReviewStatus;
}): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      data: { endpoint, method }
    } = doUpdateFileMetadata;

    const userId = getRefreshTokenUserId(getState());

    const data: FileUpdateRequestParams = {
      id: fileId,
      reviewedBy: userId,
      reviewStatus
    };

    const updatedFile = await dispatch(
      eb<FileUpdateRequestParams>({
        endpoint,
        method,
        data
      })
    );
    dispatch(updateListFile(updatedFile));
  };
}
