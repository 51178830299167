// Globals
import React from 'react';

// Components
import { Organization } from 'components/Organization';

// Services
import { getOrganizationsCodeByPage } from 'services/organizations/selectors';

// Misc
import { useSelector } from 'react-redux';

const OrganizationList: React.FC = () => {
  // Hooks - selectors
  const organization = useSelector(getOrganizationsCodeByPage);

  // Render
  return (
    <div className="eb-organization_list">
      {organization.map((code: string, index: number) => {
        return <Organization code={code} key={`${code}${index}`} />;
      })}
    </div>
  );
};

export { OrganizationList };
