export enum PartnerExternalActionTypes {
  SET_EXTERNAL_ID = 'eb.partnerX.SET_EXTERNAL_ID'
}

// Generic Files Action
export interface PartnerExternalAction<P> {
  type: PartnerExternalActionTypes;
  data: P;
}

export interface PartnerExternalSetExternalIdAction extends PartnerExternalAction<string> {
  type: PartnerExternalActionTypes.SET_EXTERNAL_ID;
}

export type PartnerExternalAllAction = PartnerExternalSetExternalIdAction;

export interface PartnerExternalState {
  externalId: string;
}

export interface PartnerExternalIdUpdateRequest {
  companyCode: string;
  externalId: string;
  newExternalId: string;
}

export const initialState: PartnerExternalState = {
  externalId: ''
};
