import { object, mixed } from 'yup';
import { schemaPassword } from 'schema/password';

const schema = object({
  password: schemaPassword,
  confirm_password: mixed()
    .required('Required')
    .test('match', 'Passwords do not match', function () {
      return this.parent.password === this.parent.confirm_password;
    })
});

export { schema };
