// Globals
import React from 'react';

// Components
import { AriaLink } from 'components/AriaLink';

// Types
import { EbTrackingEventType } from 'services/analytics/types';
import { DropdownItemLinkProps } from './types';

// Misc
import clsx from 'clsx';
import { useTracking } from 'hooks/useTracking';

// Component
const DropdownItemLink: React.FC<DropdownItemLinkProps> = ({
  children = null,
  className = '',
  isDisabled = false,
  keyDownData = [],
  path,
  tracking = null,
  trackingEnabled = false
}) => {
  // Hooks
  const trackEvent = useTracking();

  const handleOnClick = () => {
    const actionName = children?.toString() ?? 'undefined';

    trackEvent({
      ...tracking,
      action_name: actionName,
      action: (tracking?.action && `drp_${tracking.action}`) ?? `drp_${actionName}`,
      event: EbTrackingEventType.ui_click
    });
  };

  // Vars
  const classes = clsx('eb-dropdown_item_link', className);

  // Render
  return (
    <li className={classes}>
      <AriaLink
        className="eb-dropdown_item-container"
        data-type="eb-dropdown_item-container"
        isDisabled={isDisabled}
        keyDownData={keyDownData}
        path={path}
        trackingDisabled
        {...(trackingEnabled && tracking ? { onClick: handleOnClick } : {})}
      >
        {children}
      </AriaLink>
    </li>
  );
};

export { DropdownItemLink };
