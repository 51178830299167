// State
export interface FormsState {
  ids: Array<keyof FormsState['list']>;
  list: Record<string, FormValueEntries>;
}

export type FormValue = string | boolean | number | any[] | Record<string, unknown> | null;

export type FormValueEntries = Record<string, FormValue>;

// ActionTypes and Actions
export enum FormsActionTypes {
  SET_FORM_VALUES = 'forms.SET_FORM_VALUES',
  SET_FORM_VALUE = 'forms.SET_FORM_VALUE',
  CLEAR_FORM = 'forms.CLEAR_FORM'
}

export interface SingleValueUpdatePayload {
  formName: string;
  valueName: string;
  value: string;
}

export interface MultiValueUpdatePayload {
  formName: string;
  values: FormValueEntries;
}

export interface GenericPaypalAction<T> {
  type: FormsActionTypes;
  data: T;
}

export interface FormsSetFormValuesAction extends GenericPaypalAction<MultiValueUpdatePayload> {
  type: FormsActionTypes.SET_FORM_VALUES;
}

export interface FormsSetFormValueAction extends GenericPaypalAction<SingleValueUpdatePayload> {
  type: FormsActionTypes.SET_FORM_VALUE;
}

export interface FormsClearFormAction extends GenericPaypalAction<string> {
  type: FormsActionTypes.CLEAR_FORM;
}

export type AnyFormsAction =
  | FormsClearFormAction
  | FormsSetFormValuesAction
  | FormsSetFormValueAction;
