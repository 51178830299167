import {
  EbTrackingSuperEvent,
  EbTrackingEventParams,
  EbUITrackingEvent
} from 'services/analytics/types';
import { placementMap } from './data';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTracking as useReactTracking } from 'react-tracking';
import _snakeCase from 'lodash/snakeCase';
import { EbDispatch } from 'store';
import { getGenericEbTrackingEvent } from './util';

export function useTracking() {
  // Hooks
  const location = useLocation();
  const dispatch: EbDispatch = useDispatch();
  const { trackEvent } = useReactTracking();

  // Return
  return (props: EbTrackingEventParams) => {
    if (props) {
      // Timestamp of event
      const ts = Date.now();

      // Look for placement label
      const pathName = location.pathname ? _snakeCase(location.pathname) : '';
      const placement = props.placement ?? placementMap[pathName] ?? pathName;

      const genericEvent = dispatch(getGenericEbTrackingEvent());

      // Overrides to genericEvent
      const trackingEvent: EbUITrackingEvent = {
        ...genericEvent,
        action: props.action ?? '',
        action_name: props.action_name ?? '',
        config_source: 'cache',
        details: props.details ?? '',
        error: props.error ?? '',
        error_code: props.error_code ?? '',
        notes: props.notes ?? '',
        placement
      };

      // Uncomment to see events live in console (excludes trackAPEvent events)
      // console.log(
      //   `\n***** useTracking *****`,
      //   `\nEVENT: ${props.event}`,
      //   `\nPLACEMENT: ${trackingEvent.placement}`,
      //   `\nACTION: ${trackingEvent.action}`,
      //   `\nDATA:`,
      //   { event: props.event ?? '', payload: trackingEvent, ts },
      //   '\n\n'
      // );

      const superEvent: EbTrackingSuperEvent = {
        event: props.event ?? '',
        payload: trackingEvent,
        ts
      };

      trackEvent(superEvent);
    }
  };
}
