// Globals
import React from 'react';

// Components
import { BadgeIconText } from './IconText';

// Types
import { BadgeProps, BadgeTypes } from './types';

const Types = {
  iconText: BadgeIconText
};

// Component
const Badge: React.FC<BadgeProps> = ({
  children,
  className = '',
  icon = '',
  status = '',
  type = BadgeTypes.ICON_TEXT
}) => {
  const Component = Types[type];

  const restProps = {
    children,
    className,
    icon,
    status
  };

  return <Component {...restProps} />;
};

export { Badge };
