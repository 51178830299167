// Globals
import React from 'react';

// Components
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { RoutesContext } from './context';

// Services
import {
  getEntitlementEbUserAccessLevel,
  getEntitlementHasDeveloperAccess,
  getEntitlementHasDeveloperAccessOnly,
  getIsRefreshTokenValid
} from 'services/auth/selectors';

// Types
import { RouterProps, RouterRouteProps } from './types';
import { useSelector } from 'react-redux';

/*
 react-router-dom 6
 <Routes> instead of <Switch>
 <Navigate> instead of <Redirect>
 element instead of render
 */

const Router: React.FC<RouterProps> = ({ routes }) => {
  // Hooks
  const location = useLocation();

  // Hooks -selectors
  const isAuthenticated = useSelector(getIsRefreshTokenValid);
  const userGroupAccessLevel = useSelector(getEntitlementEbUserAccessLevel);
  const hasOnlyDevAccess = useSelector(getEntitlementHasDeveloperAccessOnly);
  const hasDevAccess = useSelector(getEntitlementHasDeveloperAccess);

  return (
    <Routes>
      {routes.map((routeData: RouterRouteProps, index: number) => {
        const { accessUserGroup, component: Component, path, auth, ...rest } = routeData;

        if (auth && !isAuthenticated) {
          return (
            <Route
              element={<Navigate state={{ from: location.pathname }} to="/sign-in" />}
              key={path}
              path={path}
            />
          );
        }

        if (hasOnlyDevAccess && !location.pathname.includes('/api')) {
          return <Route element={<Navigate to="/api" />} key={path} path={path} />;
        }
        if (location.pathname.startsWith('/api') && !hasDevAccess) {
          return <Route element={<Navigate to="/" />} key={path} path={path} />;
        }
        if (accessUserGroup && !accessUserGroup.includes(userGroupAccessLevel)) {
          return <Route element={<Navigate to="/" />} key={path} path={path} />;
        }

        return (
          <Route
            element={
              <RoutesContext.Provider value={{ auth: Boolean(auth) }}>
                <Component {...rest} />
              </RoutesContext.Provider>
            }
            key={`${String(path)}${index}`}
            path={path}
          />
        );
      })}
    </Routes>
  );
};

export { Router };
