import { MemberDetails, PeopleParamState, PeopleState, SubscriptionStatus } from './types';

export const initialParams: PeopleParamState = {
  pageSize: 100,
  page: 0,
  totalPages: 0,
  total: 0,
  totalWithoutFilters: 0
};

export const initialMemberState: MemberDetails = {
  birthDate: '',
  companyCode: '',
  companyUserId: '',
  deptId: '',
  emailAddress: '',
  employeeId: '',
  name: {
    first: '',
    last: ''
  },
  proxyOrgCode: '',
  planCode: '',
  planTitle: '',
  status: SubscriptionStatus.PENDING,
  updatedAt: '',
  userId: ''
};

export const initialState: PeopleState = {
  params: initialParams,
  list: [],
  memberDetails: initialMemberState,
  eligibilityUserCount: 0,
  enrolledUserCount: 0
};
