import React from 'react';
import { ClassValue } from 'clsx';

export enum BadgeStatus {
  DEFAULT = '',
  ERROR = 'error',
  PENDING = 'pending',
  SUCCESS = 'success'
}

export enum BadgeTypes {
  ICON_TEXT = 'iconText'
}

export type status = 'success' | 'pending' | 'error' | '';

export interface BadgeProps {
  children: React.ReactNode;
  className?: ClassValue;
  icon?: string;
  status?: status;
  type?: BadgeTypes;
}
