// Globals
import './styles.scss';
import React, { useEffect } from 'react';

// Components
import { Icon } from 'components/Icon';

// Misc
import clsx from 'clsx';
import { TooltipProps } from './types';

// Component
const Tooltip: React.FC<TooltipProps> = ({
  children,
  className,
  delay = 1000,
  hover,
  icon,
  pointer = 'bottom',
  tooltipContent
}) => {
  const preventHovering = hover !== undefined;

  // Hook - useState
  const [isToolTipVisible, setIsToolTipVisible] = React.useState(false);
  const timerId = React.useRef<EbTimeout>();

  // Hooks - effects
  useEffect(() => {
    if (!preventHovering) {
      return;
    }

    setIsToolTipVisible(hover);
  }, [hover, preventHovering]);

  useEffect(() => () => clearTimeout(timerId.current), []);

  // Handlers
  const showTooltip = React.useCallback(() => {
    if (preventHovering) {
      return;
    }

    // Show tooltip after waiting ${delay} ms
    timerId.current = setTimeout(() => setIsToolTipVisible(true), delay);
  }, [preventHovering, delay]);

  const hideTooltip = React.useCallback(() => {
    if (preventHovering) {
      return;
    }

    // Hide tooltip and clear timer if timerId exists
    setIsToolTipVisible(false);
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
  }, [preventHovering]);

  // Vars
  const classes = clsx('eb-tooltip', className);

  const textClasses = clsx('eb-tooltip-text', {
    'eb-tooltip-arrow-bottom': pointer === 'bottom',
    'eb-tooltip-arrow-bottom-left': pointer === 'bottomLeft',
    'eb-tooltip-arrow-bottom-right': pointer === 'bottomRight',
    'eb-tooltip-arrow-top': pointer === 'top',
    'eb-tooltip-arrow-right': pointer === 'right',
    'eb-tooltip-arrow-left': pointer === 'left'
  });

  // Render
  return (
    <div
      className={classes}
      onBlur={hideTooltip}
      onFocus={showTooltip}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      role="button"
      tabIndex={0}
    >
      {children}

      {isToolTipVisible && (
        <div className={textClasses}>
          {icon && <Icon className="eb-tooltip-icon" name={icon} />}
          {tooltipContent}
        </div>
      )}
    </div>
  );
};

export { Tooltip };
