import { Company } from './types';

export function formatList({ companies }: { companies: Company[] }): Record<string, Company> {
  return companies.reduce((obj: Record<string, Company>, company) => {
    if (company?.org?.code) {
      const companyCode = company.org.code;
      obj[companyCode] = company;
    }
    return obj;
  }, {});
}

export function formatCompanyCodes({
  companies,
  page
}: {
  companies: Company[];
  page: number;
}): Record<string, string[]> {
  return companies.reduce((obj: Record<number, string[]>, company) => {
    if (company?.org?.code) {
      const { code } = company.org;
      // New page, create key
      if (!obj[page]) {
        obj[page] = [code];
        return obj;
      }
      // Key exists
      obj[page].push(code);
    }

    return obj;
  }, {});
}
