import { md5 as hash } from 'pure-md5';
import persistState from 'redux-localstorage';
import config from 'config';
import { EbState } from '../index';
import { WritableDraft } from 'immer/dist/internal';
import { StoreEnhancer } from '@reduxjs/toolkit';

// State keys to be stored
const paths = ['auth'];

// Name the key used in browser local storage
const key = `${hash(`eb.portal${config.env}`) as string}`;

function slicer(paths: Array<keyof EbState>) {
  return (state: EbState) => {
    const subset: WritableDraft<Partial<EbState>> = {};
    paths.forEach((path) => {
      subset[path] = state[path];
    });
    return subset;
  };
}

// @ts-ignore
export const syncLocalStorage: StoreEnhancer = persistState(paths, { key, slicer });
