// Globals
import './styles.scss';
import React, { useEffect, useState, useCallback, MouseEvent } from 'react';

// Components
import { Button } from 'components/Button';

// Misc
import clsx from 'clsx';
import DropdownContext from 'components/Dropdown/context';
import { getWindow } from 'ssr-window';
import { DropdownIconProps, TEST_IDS } from './types';

// Component
const DropdownIcon: React.FC<DropdownIconProps> = ({
  className = '',
  hoverText = '',
  children,
  icon,
  onClick
}) => {
  const window = getWindow();

  // Hooks - state
  const [isOpen, setIsOpen] = useState(false);

  // Vars
  const classes = clsx('eb-dropdown_icon', className);

  // Handler
  const closeDropDownIcon = useCallback(
    (event: any) => {
      const target = event.target as HTMLElement;
      if (target.dataset?.type === 'eb-dropdown_item-container') {
        return;
      }
      setIsOpen(false);
      event.stopPropagation();
    },
    [setIsOpen]
  );

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setIsOpen(!isOpen);

    if (onClick) {
      onClick(event);
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('click', closeDropDownIcon, true);
    }
    return () => {
      window.removeEventListener('click', closeDropDownIcon, true);
    };
  }, [isOpen, window, closeDropDownIcon]);

  // Render
  return (
    <DropdownContext.Provider value={{ setIsOpen }}>
      <div className={classes} data-test={TEST_IDS.ICON}>
        <Button
          className={clsx({ 'eb-button_icon-click': isOpen })}
          hoverText={hoverText}
          icon={icon}
          intent="secondary"
          onClick={handleClick}
          type="icon"
        />
        {isOpen ? (
          <ul className="eb-dropdown-nav" data-test={TEST_IDS.ICON_MSG}>
            {children}
          </ul>
        ) : null}
      </div>
    </DropdownContext.Provider>
  );
};

export { DropdownIcon };
