export enum FilesActionTypes {
  SET_FILES = 'eb.files.SET_FILES',
  SET_FILES_PAGE = 'eb.files.SET_FILES_PAGE',
  SET_FILES_PAGE_SIZE = 'eb.files.SET_FILES_PAGE_SIZE',
  SET_FILES_FILTERS = 'eb.files.SET_FILES_FILTERS',
  UPDATE_LIST_FILE = 'eb.files.UPDATE_LIST_FILE',
  RESET_FILES_FILTERS = 'eb.files.RESET_FILES_FILTERS',
  SET_ORG_FILES = 'eb.files.SET_ORG_FILES',
  SET_ORG_FILES_PAGE = 'eb.files.SET_ORG_FILES_PAGE',
  SET_ORG_FILES_PAGE_SIZE = 'eb.files.SET_ORG_FILES_PAGE_SIZE',
  SET_ORG_FILES_SEARCH = 'eb.files.SET_ORG_FILES_SEARCH',
  RESET_ORG_FILES_FILTERS = 'eb.files.RESET_ORG_FILES_FILTERS'
}

// Generic Files Action
export interface FilesAction<P> {
  type: FilesActionTypes;
  data: P;
}

export enum FileStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  DEFAULT = ''
}

export interface FileSubmittedBy {
  userId: string;
  username?: string;
  uploadMethod: string;
}

export interface OrgFile {
  id: string;
  fileName: string;
  fileUploadedAt: string;
  status: FileStatus | null;
  successCount: number;
  failureCount: number;
  noActionCount: number;
  submittedBy?: FileSubmittedBy;
}

export enum FileReviewStatus {
  NOT_REVIEWED = 'NOT_REVIEWED',
  IN_PROGRESS = 'IN_PROGRESS',
  REVIEWED = 'REVIEWED'
}

export interface FileListItem {
  id: string;
  name: string;
  uploadedAt: string;
  status: FileStatus | null;
  companyCodes?: string[];
  uploadedCompanyCode: string;
  successCount: number;
  failureReason?: string;
  totalCount: number;
  failureCount: number;
  noActionCount: number;
  addRequestCount: number;
  termRequestCount: number;
  submittedBy: { username: string; userId: string; uploadMethod: string };
  reviewStatus?: FileReviewStatus;
  reviewedBy?: string;
  reviewedAt?: string;
  errorReportUri?: string;
  organization: {
    user_id: string;
    company_code: string;
    name: string;
    created_at: string;
    broker_code: string;
  };
}

export interface GetOrgFilesResponse {
  pageSize?: number;
  page?: number;
  total?: number;
  totalPages?: number;
  files: OrgFile[];
}

export interface GetFilesListResponse {
  pageSize?: number;
  page?: number;
  total?: number;
  totalPages?: number;
  files: FileListItem[];
}

export interface FilesSetAction extends FilesAction<GetFilesListResponse> {
  type: FilesActionTypes.SET_FILES;
}

export interface FilesSetPageAction extends FilesAction<number> {
  type: FilesActionTypes.SET_FILES_PAGE;
}

export interface FilesSetPageSizeAction extends FilesAction<number> {
  type: FilesActionTypes.SET_FILES_PAGE_SIZE;
}

export interface FilesSetFiltersAction
  extends FilesAction<{
    status?: string;
    uploadedAt?: string;
  }> {
  type: FilesActionTypes.SET_FILES_FILTERS;
}

export interface FilesResetFiltersAction extends FilesAction<null> {
  type: FilesActionTypes.RESET_FILES_FILTERS;
}

export interface FilesUpdateListFileAction extends FilesAction<Partial<FileListItem>> {
  type: FilesActionTypes.UPDATE_LIST_FILE;
}

export interface OrgFilesSetAction extends FilesAction<GetOrgFilesResponse> {
  type: FilesActionTypes.SET_ORG_FILES;
}

export interface OrgFilesSetPageAction extends FilesAction<number> {
  type: FilesActionTypes.SET_ORG_FILES_PAGE;
}

export interface OrgFilesSetPageSizeAction extends FilesAction<number> {
  type: FilesActionTypes.SET_ORG_FILES_PAGE_SIZE;
}

export interface OrgFileSetSearchAction extends FilesAction<string> {
  type: FilesActionTypes.SET_ORG_FILES_SEARCH;
}

export interface OrgFilesResetFiltersAction extends FilesAction<null> {
  type: FilesActionTypes.RESET_ORG_FILES_FILTERS;
}

export type FilesAllAction =
  | FilesSetAction
  | FilesSetPageAction
  | FilesSetPageSizeAction
  | FilesSetFiltersAction
  | FilesResetFiltersAction
  | FilesUpdateListFileAction
  | OrgFilesSetAction
  | OrgFilesSetPageAction
  | OrgFilesSetPageSizeAction
  | OrgFileSetSearchAction
  | OrgFilesResetFiltersAction;

export interface FilesParamState {
  page: number;
  pageSize: number;
  totalPages: number;
  total: number;
  statusColor?: string;
  uploadedAt?: string;
  companyCode?: string;
}

export interface OrgFilesParamState {
  page: number;
  pageSize: number;
  totalPages: number;
  total: number;
  search?: string;
}

export interface OrgFilesRequestParams {
  page: number;
  pageSize: number;
  search?: string;
}

export interface FilesListRequestParams {
  page: number;
  pageSize: number;
  statusColor?: string;
  uploadedAt?: string;
  companyCode?: string;
}

export interface FileUpdateRequestParams {
  id: string;
  reviewStatus?: FileReviewStatus;
  reviewedBy?: string;
}

export interface OrgFilesState {
  list: OrgFile[];
  params: OrgFilesParamState;
}

export interface AllFilesState {
  list: FileListItem[];
  params: FilesParamState;
}

export interface FilesState {
  allFiles: AllFilesState;
  orgFiles: OrgFilesState;
}
