import {
  PartnerExternalActionTypes,
  PartnerExternalAllAction,
  PartnerExternalState,
  initialState
} from './types';
import { combineReducers } from '@reduxjs/toolkit';

export const setPartnerExternalId = (data: string) => ({
  type: PartnerExternalActionTypes.SET_EXTERNAL_ID,
  data
});

function externalId(
  partnerExternalId = initialState.externalId,
  action: PartnerExternalAllAction
): string {
  switch (action.type) {
    case PartnerExternalActionTypes.SET_EXTERNAL_ID:
      return action.data;
    default:
      return partnerExternalId;
  }
}

// Root Reducer
export const partnerExternal = combineReducers<PartnerExternalState>({
  externalId
});
