// Action types
export enum UsersActionTypes {
  SET_ADMIN_USERS = 'eb.users.SET_ADMIN_USERS'
}

// Generic User  actions
export interface AdminUsersActions<P> {
  type: UsersActionTypes;
  data: P;
}

export interface AdminUsersSetUsersActions extends AdminUsersActions<DoGetAdminUsersResp> {
  type: UsersActionTypes.SET_ADMIN_USERS;
}

export type AdminUsersAllActions = AdminUsersSetUsersActions;

export interface AdminUsersParams {
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
}

export interface Name {
  first: string;
  last: string;
  middle?: string | null;
  suffix?: string | null;
}

export interface User {
  adminUserGroups: string[];
  companyCodes: string[] | null;
  createdAt: string;
  disabled: boolean;
  email: string;
  loggedInAt: string | null;
  name: Name;
  updatedAt: string;
  uploadMethod: string;
  userGroup: string;
  userId: string;
  username: string | null;
}

export interface AdminUsersState {
  params: AdminUsersParams;
  users: User[];
}

// Api interfaces
export interface DoGetAdminUsersResp {
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
  users: User[];
}

export interface InviteUser {
  name: Name;
  email: string;
  role: string;
  companyCodes?: string[];
  super: boolean;
}
