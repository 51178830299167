// Globals
import './styles.scss';
import React from 'react';

// Components
import { AriaLink } from 'components/AriaLink';
import { Icon } from 'components/Icon';

// Misc
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { useNavLinkManager } from 'hooks/useNavLinkManager';

// Component
function LayoutPageNav() {
  // Hooks
  const location = useLocation();

  // Hooks - custom
  const { links } = useNavLinkManager();

  // Render
  return (
    <div className="eb-layout_page_nav">
      {links.map(({ icon, path, title, isDisabled }) => {
        const classes = clsx('eb-layout_page_nav-link', {
          'eb-layout_page_nav-link-active': location.pathname.includes(path)
        });
        return isDisabled ? null : (
          <AriaLink className={classes} key={title} path={path}>
            <Icon name={icon} />
            <span className="eb-layout_page_nav-title">{title}</span>
          </AriaLink>
        );
      })}
    </div>
  );
}

export { LayoutPageNav };
