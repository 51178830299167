import React from 'react';

interface AccordionContextProps {
  isToggleOpen?: boolean;
}

const defaultProps = {};

const AccordionContext = React.createContext<AccordionContextProps>(defaultProps);

export default AccordionContext;
