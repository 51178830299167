// Globals
import './styles.scss';
import React from 'react';
import clsx from 'clsx';

interface ModalBodyTextProps {
  className?: string;
  children?: React.ReactNode;
}

// Component
const ModalBodyText: React.FC<ModalBodyTextProps> = (props) => {
  const className = clsx(props.className, 'eb-modal_body_text');

  return (
    <div className={className}>
      <div className="eb-modal-content">{props.children}</div>
    </div>
  );
};

export { ModalBodyText };
