import * as endpoints from 'endpoints';
import { ApiDomain } from 'services/api/types';
import { eb } from 'services/api/async';
import { EbThunk } from 'store/types';
import { UserSettingsGetResponse } from './types';
import { addCompanyNotifications, removeCompanyNotifications, setUserSettings } from './reducers';

doAddNotificationSettings.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.USER_SETTINGS,
  method: 'POST'
};

doRemoveNotificationSettings.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.USER_SETTINGS,
  method: 'POST'
};

doGetUserSettings.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.USER_SETTINGS,
  method: 'GET'
};

export function doAddNotificationSettings(companyCode: string): EbThunk<Promise<void>> {
  return async (dispatch) => {
    const {
      data: { endpoint, method }
    } = doAddNotificationSettings;

    const data = {
      notifications: {
        fileSuccessCompanyCodes: {
          add: [companyCode]
        },
        fileFailedCompanyCodes: {
          add: [companyCode]
        }
      }
    };

    await dispatch(
      eb<UserSettingsGetResponse>({
        endpoint,
        method,
        data
      })
    );

    dispatch(addCompanyNotifications(companyCode));
  };
}

export function doRemoveNotificationSettings(companyCode: string): EbThunk<Promise<void>> {
  return async (dispatch) => {
    const {
      data: { endpoint, method }
    } = doRemoveNotificationSettings;

    const data = {
      notifications: {
        fileSuccessCompanyCodes: {
          remove: [companyCode]
        },
        fileFailedCompanyCodes: {
          remove: [companyCode]
        }
      }
    };

    await dispatch(
      eb({
        endpoint,
        method,
        data
      })
    );

    dispatch(removeCompanyNotifications(companyCode));
  };
}

export function doGetUserSettings(): EbThunk<Promise<void>> {
  return async (dispatch) => {
    const {
      data: { endpoint, method }
    } = doGetUserSettings;

    const resp = await dispatch(
      eb<UserSettingsGetResponse>({
        endpoint,
        method
      })
    );

    dispatch(setUserSettings(resp));
  };
}
