import { createSelector } from 'reselect';
import { EbState } from 'store';
import { ApiSpecState } from './types';

export const getApiSpecState = createSelector(
  (state: EbState) => state?.apiSpec,
  (apiSpecState): ApiSpecState => apiSpecState
);

export const getApiSpecByTag = createSelector(
  getApiSpecState,
  (apiSpecState) => apiSpecState?.byTag
);

export const getApiSpecPlain = createSelector(
  getApiSpecState,
  (apiSpecState) => apiSpecState?.plain
);

export const getApiSpecVersion = createSelector(
  getApiSpecState,
  (apiSpecState) => apiSpecState?.version
);

export const getApiSpecEnvironments = createSelector(
  getApiSpecState,
  (apiSpecState) => apiSpecState?.environments
);

export const getApiSpecConfig = createSelector(
  getApiSpecState,
  (apiSpecState) => apiSpecState?.config
);

export const getApiSpecConfigAuthBearer = createSelector(
  getApiSpecConfig,
  (config) => config?.auth.bearer
);

export const getApiSpecConfigAuthBasic = createSelector(getApiSpecConfig, (config) => ({
  username: config?.auth.username,
  pass: config?.auth.pass
}));
