// Globals
import './styles.scss';

// Components
import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { LayoutAuth } from 'components/LayoutAuth';

function PasswordResetSuccess() {
  // Render
  return (
    <LayoutAuth className="eb-reset_password-success">
      <div className="eb-reset_password-success-image" />
      <Heading align="center" size={26} type="h4">
        Your password has been reset
      </Heading>
      <Button goto="/sign-in" type="button">
        Done
      </Button>
    </LayoutAuth>
  );
}

export { PasswordResetSuccess };
