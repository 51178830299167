import jwt_decode from 'jwt-decode';
import { AccessLevel, JWTToken } from './types';
import { accessTokenDecodedInitial, refreshTokenDecodedInitial } from './reducers';
import { createSelector } from 'reselect';
import { EbState } from 'store/types';
import { isValidDate } from 'utils/isValidDate';

// Selectors
export const getAuth = createSelector(
  (state: EbState) => state?.auth,
  (auth) => auth
);

// Selectors - sub-reducers
export const getAccessToken = createSelector(getAuth, (auth) => auth.accessToken);

export const getRefreshToken = createSelector(getAuth, (auth) => auth.refreshToken);

export const getEntitlements = createSelector(getAuth, (auth) => auth.entitlements);

export const getAccessTokenDecoded = createSelector(getAccessToken, (token: string): JWTToken => {
  const accessToken: JWTToken = jwt_decode(token);

  if (typeof accessToken === 'object' && accessToken) {
    return accessToken;
  }
  return accessTokenDecodedInitial;
});

export const getRefreshTokenAlias = createSelector(
  getAccessTokenDecoded,
  (token) => token.alias ?? ''
);

export const getRefreshTokenUserId = createSelector(
  getAccessTokenDecoded,
  (token) => token.sub
);

export const getRefreshTokenDecoded = createSelector(getRefreshToken, (token: string): JWTToken => {
  if (!token) return refreshTokenDecodedInitial;

  const accessToken: JWTToken = jwt_decode(token);
  if (typeof accessToken === 'object' && accessToken) {
    return accessToken;
  }

  return refreshTokenDecodedInitial;
});

export const getAccessTokenExpires = createSelector(getAccessTokenDecoded, (token) => {
  if (token.exp && isValidDate(new Date(token.exp * 1e3))) {
    return new Date(token.exp * 1e3);
  }
  return new Date(0);
});

export const getRefreshTokenExpires = createSelector(
  getRefreshTokenDecoded,
  (token: JWTToken): Date => {
    if (token?.exp && isValidDate(new Date(token.exp * 1e3))) {
      return new Date(token.exp * 1e3);
    }
    return new Date(0);
  }
);

export const getIsRefreshTokenValid = createSelector(
  getRefreshTokenExpires,
  (expires) => expires.getTime() > Date.now()
);

export const getEntitlementEbPortalCompanyCode = createSelector(
  getEntitlements,
  (entitlements) => entitlements['eb:portal_company_codes'] || []
);

export const getEntitlementEbUserGroups = createSelector(
  getEntitlements,
  (entitlements) => entitlements['eb:user_groups'] || []
);

export const getEntitlementEbAdminUserGroups = createSelector(
  getEntitlements,
  (entitlements) => entitlements['eb:admin_user_groups'] || []
);

export const getEntitlementHasDeveloperAccess = createSelector(
  getEntitlementEbUserGroups,
  (userGroups) => userGroups.includes('developer')
);

export const getEntitlementHasDeveloperAccessOnly = createSelector(
  getEntitlementEbUserGroups,
  (userGroups) => userGroups.length === 1 && userGroups.includes('developer')
);

export const getEntitlementEbUserAccessLevel = createSelector(
  getEntitlementEbUserGroups,
  getEntitlementEbAdminUserGroups,
  (userGroups, adminUserGroups) => {
    switch (true) {
      case ['aura_admin', 'proxy_admin', 'metlife_admin'].some((group) =>
        userGroups.includes(group)
      ):
        return AccessLevel.FULL;
      case userGroups.includes('hr_admin') && adminUserGroups.includes('create_admin'):
        return AccessLevel.HR_SUPER;
      case userGroups.includes('hr_admin') && !adminUserGroups.includes('create_admin'):
        return AccessLevel.HR;
      case userGroups.includes('developer'):
        return AccessLevel.DEV;
      default:
        return AccessLevel.ORG;
    }
  }
);

export const getEntitlementsHasFilesAccess = createSelector(
  getEntitlementEbUserGroups,
  (userGroups) =>
    userGroups.includes('aura_admin') ||
    userGroups.includes('hr_admin') ||
    userGroups.includes('proxy_admin')
);

export const getEntitlementsIsHrAdmin = createSelector(getEntitlementEbUserGroups, (userGroups) =>
  userGroups.includes('hr_admin')
);

export const getEntitlementsIsAuraAdmin = createSelector(getEntitlementEbUserGroups, (userGroups) =>
  userGroups.includes('aura_admin')
);

export const getEntitlementsIsProxyAdmin = createSelector(
  getEntitlementEbUserGroups,
  (userGroups) => userGroups.includes('proxy_admin')
);

export const getEntitlementsCanCreateAdminUser = createSelector(
  getEntitlementEbAdminUserGroups,
  (userGroups) => userGroups.includes('create_admin')
);
