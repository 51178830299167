// Globals
import './styles.scss';
import React from 'react';

// Components
import { Breadcrumbs } from 'components/Breadcrumbs';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { Heading } from 'components/Heading';
import { LayoutPageNav } from './Nav';
import { Page } from 'components/Page';

// Misc
import clsx, { ClassValue } from 'clsx';

// Prop Interface
interface LayoutPageProps {
  children: React.ReactNode | React.ReactNode[];
  className?: ClassValue;
  heading?: React.ReactNode;
  hasBreadcrumbs?: boolean;
}

// Component
const LayoutPage: React.FC<LayoutPageProps> = ({
  children,
  className = '',
  heading = '',
  hasBreadcrumbs
}) => {
  // class vars
  const classes = clsx('eb-layout_page', className);

  // Render
  const renderHeading = () => {
    if (!heading) return null;

    if (typeof heading === 'string')
      return (
        <Heading className="eb-layout_page-heading" size={32} type="h1">
          {heading}
        </Heading>
      );

    return heading;
  };

  return (
    <Page>
      <div className={classes}>
        <Header />

        <div className="eb-layout_page-content">
          <LayoutPageNav />
          <div className="eb-layout_page-body">
            {hasBreadcrumbs && <Breadcrumbs />}

            {/* Page Heading */}
            {renderHeading()}

            {/* Page Content */}
            {children}
          </div>
        </div>
        <Footer />
      </div>
    </Page>
  );
};

export { LayoutPage };
