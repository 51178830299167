import { differenceInMonths } from 'date-fns';
import { Status as TagStatus } from 'components/TagStatus/types';

const FILE_EXPIRATION_MONTHS = 3;

export const isFileClickable = (status?: TagStatus, dateUploaded?: string) => {
  if (!dateUploaded || !status) return false;

  const date = new Date(dateUploaded);

  return (
    status !== TagStatus.PENDING && differenceInMonths(new Date(), date) <= FILE_EXPIRATION_MONTHS
  );
};
