import React from 'react';

export interface ModalUploadFileProps {
  title: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  handleUploadFile: (file: FileList) => void;
  hide: () => void;
  onSuccessClose?: () => void;
  isVisible: boolean;
}

export enum FILE_UPLOAD_STEP {
  INITIAL = 'INITIAL',
  UPLOADING = 'UPLOADING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

export const FILE_UPLOAD_INITITAL_STATE = {
  step: FILE_UPLOAD_STEP.INITIAL
};

export interface FileUploadState {
  step: FILE_UPLOAD_STEP;
  error?: string;
}
