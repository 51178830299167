import { differenceInMilliseconds, differenceInMinutes } from 'date-fns';
import React from 'react';
import { getRefreshTokenExpires } from 'services/auth/selectors';
import { useSelector } from 'react-redux';

// Constant
const SESSION_EXPIRATION_WARNING_MINUTES = 5;

export function useTokenManager() {
  // Refs
  const timerId = React.useRef<EbTimeout>();

  // Hooks - Selector
  const refreshTokenExpiration = useSelector(getRefreshTokenExpires);

  // Hooks - state
  const [isSessionExpiring, setIsSessionExpiring] = React.useState(false);

  // Timeout to open modal N min before session expiration
  React.useEffect(() => {
    const msBeforeSessionExpiration = differenceInMilliseconds(refreshTokenExpiration, new Date());
    const msTimeout = msBeforeSessionExpiration - SESSION_EXPIRATION_WARNING_MINUTES * 60 * 1000;

    clearTimeout(timerId.current);
    timerId.current = setTimeout(() => {
      setIsSessionExpiring(true);
    }, msTimeout);
  }, [refreshTokenExpiration]);

  React.useEffect(() => {
    const minBeforeSessionExpiration = differenceInMinutes(refreshTokenExpiration, new Date());

    setIsSessionExpiring(minBeforeSessionExpiration <= SESSION_EXPIRATION_WARNING_MINUTES);
  }, [refreshTokenExpiration]);

  React.useEffect(() => () => clearTimeout(timerId.current), []);

  return {
    isSessionExpiring
  };
}
