import { escapeRegExp } from '../escapeRegExp';

export function matchPath(routes: Record<string, boolean>, path: string) {
  return Object.keys(routes).some((route) => {
    const routeEscapedSpecialChars = escapeRegExp(route);
    // If endpoint includes placeholder for path param replace with metacharacter to match any "word character"
    const routeEscapedPathParam = routeEscapedSpecialChars.replace(/{\d}/g, '(?:[a-z0-9_]|-)+');
    return new RegExp(routeEscapedPathParam).test(path);
  });
}
