import React from 'react';
import { createSelector } from 'reselect';
import { EbState } from 'store';
import { ErrorParser } from 'utils/parseErrors/types';
import { ApiDomain, ApiRequest, RequestApiParams } from './types';
import { mapDomainToParser } from './utils';

export const getApi = createSelector(
  (state: EbState) => state.api,
  (api) => api
);

// Private:
export const getEndpointMethods = (state: EbState, request: ApiRequest) =>
  createSelector(
    getApi,
    (_: EbState, request: any) => request?.data?.endpoint,
    (api, endpoint) => api?.[endpoint]
  )(state, request);

export const getEndpointByMethod = (state: EbState, request: ApiRequest) =>
  createSelector(
    getEndpointMethods,
    (_: EbState, request: Partial<RequestApiParams>) => request?.data?.method,
    (endpoint, method) => endpoint?.[method]
  )(state, request);

const getSingleEndpointPending = (state: EbState, request: ApiRequest) =>
  createSelector(getEndpointByMethod, (endpoint) => endpoint?.pending)(state, request);

// Public:
// Selectors - single endpoint
export const getEndpointPending = (state: EbState, request: ApiRequest) =>
  createSelector(
    (state: EbState, request: any) => [state, request],
    ([state, request]) => getSingleEndpointPending(state, request)
  )(state, request);

export const getEndpointSuccess = (state: EbState, request: ApiRequest) =>
  createSelector(getEndpointByMethod, (endpoint) => endpoint?.success)(state, request);

export const getEndpointFailure = (state: EbState, request: ApiRequest) =>
  createSelector(getEndpointByMethod, (endpoint) => endpoint?.success === false)(state, request);

export const getEndpointError = (
  state: EbState,
  request: ApiRequest
): React.ReactElement | string =>
  createSelector(
    getEndpointByMethod,
    (_: EbState, request: Partial<RequestApiParams>) => request?.data?.domain,
    (endpoint, domain: ApiDomain) => {
      const parser: ErrorParser = mapDomainToParser[domain];
      if (parser) {
        return endpoint?.error?.message
          ? parser(endpoint.error)
          : parser({ message: endpoint?.error?.code });
      }
      return '';
    }
  )(state, request);

// Selectors - multiple endpoints
export const getEndpointsAllSuccess = (state: EbState, requests: ApiRequest[]) =>
  createSelector(
    (state: EbState, requests: any) => [state, requests],
    ([state, requests]) => requests.every((request: any) => getEndpointSuccess(state, request))
  )(state, requests);

export const getEndpointsAnyFailure = (state: EbState, requests: ApiRequest[]) =>
  createSelector(
    (state: EbState, requests: any) => [state, requests],
    ([state, requests]) =>
      requests.some((request: ApiRequest) => getEndpointFailure(state, request))
  )(state, requests);

export const getEndpointsAnyPending = (state: EbState, requests: ApiRequest[]) =>
  createSelector(
    (state: EbState, requests: any) => [state, requests],
    ([state, requests]) => [...requests].some((request) => getSingleEndpointPending(state, request))
  )(state, requests);
