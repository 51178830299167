// Global
import React from 'react';

// Services
import { setTokens } from 'services/auth/reducers';
import { getAccessToken } from 'services/auth/selectors';
import { parsePlatformError } from 'utils/parseErrors';
import { setToastError } from 'services/toast/reducers';

// Misc
import * as AuthManager from '@isubscribed/eddie';
import config from 'config';
import { Dictionary } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

// Types
import { AuthChallenge, AuthChallenges } from './types';
import { AuthTokens, LoginAuthParams } from 'services/auth/types';

// Constants
const AUTH_MANAGER_CONFIG = {
  performOAuthInNewWindow: true,
  useSrp: false,
  allowSrpFallback: false,
  log_messages: {},
  baseUrl: config.ap_api_origin,
  authHeader: 'Basic ' + btoa(`${config.ap_api_key}:${config.ap_api_secret}`)
};

export function useAuthManager(initialChallenge?: AuthChallenge) {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Selectors
  const accessToken = useSelector(getAccessToken);

  // Hooks - state
  const [error, setError] = React.useState('');
  const [pending, setPending] = React.useState(false);
  const [attempt, setAttempt] = React.useState<Dictionary<any>>({});
  // Needed in state to update MFA step, updating value in ref doesn't trigger rerender
  const [currentChallengeName, setCurrentChallengeName] = React.useState(initialChallenge);

  // Refs - need to store values that need to be accesses in async callbacks
  const currentChallenge = React.useRef<AuthChallenge>();

  const setCurrentChallenge = React.useCallback(
    (challenge: AuthChallenge) => {
      currentChallenge.current = challenge;
      setCurrentChallengeName(challenge);
    },
    [setCurrentChallengeName]
  );

  const resetAttempt = React.useCallback(
    (challenge: AuthChallenge = initialChallenge) => {
      setAttempt({});
      setError('');
      setCurrentChallenge(challenge);
    },
    [setCurrentChallenge, initialChallenge]
  );

  const onError = React.useCallback(
    ({ message }: { message: string }) => {
      resetAttempt();
      const error = parsePlatformError({ message }) ?? '';
      if (message === 'InvalidMfaCode') {
        dispatch(setToastError(error));
      } else if (message === 'InvalidAliasOrPassword') {
        setError('Incorrect password. Please try again');
      } else if (error) {
        setError(error);
      } else {
        setError('Incorrect password. Please try again');
      }

      navigate('/sign-in', { state: { persistToast: true } });
      setPending(false);
    },
    [dispatch, navigate, resetAttempt]
  );

  const configureAuthManager = React.useCallback(
    (accessToken: string) => {
      AuthManager.configure({
        ...AUTH_MANAGER_CONFIG,
        bearerTokenGetter: () => accessToken,
        eventHandlers: {
          started() {
            resetAttempt();
            setAttempt(this);
          },

          authenticated(tokens: AuthTokens) {
            dispatch(setTokens(tokens));

            resetAttempt();
            setPending(false);

            const { from = '/' } = location.state || { from: { pathname: '/' } };
            navigate(from, { replace: true });
          },

          failed: onError,

          configure_mfa() {
            setCurrentChallenge(AuthChallenges.CONFIGURE_MFA);
          },

          provide_mfa_code() {
            setCurrentChallenge(AuthChallenges.PROVIDE_MFA);
          }
        }
      });
    },
    [dispatch, navigate, onError, resetAttempt, setCurrentChallenge, location.state]
  );

  function doInitConfigureMfa() {
    setCurrentChallenge(AuthChallenges.CONFIGURE_MFA);
  }

  async function doConfigureMfa(value: string) {
    setPending(true);

    await attempt.respondToChallenge({
      device_type: 'email',
      value,
      is_default: true
    });

    setPending(false);
  }

  async function doProvideMfaCode(code: string) {
    setPending(true);
    await attempt.respondToChallenge({ code });
    setPending(false);
  }

  async function doSignIn(data: LoginAuthParams) {
    setPending(true);

    configureAuthManager(accessToken);

    const response = await AuthManager.initAuthFlow(AuthManager.AuthProvider.ALIAS_PASSWORD).signin(
      data
    );

    setPending(false);
    return response;
  }

  // Hooks - effects
  React.useEffect(() => {
    configureAuthManager(accessToken);
  }, [accessToken, configureAuthManager]);

  return {
    error,
    pending,
    doConfigureMfa,
    doInitConfigureMfa,
    doProvideMfaCode,
    doSignIn,
    currentChallenge: currentChallengeName
  };
}
