// Apply environment configs and defaults
export function getGlobalVar(varName: string): string | undefined {
  return (global as any)?.__ENV__?.[varName];
}

const config = {
  /**
   * ENVs are on process on server (Node) and global in browser (DOM),in the case of process you
   * cannot destructure, full path must be called
   **/
  env: getGlobalVar('REACT_APP_APP_ENV') ?? process.env.REACT_APP_APP_ENV ?? '',
  ap_api_origin:
    getGlobalVar('REACT_APP_AP_API_ORIGIN') ?? process.env.REACT_APP_AP_API_ORIGIN ?? '',
  ap_api_admin_origin:
    getGlobalVar('REACT_APP_AP_ADMIN_ORIGIN') ?? process.env.REACT_APP_AP_ADMIN_ORIGIN ?? '',
  ap_api_key: getGlobalVar('REACT_APP_AP_API_KEY') ?? process.env.REACT_APP_AP_API_KEY ?? '',
  ap_api_secret:
    getGlobalVar('REACT_APP_AP_API_SECRET') ?? process.env.REACT_APP_AP_API_SECRET ?? '',
  eb_api_origin:
    getGlobalVar('REACT_APP_EB_API_ORIGIN') ?? process.env.REACT_APP_EB_API_ORIGIN ?? '',
  gpr_api_origin:
    getGlobalVar('REACT_APP_GPR_API_ORIGIN') ?? process.env.REACT_APP_GPR_API_ORIGIN ?? '',
  fte_api_origin:
    getGlobalVar('REACT_APP_FTE_API_ORIGIN') ?? process.env.REACT_APP_FTE_API_ORIGIN ?? '',
  cuid_api_origin:
    getGlobalVar('REACT_APP_CUID_API_ORIGIN') ?? process.env.REACT_APP_CUID_API_ORIGIN ?? '',
  sentry_dsn: getGlobalVar('REACT_APP_SENTRY_DSN') ?? process.env.REACT_APP_SENTRY_DSN ?? ''
};

export default config;
