// Globals
import './styles.scss';
import React from 'react';

// Components
import { ModalFileUpload } from 'modals/ModalFileUpload';

// Services
import { doGetCompanyCuidFiles, doUploadCuidFile } from 'services/cuid/async';
import { setToastSuccess } from 'services/toast/reducers';

// Misc
import { useFileUploadManager } from 'hooks/useFileUploadManager';
import { useDispatch } from 'react-redux';

// Types
import { EbDispatch } from 'store';
import { ModalFileUploadEnrollmentProps } from './types';

const FILE_EXTENSIONS = ['.csv'];
const REQUIRED_CSV_HEADERS = ['companycode', 'oldcompanyuserid', 'newcompanyuserid', 'userid'];

// Component
const ModalFileUploadCuid: React.FC<ModalFileUploadEnrollmentProps> = ({
  hide,
  isVisible,
  onSuccessClose
}) => {
  // Hooks
  const dispatch: EbDispatch = useDispatch();

  // Handlers
  const handleUpload = async (file: File) => {
    await dispatch(doUploadCuidFile(file));
    void dispatch(doGetCompanyCuidFiles());
    hide();
    dispatch(setToastSuccess('CUID file has been successfully uploaded'));
  };

  // Hooks - custom
  const { uploadFile } = useFileUploadManager(handleUpload, FILE_EXTENSIONS, REQUIRED_CSV_HEADERS);

  return (
    <ModalFileUpload
      handleUploadFile={uploadFile}
      hide={hide}
      isVisible={isVisible}
      onSuccessClose={onSuccessClose}
      title="Upload CUID update file"
    />
  );
};

export { ModalFileUploadCuid };
