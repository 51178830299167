import * as endpoints from 'endpoints';
import { ApiDomain } from 'services/api/types';
import { eb } from 'services/api/async';
import { EbThunk } from 'store/types';
import {
  getOrganizationByCode,
  getOrganizationsParams,
  getOrganizationsSelected
} from './selectors';
import { setOrganizationDetailsSelected, setOrganizations } from './reducers';
import { DoGetOrganizationDetailsResp, DoGetOrganizationsResp } from './types';
import { getEntitlementEbPortalCompanyCode } from 'services/auth/selectors';
import { cloneDeep } from 'lodash';

doGetOrganizations.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.ORGANIZATION_GET,
  method: 'GET'
};

doGetOrganizationDetails.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.ORGANIZATION_DETAILS,
  method: 'GET'
};

doEndOrganizationOnboarding.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.ORGANIZATION_DETAILS,
  method: 'PATCH'
};

export function doGetOrganizations(): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    // Request
    const {
      data: { endpoint, method }
    } = doGetOrganizations;

    // Request params
    const { page, pageSize, search } = getOrganizationsParams(getState());

    const respOrganization: DoGetOrganizationsResp = await dispatch(
      eb<DoGetOrganizationsResp>({
        endpoint,
        method,
        params: { page, pageSize, search }
      })
    );

    dispatch(setOrganizations(respOrganization));
  };
}

export function doEndOrganizationOnboarding(companyCode: string): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    // Request
    const {
      data: { endpoint, method }
    } = doEndOrganizationOnboarding;

    const orgDetails = getOrganizationByCode(getState(), companyCode);

    await dispatch(
      eb({
        endpoint,
        method,
        pathParams: [companyCode],
        data: { isProd: true }
      })
    );

    // setting the updated isProd value
    const clonedOrg = cloneDeep(orgDetails);
    clonedOrg.org.isProd = true;
    dispatch(setOrganizationDetailsSelected(clonedOrg));
  };
}

export function doGetOrganizationDetails(code?: string): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    // Request
    const {
      data: { endpoint, method }
    } = doGetOrganizationDetails;

    // Request Path Param
    const companyCodeSelected = getOrganizationsSelected(getState());
    const companyCodeEntitlements = getEntitlementEbPortalCompanyCode(getState());

    // Get company code from either selected or entitlements
    const companyCode = code
      ? code
      : companyCodeSelected
      ? companyCodeSelected
      : companyCodeEntitlements;

    const resp: DoGetOrganizationDetailsResp = await dispatch(
      eb<DoGetOrganizationDetailsResp>({
        endpoint,
        method,
        pathParams: [companyCode]
      })
    );

    dispatch(setOrganizationDetailsSelected(resp));
  };
}
