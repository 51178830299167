import { createSelector } from 'reselect';
import { EbState } from 'store';
import { UserSettingsNotificationsState, UserSettingsState } from './types';
import { initialNotifications } from './initialState';

export const getUserSettings = createSelector(
  (state: EbState) => state?.userSettings,
  (settings): UserSettingsState => settings
);

export const getUserSettingsNotifications = createSelector(
  getUserSettings,
  (settings): UserSettingsNotificationsState => settings?.notifications || initialNotifications
);

export const getUserSettingsIsNotificationsEnabledForCompanyCode = createSelector(
  getUserSettingsNotifications,
  (_: EbState, companyCode: string) => companyCode,
  (notificationSettings, companyCode): boolean =>
    notificationSettings.fileFailedCompanyCodes?.includes(companyCode) ||
    notificationSettings.fileSuccessCompanyCodes?.includes(companyCode)
);
