// Globals
import './styles.scss';
import React, { useEffect, useState } from 'react';

// Types
import { EbTrackingEventType } from 'services/analytics/types';
import { FormProps } from './types';
import { FormValueEntries } from 'services/forms/types';
import { ObjectSchema } from 'yup';

// Misc
import clsx from 'clsx';
import FormContext from './context';
import { scrollTo } from 'utils/scrollTo';
import { useTracking } from 'hooks/useTracking';
import { useDispatch } from 'react-redux';
import { formsSetFormValues } from 'services/forms/reducers';
import { nestedReactToStringAPI, nestedReactToStringSchema } from './util';

// Custom Hooks
function useHandleErrors(values: Object, schema?: ObjectSchema<any>): Record<string, string[]> {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Track lifecycle of component
    let isSubscribed = true;

    async function handleErrors() {
      try {
        // If no schema, skip validation
        if (!schema) {
          return {};
        }
        // Validate schema
        await schema.validate(values, { abortEarly: false });
        if (isSubscribed) {
          setErrors({});
        }
      } catch (error: any) {
        if (error?.inner?.length > 0) {
          // Keep track of errors by field
          const fieldErrors = error.inner.reduce(
            (
              object: Record<string, string[]>,
              { path, message }: { path: string; message: string }
            ) => {
              object[path] = object[path] || [];
              object[path].push(message);
              return object;
            },
            {}
          );
          if (isSubscribed) {
            setErrors(fieldErrors);
          }
        }
      }
    }

    handleErrors();

    // Prevent setting state if component unmounted
    return () => {
      isSubscribed = false;
    };
  }, [schema, values]);
  return errors;
}

// Component
const Form: React.FC<FormProps> = (props) => {
  const {
    className,
    data = {},
    defaultValuesAsync = {},
    error,
    isSynced,
    onError,
    onSubmit,
    pending,
    schema,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    staticContext = {},
    success,
    tracking,
    ...otherProps
  } = props;

  // Analytic Tracking
  const trackEvent = useTracking();

  // API Status
  const status = error ?? pending ?? success;
  // Key prop is not accessible, so use defaultValuesAsync as 'key' since it is the value passed as key prop to force re-render
  // Non-primitives need to be memoized to prevent unnecessary re-renders in useEffect, but need a way to do "deep equality check," so stringify-ing for referential comparison
  const key = JSON.stringify(defaultValuesAsync);

  // Hooks - state
  const [values, setValues] = useState(data);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Hooks - dispatch
  const dispatch = useDispatch();

  // Hooks - effects
  useEffect(() => {
    // Some forms need default values that are async. Since Form component is controlled, need to use key to trigger re-render and manually set input values

    // Length check on values won't suffice as they may have defaults coming from global state, instead check for any non-undefined value
    const isRequestResolved = Object.values(defaultValuesAsync).some(
      (value) => value !== undefined
    );
    if (isRequestResolved) {
      setValues({ ...values, ...defaultValuesAsync });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, defaultValuesAsync]);

  // track  api error
  useEffect(() => {
    if (error) {
      scrollTo('eb-form-toast-error');
    }
    if (error) {
      trackEvent({
        ...tracking,
        action: '',
        event: EbTrackingEventType.api_error_validation,
        error: nestedReactToStringAPI(error)
      });
    }
  }, [error, tracking, trackEvent]);

  const errors = useHandleErrors(values, schema);

  // Handlers
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isSubmitted) {
      setIsSubmitted(true);
    }

    // Find submission button
    // @ts-ignore
    const submitElement = event.nativeEvent.submitter;

    trackEvent({
      ...tracking,
      action_name: tracking?.action_name ?? submitElement?.outerText?.toString(),
      action: tracking && tracking.action && `btn_${tracking.action}`,
      event: EbTrackingEventType.ui_click,
      placement: tracking && tracking.placement
    });

    if (Object.keys(errors).length > 0) {
      // Track schema errors
      trackEvent({
        ...tracking,
        action: '',
        event: EbTrackingEventType.ui_form_error_validation,
        error: nestedReactToStringSchema(errors)
      });

      if (onError) {
        onError();
      }
      return;
    }

    onSubmit(values);

    // Sync on submit
    if (isSynced) {
      let undefinedValues = {};
      // @ts-ignore
      const _nodes = schema?._nodes ?? [];

      _nodes.forEach((node: any) => {
        undefinedValues = { ...undefinedValues, [node]: null };
      });

      dispatch(
        formsSetFormValues({
          formName: otherProps.name,
          values: { ...undefinedValues, ...values } as FormValueEntries
        })
      );
    }
  };

  // Vars
  const classes = clsx(['eb-form', className]);
  const toastClasses = clsx('eb-form-toast', {
    'eb-form-toast-error': error,
    'eb-form-toast-pending': Boolean(pending) || success
  });
  const formProps = {
    onSubmit: handleSubmit,
    ...otherProps
  };

  // Render
  return (
    <FormContext.Provider value={{ errors, isSubmitted, values: [values, setValues] }}>
      <div className={classes}>
        {status && <div className={toastClasses}>{status}</div>}
        <form {...formProps} />
      </div>
    </FormContext.Provider>
  );
};

export { Form };
