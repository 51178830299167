// Globals
import './styles.scss';
import React, { useCallback, useEffect, useState } from 'react';

// Components
import { DropdownFilterOption } from './Option';
import { Icon } from 'components/Icon';

// Misc
import clsx from 'clsx';
import { getWindow } from 'ssr-window';
import onKeyDown from '@isubscribed/fat-tony/onKeyDown';
import { useEffectDidUpdate } from 'hooks/useEffectDidUpdate';

// Types
import { DropdownEvent, DropdownFilterProps } from './types';

// Component
const DropdownFilter: React.FC<DropdownFilterProps> = ({
  isDisabled,
  onChange,
  options,
  value
}) => {
  const window = getWindow();

  // Hooks - state
  const [isOpen, setIsOpen] = useState(false);
  const [label, setLabel] = useState('');
  const [eventOnChange, setEventOnChange] = useState<DropdownEvent>();

  // Handlers
  const autoFocus = useCallback(() => {
    if (!isOpen) {
      return;
    }
    const navItems: NodeListOf<HTMLLIElement> = document.querySelectorAll(
      '[data-type="eb-dropdown_filter-list-item"]'
    );
    if (navItems.length > 0) {
      navItems[0].focus();
    }
  }, [isOpen]);

  const closeMenu = useCallback(
    (event: MouseEvent) => {
      // Prevent execution if option item clicked
      if (!isOpen || (event.target as HTMLDivElement).dataset.type) {
        return;
      }
      setIsOpen(false);
      event.stopPropagation();
    },
    [isOpen, setIsOpen]
  );

  const selectOption = React.useCallback(
    (event: React.MouseEvent, label: string, optionValue?: string | number) => {
      setLabel(label);
      setIsOpen(false);

      // Mock some basic event data for onChange since this component is not an actual input
      if (value !== optionValue && onChange) {
        const extendedEvent: DropdownEvent = {
          ...event,
          dropdownFilter: { value: optionValue }
        };
        // Set event in state so onChange can be invoked after setValue update, preventing 'bad setState' update from Table re-rendering simultaneously
        setEventOnChange(extendedEvent);
      }
    },
    [onChange, value]
  );

  // Hooks - effects
  useEffectDidUpdate(() => {
    if (onChange) onChange(eventOnChange);
  }, [eventOnChange]);

  useEffect(() => {
    window.addEventListener('click', closeMenu, true);

    return () => {
      window.removeEventListener('click', closeMenu, true);
    };
  }, [closeMenu, window]);

  useEffect(() => {
    autoFocus();
  }, [autoFocus, isOpen]);

  useEffect(() => {
    const initialLabel = options.find((option) => option.value === value)?.label;
    if (initialLabel) {
      setLabel(initialLabel);
    }
  }, [options, value]);

  // Vars
  const classes = clsx('eb-dropdown_filter-selected', {
    'eb-dropdown_filter-selected-focus': isOpen
  });
  const dropdownClasses = clsx('eb-dropdown_filter-list', {
    'eb-dropdown_filter-list-closed': !isOpen
  });

  // Render
  return (
    <div className="eb-dropdown_filter">
      <div
        className={classes}
        onClick={() => !isDisabled && setIsOpen(!isOpen)}
        onKeyDown={(event) => onKeyDown([{ event, key: ' ', handler: setIsOpen }])}
        role="button"
        tabIndex={0}
      >
        {label}
        <Icon
          className="eb-dropdown_filter-dropdown"
          isDisabled={isDisabled}
          name="dropdown"
          title={`${isOpen ? 'Close' : 'Open'} pagination`}
        />
      </div>
      <div className={dropdownClasses}>
        {options.map((item, index) => (
          <DropdownFilterOption
            isCurrent={item.value === value}
            key={item.label}
            label={item.label}
            selectOption={selectOption}
            tabIndex={index}
            value={item.value}
          >
            {item.label}
          </DropdownFilterOption>
        ))}
      </div>
    </div>
  );
};

export { DropdownFilter };
