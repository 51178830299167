// Global
import React from 'react';

// Components
import { Button } from 'components/Button';

export const useSearchButton = () => {
  const [isSearchInputActive, setIsSearchInputActive] = React.useState(false);

  const buttonIcon = isSearchInputActive ? 'primary' : 'secondary';

  return {
    searchButton: (
      <Button
        icon="search"
        intent={buttonIcon}
        onClick={() => setIsSearchInputActive(!isSearchInputActive)}
        type="icon"
      />
    ),
    isSearchInputActive
  };
};
