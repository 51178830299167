// Globals
import './styles.scss';
import React from 'react';

// Components
import { Heading } from 'components/Heading';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';

// Types
import { FILE_UPLOAD_ERROR } from 'hooks/useFileUploadManager/types';

// Props
export interface ModalFileUploadFailureProps {
  error: FILE_UPLOAD_ERROR | string;
  onBack: () => void;
}

// Component
const ModalFileUploadFailure: React.FC<ModalFileUploadFailureProps> = ({ error, onBack }) => {
  const headingText =
    error === FILE_UPLOAD_ERROR.BAD_FILE_TYPE
      ? 'Please upload a CSV file'
      : 'We couldn’t read this file';

  // Render
  return (
    <div className="eb-modal_file_upload_failure">
      <Icon className="eb-modal_file_upload_failure-img" name="warning" />

      <Heading className="eb-modal_file_upload_failure-heading" size={22} type="h4">
        {headingText}
      </Heading>

      <Button className="eb-modal_file_upload_failure-button" onClick={onBack}>
        OK
      </Button>
    </div>
  );
};

export { ModalFileUploadFailure };
