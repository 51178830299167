// Global
import React from 'react';

// Components
import { ModalSessionExpiration } from 'modals/ModalSessionExpiration';

// Services
import { doGetOrganizationDetails } from 'services/organizations/async';
import {
  getEntitlementEbPortalCompanyCode,
  getEntitlementEbUserAccessLevel
} from 'services/auth/selectors';
import { setOrganizationSelected } from 'services/organizations/reducers';

// Misc
import { AccessLevel } from 'services/auth/types';
import { EbDispatch } from 'store';
import { Navigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTokenManager } from 'hooks/useTokenManager';

interface PageProps {
  children: React.ReactNode;
}

/**
 * Wrapper component containing business logic needed in all pages
 */
export const Page: React.FC<PageProps> = ({ children }) => {
  // Hooks
  const dispatch: EbDispatch = useDispatch();
  const { companyCode } = useParams();

  // Hooks - selectors
  const userCompanyCodes = useSelector(getEntitlementEbPortalCompanyCode);
  const userGroupAccessLevel = useSelector(getEntitlementEbUserAccessLevel);

  // Hooks - state
  const [isSessionModalOpen, setIsSessionModalOpen] = React.useState(false);

  // Hooks - custom
  const { isSessionExpiring } = useTokenManager();

  // Hooks - effects
  React.useEffect(() => {
    if (companyCode) {
      dispatch(setOrganizationSelected(companyCode));
      void dispatch(doGetOrganizationDetails(companyCode));
    } else {
      dispatch(setOrganizationSelected(''));
    }
  }, [companyCode, dispatch]);

  React.useEffect(() => {
    setIsSessionModalOpen(isSessionExpiring);
  }, [isSessionExpiring]);

  if (
    companyCode &&
    userGroupAccessLevel !== AccessLevel.FULL &&
    !userCompanyCodes.includes(companyCode)
  ) {
    return <Navigate to="/not-found" />;
  }

  return (
    <>
      {children}

      <ModalSessionExpiration
        hide={() => setIsSessionModalOpen(false)}
        isVisible={isSessionModalOpen}
      />
    </>
  );
};
