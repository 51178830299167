import { EbState } from 'store';
import { Company } from './types';
import { createSelector } from 'reselect';
import { initialCompany } from './initialState';

export const getOrganizations = createSelector(
  (state: EbState) => state?.organizations,
  (organizations) => organizations
);

// Selectors - sub-reducers
export const getOrganizationsCompanyCodes = createSelector(
  getOrganizations,
  (organizations) => organizations.companyCodes
);

export const getOrganizationsLists = createSelector(
  getOrganizations,
  (organizations) => organizations.list
);

export const getOrganizationsParams = createSelector(
  getOrganizations,
  (organizations) => organizations.params
);

export const getOrganizationsSelected = createSelector(
  getOrganizations,
  (organizations) => organizations.selected
);

export const getOrganizationsSelectedOrganizationDetails = createSelector(
  getOrganizations,
  (organizations): Company => organizations.selectedOrganizationDetails || initialCompany
);

// Selectors - params
export const getOrganizationsParamsOrgTotal = createSelector(
  getOrganizationsParams,
  (params) => params.total || 0
);

export const getOrganizationsParamsPageTotal = createSelector(
  getOrganizationsParams,
  (params) => params.totalPages
);

export const getOrganizationsParamsPage = createSelector(
  getOrganizationsParams,
  (params) => params.page
);

export const getOrganizationsParamsPageSize = createSelector(
  getOrganizationsParams,
  (params) => params.pageSize
);

// Selector Multiple Orgs
export const getOrganizationsCodeByPage = createSelector(
  getOrganizationsParamsPage,
  getOrganizationsCompanyCodes,
  (page, list) => list[page] || []
);

// Selector - Single Org
export const getOrganizationByCode = createSelector(
  getOrganizationsLists,
  (_: EbState, code: string) => code,
  (list, code) => list[code] || initialCompany
);

export const getOrganizationFilesByOrgCode = createSelector(
  getOrganizationByCode,
  (org) => org.files ?? []
);

export const getOrganizationRecentFileByOrgCode = createSelector(
  getOrganizationFilesByOrgCode,
  (orgFiles) => orgFiles?.[0] || {}
);

// Selector - Organization Name
export const getOrganizationNameByCode = createSelector(
  getOrganizationsSelected,
  getOrganizationsLists,
  (code, list) => list[code]?.org.name || ''
);

export const getOrganizationName = createSelector(
  getOrganizationsSelectedOrganizationDetails,
  (organizationDetails) => organizationDetails.org.name
);

export const getOrganizationIsProdEnv = createSelector(
  getOrganizationsSelectedOrganizationDetails,
  (organizationDetails) => organizationDetails.org.isProd ?? false
);

export const getOrganizationIsSupressTermEmails = createSelector(
  getOrganizationsSelectedOrganizationDetails,
  (organizationDetails) => organizationDetails.org?.suppressTerminationEmails ?? false
);

export const getOrganizationIsSupressPortabilityEmails = createSelector(
  getOrganizationsSelectedOrganizationDetails,
  (organizationDetails) => organizationDetails.org?.suppressPortabilityEmails ?? false
);

export const getOrganizationsSelectedOrganizationFiles = createSelector(
  getOrganizationsSelectedOrganizationDetails,
  (org) => org.files ?? []
);

export const getOrganizationsSelectedOrganizationRecentFile = createSelector(
  getOrganizationsSelectedOrganizationFiles,
  (orgFiles) => orgFiles?.[0] || {}
);

export const getOrganizationCompanyCode = createSelector(
  getOrganizationsSelectedOrganizationDetails,
  (organizationDetails) => organizationDetails.org.code
);

export const getOrganizationIsPartnerX = createSelector(
  getOrganizationsSelectedOrganizationDetails,
  (organizationDetails) => organizationDetails.org.partnerX
);
