// Globals
import './styles.scss';
import React from 'react';

// Component
const IconBadgeCount: React.FC<{ count: number }> = ({ count = 0 }) => {
  if (count === 0) {
    return null;
  }

  return <span className="eb-icon_badge_count">{count > 9 ? '9+' : count}</span>;
};

export { IconBadgeCount };
