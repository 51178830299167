import { ReactNode } from 'react';

export enum Status {
  ERROR = 'error',
  SUCCESS = 'success',
  PENDING = 'pending',
  WARNING = 'warning'
}

export type TagStatusProps = {
  children: ReactNode;
  className?: string;
  status?: Status;
};
