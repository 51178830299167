// Global
import React from 'react';
import './styles.scss';

// Components
import { Icon } from 'components/Icon';

// Misc
import onKeyDown from '@isubscribed/fat-tony/onKeyDown';
import { DropdownFilterOptionProps } from './types';

const DropdownFilterOption: React.FC<DropdownFilterOptionProps> = ({
  children,
  disabled,
  label,
  value,
  selectOption,
  isCurrent,
  tabIndex
}) => {
  // Handlers
  const handleSelectOption = React.useCallback(
    (event: React.MouseEvent) => {
      !disabled && selectOption(event, label, value);
    },
    [selectOption, value, label, disabled]
  );

  const handleArrowDown = () => {
    const nextElementSibling = (document.activeElement?.parentNode as HTMLElement)
      ?.nextElementSibling;
    if (!nextElementSibling) {
      return;
    }

    const nextChildSibling = nextElementSibling.firstChild as HTMLElement;
    return nextChildSibling && nextChildSibling.focus();
  };

  const handleArrowUp = () => {
    const previousElementSibling = (document.activeElement?.parentNode as HTMLElement)
      ?.previousElementSibling as HTMLElement;
    const previousChildSibling = previousElementSibling?.firstChild as HTMLElement;

    return (
      previousChildSibling && previousChildSibling.tabIndex > -1 && previousChildSibling.focus()
    );
  };

  const handleOptionKeys = (event: React.KeyboardEvent) => {
    onKeyDown([
      {
        event,
        key: 'Enter',
        handler: handleSelectOption
      },
      {
        event,
        key: 'Tab'
      },
      {
        event,
        key: 'ArrowDown',
        handler: handleArrowDown
      },
      {
        event,
        key: 'ArrowUp',
        handler: handleArrowUp
      }
    ]);
  };

  // Render
  return (
    <div
      className="eb-dropdown_filter-list-item"
      data-type="eb-dropdown_filter-list-item"
      onClick={handleSelectOption}
      onKeyDown={handleOptionKeys}
      role="button"
      tabIndex={tabIndex}
    >
      {children}
      {!disabled && isCurrent && (
        <Icon className="eb-dropdown_filter-check" name="checkmark" title="check" />
      )}
    </div>
  );
};

export { DropdownFilterOption };
