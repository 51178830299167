import { createSelector } from 'reselect';
import { EbState } from 'store';
import { OrgFile, OrgFilesParamState, FilesState, FileListItem, FilesParamState } from './types';

export const initialOrgFilesParams: OrgFilesParamState = {
  pageSize: 25,
  page: 0,
  totalPages: 0,
  total: 0
};

export const initialFilesListParams: OrgFilesParamState = {
  pageSize: 100,
  page: 0,
  totalPages: 0,
  total: 0
};

export const initialState: FilesState = {
  allFiles: { list: [], params: initialFilesListParams },
  orgFiles: { list: [], params: initialOrgFilesParams }
};

export const getFiles = createSelector(
  (state: EbState) => state?.files,
  (files): FilesState => files
);

export const getCompanyFilesState = createSelector(getFiles, (filesState) => filesState?.orgFiles);

export const getAllFilesState = createSelector(getFiles, (filesState) => filesState?.allFiles);

/** Org files **/
export const getCompanyFiles = createSelector(
  getCompanyFilesState,
  (filesState): OrgFile[] => filesState?.list || []
);

export const getCompanyFilesParams = createSelector(
  getCompanyFilesState,
  (filesState): OrgFilesParamState => filesState?.params ?? initialOrgFilesParams
);

export const getCompanyFilesParamsPageSize = createSelector(
  getCompanyFilesParams,
  (params): number => params.pageSize
);

export const getCompanyFilesParamsPage = createSelector(
  getCompanyFilesParams,
  (params): number => params.page
);

export const getCompanyFilesParamsTotal = createSelector(
  getCompanyFilesParams,
  (params): number => params.total || 0
);

export const getCompanyFilesParamsTotalPages = createSelector(
  getCompanyFilesParams,
  (params): number => params.totalPages
);

/** All Files **/
export const getFilesList = createSelector(
  getAllFilesState,
  (filesState): FileListItem[] => filesState?.list || []
);

export const getFilesParams = createSelector(
  getAllFilesState,
  (filesState): FilesParamState => filesState?.params ?? initialFilesListParams
);

export const getFilesParamsPageSize = createSelector(
  getFilesParams,
  (params): number => params.pageSize
);

export const getFilesParamsPage = createSelector(getFilesParams, (params): number => params.page);

export const getFilesParamsTotal = createSelector(
  getFilesParams,
  (params): number => params.total || 0
);

export const getFilesParamsTotalPages = createSelector(
  getFilesParams,
  (params): number => params.totalPages
);
