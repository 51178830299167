// Globals
import React, { useRef, FC } from 'react';
import { useLink } from 'react-aria';
import './styles.scss';

// Components
import { Icon } from 'components/Icon';

// Types
import { AriaLinkProps, KeyDownData } from './types';
import { EbTrackingEventType } from 'services/analytics/types';
import type { AriaLinkOptions } from '@react-aria/link';
import type { PressEvent, KeyboardEvent } from '@react-types/shared';

// Misc
import clsx from 'clsx';
import { getWindow } from 'ssr-window';
import onKeyDown from '@isubscribed/fat-tony/onKeyDown';
import { useTracking } from 'hooks/useTracking';
import { useNavigate } from 'react-router-dom';

// Util
const reduceKeyDownHandlers = (handlers: KeyDownData[], event: KeyboardEvent) =>
  handlers.reduce((array: KeyDownData[], data: KeyDownData) => {
    // Add event from onKeyDown to data
    data.event = event;
    array.push(data as Required<KeyDownData>);
    return array;
  }, []);

// Component
const AriaLink: FC<AriaLinkProps> = ({
  className,
  display,
  icon,
  intent = 'none',
  isDisabled,
  isFullWidth,
  keyDownData = [],
  trackingDisabled,
  onClick,
  isNewTab = false,
  path,
  href,
  tracking,
  children,
  ...rest
}) => {
  // PropType error
  if (href && path) throw new Error('AriaLink cannot use both href and path, choose one');

  // Hooks
  const ref = useRef(null);
  const navigate = useNavigate();
  const window = getWindow();
  const { linkProps } = useLink(
    {
      onKeyDown: (event: KeyboardEvent) => handleOnKeyDown(event),
      onPress: (event: PressEvent) => handleOnClick(event),
      elementType: 'div',
      isDisabled,
      ...rest
    } as AriaLinkOptions,
    ref
  );

  // Hooks - custom
  const trackEvent = useTracking();

  // Handlers
  const handleOnKeyDown = (event: KeyboardEvent) => {
    const eventData = reduceKeyDownHandlers(keyDownData, event);
    onKeyDown([
      // Optional onKeyDown handlers
      ...eventData,
      // Always fire handleOnClick on 'Enter'
      {
        event,
        key: 'Enter',
        handler: handleOnClick
      }
    ]);
  };

  const handleOnClick = (event: PressEvent) => {
    if (isDisabled) return;

    if (!trackingDisabled) {
      let action = '';
      if (tracking?.action) action = `lnk_${tracking.action}`;
      else if (typeof children === 'string') action = `lnk_${children.toLowerCase()}`;
      else {
        // Construct action debug token
        const actionPayload = {
          className,
          path: path ?? '',
          href: href ?? '',
          intent
        };

        action = 'lnk_' + btoa(JSON.stringify(actionPayload));
      }

      trackEvent({
        ...tracking,
        action_name: tracking?.action_name ?? children?.toString(),
        action,
        event: EbTrackingEventType.ui_click
      });
    }

    // Optional onClick
    if (onClick) {
      onClick(event);
    }

    // Relative path
    if (path) {
      navigate(path);
    } else if (href && isNewTab) {
      // Absolute path
      window.open(href, '_blank');
    } else if (href && !isNewTab) {
      window.open(href, '_self');
    }
  };

  // Vars
  const classes = clsx('eb-aria_link', className, {
    'eb-aria_link-button': intent !== 'none',
    'eb-aria_link-primary': intent === 'primary',
    'eb-aria_link-secondary': intent === 'secondary',
    'eb-aria_link-text': intent === 'text',
    'eb-aria_link-inline': display === 'inline',
    'eb-aria_link-disabled': isDisabled,
    'eb-aria_link-full-width': isFullWidth
  });

  // Render
  return (
    <div {...linkProps} className={classes} ref={ref}>
      {Boolean(icon) && <Icon className="eb-aria_link-icon" name={icon} />}
      {children}
    </div>
  );
};

export { AriaLink };
