// Global
import React from 'react';
import './styles.scss';

// Components
import { Anchor } from 'components/Anchor';

const Footer: React.FC = () => {
  return (
    <footer className="eb-footer">
      <Anchor className="eb-footer-link" href="/">
        © Aura Portal {new Date().getFullYear()}
      </Anchor>
    </footer>
  );
};

export { Footer };
