// Globals
import React, { useEffect, useState, ReactNode } from 'react';

// Misc
import { createPortal } from 'react-dom';

// Props
interface PortalProps {
  className: string;
  children: ReactNode;
}

// Component
const Portal: React.FC<PortalProps> = ({ className, children }) => {
  // Hooks - state
  const [root] = useState<Element | null>(document.querySelector('#app'));
  const [portal] = useState<HTMLElement>(document.createElement('div'));

  // Hooks - effects
  useEffect(() => {
    root?.appendChild(portal);

    return () => {
      root?.removeChild(portal);
    };
  }, [portal, root]);

  // Render
  portal.className = className;
  return createPortal(children, portal);
};

export { Portal };
