import { Params, Path } from 'react-router-dom';
import { routes } from 'routes';

export const applyParamsToPath = (path: string, pathParams: Params): string => {
  return Object.entries(pathParams).reduce(
    (cleanPath, [param, value]) => cleanPath?.replace(`/:${param}`, `/${value}`),
    path
  );
};

export const removePathParamsValues = (path: string, pathParams: Params): string => {
  return Object.entries(pathParams).reduce(
    (cleanPath, [param, value]) => cleanPath?.replace(`/${value}`, `/:${param}`),
    path
  );
};

/*
Method to generate crumbs from active route path
return an array of object for breadcrumbs
 */
export const getCrumbRoutes = (location: Path, pathParams: Params) => {
  return routes.filter(({ path }) => {
    const routeWithoutParams = removePathParamsValues(location.pathname, pathParams);

    return path && routeWithoutParams.includes(path);
  }, []);
};
