// Globals
import React from 'react';

// Misc
import { ErrorMap } from './types';

// Errors organized by category
export const errors: ErrorMap = {
  credentials: {
    'Invalid Request':
      "Sorry, something you sent us wasn't formatted correctly. Please check your submission or contact support.",
    InvalidCredential: 'Sorry, your session has expired. Please log back in.',
    InvalidToken: 'Sorry, your session has expired. Please log back in.',
    InvalidCredentials: 'Incorrect email or password',
    InvalidAliasOrPassword: 'Incorrect email or password',

    PasswordNotDifferent:
      'There was an issue updating your password, please choose a different password.',

    InvalidMfaCode: 'Incorrect security code. Please try again',

    RequestThrottled:
      "Looks like you've made too many access attempts recently. Please try again later.",
    UserSigninLocked:
      'Your account has been locked due to too many sign in attempts. For help, please contact 833-552-2123.'
  },

  general: {
    'default.duplicate': 'Email already exists',
    UnknownError:
      "Hmm... looks like we've encountered an issue. For help please contact our customer support team at 833-552-2123."
  }
};

export const ebErrors: Record<string, string> = {};

// Subsets of errors
export const credentialsErrors = errors.credentials;
export const generalErrors = errors.general;

// All errors
export const allErrors = flattenObject(errors);

// Flattens errors to remove categories
function flattenObject(obj: Record<string, string | any>) {
  const flattened: Record<string, string> = {};

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null && !React.isValidElement(obj[key])) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });
  return flattened;
}
