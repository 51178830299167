// Globals
import './styles.scss';
import React from 'react';

// Component
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { Search } from 'components/Search';

// Services
import { doGetOrganizations } from 'services/organizations/async';
import { getEndpointPending } from 'services/api/selectors';
import { getOrganizationsParamsOrgTotal } from 'services/organizations/selectors';
import { getRefreshTokenAlias } from 'services/auth/selectors';
import { resetParams, setSearch } from 'services/organizations/reducers';

// Misc
import { EbDispatch, EbState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectDidUpdate } from 'hooks/useEffectDidUpdate';
import { useSearchButton } from 'hooks/useSearchButton';

// Component
const OrganizationsHeader: React.FC = () => {
  // Hooks
  const dispatch: EbDispatch = useDispatch();
  const { searchButton, isSearchInputActive } = useSearchButton();

  // Selector
  const totalOrg = useSelector(getOrganizationsParamsOrgTotal);
  const userAlias = useSelector(getRefreshTokenAlias);
  const isRequestPending = useSelector((state: EbState) =>
    getEndpointPending(state, doGetOrganizations)
  );

  // Hooks - useEffect
  useEffectDidUpdate(() => {
    if (!isSearchInputActive) {
      dispatch(setSearch(''));
      void dispatch(doGetOrganizations());
    }
  }, [isSearchInputActive, dispatch]);

  // Hooks - State
  const orgTotalText = totalOrg > 1 ? `${totalOrg} Organizations` : `${totalOrg} Organization`;

  const suggestions = (
    <>
      <Paragraph>Suggestions:</Paragraph>
      <Paragraph>Make sure that organization name is spelled correctly.</Paragraph>
      <Paragraph>Try different file organization name.</Paragraph>
    </>
  );

  // Handler
  const onSubmit = async (text: string) => {
    const search = text.toLowerCase();
    void dispatch(setSearch(search));
    await dispatch(doGetOrganizations());
  };

  const onCancel = () => {
    void dispatch(resetParams());
    void dispatch(doGetOrganizations());
  };

  // Render subheading search
  const renderSearch = () => {
    switch (isSearchInputActive) {
      case false:
        return (
          <div className="eb-organizations_header-sub-heading">
            <Paragraph className="eb-organizations_header-sub-heading-org-total">
              {isRequestPending ? '' : `${orgTotalText} total`}
            </Paragraph>
          </div>
        );
      default:
        return (
          <Search
            onCancel={onCancel}
            onSubmit={onSubmit}
            placeHolder="Search by organization name"
            request={doGetOrganizations}
            suggestions={suggestions}
            totalResultsFound={totalOrg}
          />
        );
    }
  };

  return (
    <div className="eb-organizations_header">
      <div className="eb-organizations_header-heading">
        <Heading className="eb-organizations_header-heading-text" size={32} type="h1">
          Hello, {userAlias}
        </Heading>
        {searchButton}
      </div>

      {renderSearch()}
    </div>
  );
};

export { OrganizationsHeader };
