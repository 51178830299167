// Globals
import './styles.scss';
import React from 'react';

// Components
import { Icon } from 'components/Icon';

// Types
import { BadgeProps, BadgeStatus } from 'components/Badge/types';

// Misc
import clsx from 'clsx';

// Component
const BadgeIconText: React.FC<Partial<BadgeProps>> = ({
  children,
  className = '',
  icon,
  status = BadgeStatus.DEFAULT
}) => {
  const classes = clsx(
    'eb-badge_icon_text',
    {
      'eb-badge_icon_text-critical': status === BadgeStatus.ERROR,
      'eb-badge_icon_text-pending': status === BadgeStatus.PENDING,
      'eb-badge_icon_text-success': status === BadgeStatus.SUCCESS
    },
    className
  );

  return (
    <div className={classes}>
      <Icon name={icon} />
      <span className="eb-badge_icon_text-status">{children}</span>
    </div>
  );
};

export { BadgeIconText };
