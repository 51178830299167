// Globals
import './styles.scss';
import React from 'react';

// Components
import { Icon } from 'components/Icon';

// Misc
import { ButtonIconProps } from './types';
import clsx from 'clsx';

// Component
const ButtonIcon: React.FunctionComponent<ButtonIconProps> = ({
  badge,
  className,
  hoverText,
  icon,
  intent = 'primary',
  isDisabled,
  // Destructure type since it's needed for ButtonText but not this component
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type,
  ...restProps
}) => {
  // Vars
  const classes = clsx('eb-button_icon', className);
  const buttonClasses = clsx('eb-button_icon-button', {
    'eb-button_icon-button-primary': intent === 'primary',
    'eb-button_icon-button-secondary': intent === 'secondary'
  });

  // Render
  return (
    <div className={classes} role="button" tabIndex={0}>
      <button className={buttonClasses} disabled={isDisabled} type="button" {...restProps}>
        <Icon badge={badge} name={icon} tooltipText={hoverText} />
      </button>
    </div>
  );
};

export { ButtonIcon };
