import * as endpoints from 'endpoints';
import { ApiDomain } from 'services/api/types';
import { eb } from 'services/api/async';
import { EbThunk } from 'store/types';
import { getOrganizationsSelected } from 'services/organizations/selectors';
import { BulkActionStatus, GetCompanyPeopleResponse, MemberAction } from './types';
import { setCompanyPeople, setMemberDetails, updateCompanyPeople } from './reducers';
import { getCurrentCompanyPeople, getPeopleParams } from './selectors';
import { objectToAxiosParams } from 'utils/objectToApiParams';

doGetCurrentCompanyPeople.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.PEOPLE_GET,
  method: 'GET'
};

doGetMemberDetails.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.MEMBER_DETAILS_GET,
  method: 'GET'
};

doDeleteMember.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.MEMBER_ACTION,
  method: 'DELETE'
};

doBulkActionMembers.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.MEMBERS_BULK_ACTION,
  method: 'POST'
};

export function doGetCurrentCompanyPeople(): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      data: { endpoint, method }
    } = doGetCurrentCompanyPeople;
    const { page, pageSize, search, filters } = getPeopleParams(getState());
    const companyCode = getOrganizationsSelected(getState());
    const params = objectToAxiosParams({ page, pageSize, search, ...filters });

    const people = await dispatch(
      eb<GetCompanyPeopleResponse>({
        endpoint,
        method,
        params,
        pathParams: [companyCode]
      })
    );
    dispatch(setCompanyPeople(people, companyCode));
  };
}

export function doGetMemberDetails(userId: string): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    // Request Data
    const {
      data: { endpoint, method }
    } = doGetMemberDetails;

    const companyCode = getOrganizationsSelected(getState());

    const resp = await dispatch(
      eb<any>({
        endpoint,
        method,
        pathParams: [companyCode, userId]
      })
    );

    dispatch(setMemberDetails(resp));
  };
}

export function doDeleteMember(userId: string): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      data: { endpoint, method }
    } = doDeleteMember;

    const companyCode = getOrganizationsSelected(getState());

    await dispatch(
      eb<any>({
        endpoint,
        method,
        pathParams: [companyCode, userId]
      })
    );
  };
}

export function doBulkActionMembers(
  action: MemberAction,
  userIds: string[],
  suppressNotification = false
): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    // Request Data
    const {
      data: { endpoint, method }
    } = doBulkActionMembers;
    const companyCode = getOrganizationsSelected(getState());
    const data = {
      action,
      userIds: userIds,
      suppressNotification
    };

    await dispatch(
      eb<any>({
        endpoint,
        method,
        data,
        pathParams: [companyCode]
      })
    );

    const people = getCurrentCompanyPeople(getState());
    const peopleWithUpdatedBulkActionStatus = people.map((record) => {
      return userIds.includes(record.userId)
        ? {
            ...record,
            bulkAction: {
              ...record.bulkAction,
              action: MemberAction.TERM,
              state: BulkActionStatus.PROCESSING
            }
          }
        : record;
    });

    dispatch(updateCompanyPeople(peopleWithUpdatedBulkActionStatus));
  };
}
