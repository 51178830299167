// Globals
import './styles.scss';
import React, { useState } from 'react';

// Components
import { Icon } from 'components/Icon';

// Misc
import { InputPasswordProps } from './types';

// Component
const InputPassword: React.FC<InputPasswordProps> = (props) => {
  // Props
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    inputLabel, // Destructure from being passed to input element
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onInput, // Destructure unnecessary handlers
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tracking,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    trackingEnabled,
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    thousandSeparator,
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onValueChange,
    ...restProps
  } = props;

  // Hooks - state
  const [isVisible, setIsVisible] = useState(false);

  // Handlers
  const toggleInputType = () => setIsVisible((prevIsVisible) => !prevIsVisible);

  // Vars
  const iconName = isVisible ? 'eye_closed' : 'eye_opened';
  const iconTooltip = isVisible ? 'Password visible' : 'Password masked';
  const inputProps = {
    ...restProps,
    type: isVisible ? 'text' : 'password'
  };

  // Render
  return (
    <div className="eb-input_password">
      <input {...inputProps} />
      <Icon
        className="eb-input_password-icon-toggle"
        name={iconName}
        onClick={toggleInputType}
        title={iconName}
        tooltipText={iconTooltip}
      />
    </div>
  );
};

export { InputPassword };
