// Global
import './styles.scss';
import React from 'react';

// Components
import { AriaButton } from 'components/AriaButton';
import { Button } from 'components/Button';
import { Dot } from 'components/Dot';
import { ErrorServer } from 'components/ErrorServer';
import { Heading } from 'components/Heading';
import { LayoutPage } from 'components/LayoutPage';
import { Loading } from 'components/Loading';
import { ModalConfirmation } from 'modals/ModalConfirmation';
import { ModalInviteUser } from 'modals/ModalInviteUser';
import { Paragraph } from 'components/Paragraph';
import { Toast } from 'components/Toast';

// Services
import { doGetAdminUsers, doRemoveUser } from 'services/adminUsers/async';
import { getEndpointPending } from 'services/api/selectors';
import { getEntitlementsCanCreateAdminUser } from 'services/auth/selectors';
import { getTotalUsers, getUsers } from 'services/adminUsers/selectors';
import { setToastError, setToastSuccess } from 'services/toast/reducers';

// Misc
import { EbDispatch, EbState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { UserManagementUser } from './User';
import { useParams } from 'react-router-dom';

function UserManagement() {
  // Hooks
  const dispatch: EbDispatch = useDispatch();
  const { companyCode } = useParams();

  // Hooks - state
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isCancelConfirmVisible, setIsCancelConfirmVisible] = React.useState(false);
  const [isConfirmRemoveModalVisible, setIsConfirmRemoveModalVisible] = React.useState(false);
  const [removeUserId, setRemoveUserId] = React.useState('');

  // Hooks - selectors
  const canCreateAdmin = useSelector(getEntitlementsCanCreateAdminUser);
  const totalAdminUsers = useSelector(getTotalUsers);
  const adminUsers = useSelector(getUsers);
  const isPending = useSelector((state: EbState) => getEndpointPending(state, doGetAdminUsers));
  const isError = useSelector((state: EbState) => getEndpointPending(state, doGetAdminUsers));
  const isRemovePending = useSelector((state: EbState) => getEndpointPending(state, doRemoveUser));

  // Hooks - useEffect
  React.useEffect(() => {
    void dispatch(doGetAdminUsers());
  }, [dispatch]);

  // Handlers
  const onRemoveUserClick = React.useCallback((userId: string) => {
    setIsConfirmRemoveModalVisible(true);
    setRemoveUserId(userId);
  }, []);

  const handleRemoveUser = React.useCallback(async () => {
    try {
      await dispatch(doRemoveUser(removeUserId));
      void dispatch(doGetAdminUsers());
      dispatch(setToastSuccess('Member removed'));
    } catch (error) {
      dispatch(setToastError('An error occurred while removing member'));
    } finally {
      setRemoveUserId('');
      setIsConfirmRemoveModalVisible(false);
    }
  }, [removeUserId, dispatch]);

  // Vars
  const withinCompany = companyCode ? true : false;

  // Render
  const renderHeading = () => (
    <div className="eb-user_management-heading">
      <Heading size={32} type="h1">
        User Management
      </Heading>
    </div>
  );

  const renderUsers = () => {
    return (
      <div className="eb-user_management-users-list">
        {/*TODO: get current user info to display here*/}
        {/*<UserManagementUser*/}
        {/*  email="email@email.com"*/}
        {/*  firstName="john"*/}
        {/*  id={0}*/}
        {/*  lastName="doe"*/}
        {/*  userType="super admin"*/}
        {/*/>*/}
        {adminUsers.map((user, index) => {
          return (
            <UserManagementUser
              adminGroups={user.adminUserGroups}
              email={user.email}
              firstName={user.name?.first}
              index={index}
              key={user.userId}
              lastName={user.name?.last}
              loggedInAt={user?.loggedInAt ? user?.loggedInAt : ''}
              onUserRemoveClick={() => onRemoveUserClick(user.userId)}
            />
          );
        })}
        {canCreateAdmin ? (
          <Button
            className="eb-user_management-invite-button"
            icon="plus"
            intent="text"
            onClick={() => setIsModalVisible(true)}
          >
            Invite member
          </Button>
        ) : null}
      </div>
    );
  };

  const renderChildren = () => {
    const totalAdminText = `${totalAdminUsers} ${totalAdminUsers > 0 ? 'Admins' : `Admin`}`;

    switch (true) {
      case isPending:
        return <Loading>Loading...</Loading>;
      case isError:
        return <ErrorServer />;
      default:
        return (
          <>
            <div className="eb-user_management-subheading">
              <Paragraph>{totalAdminText}</Paragraph>
              {canCreateAdmin ? (
                <div>
                  <Dot className="eb-user_management-subheading-dot" />
                  <AriaButton
                    className="eb-user_management-subheading-invite"
                    onClick={() => setIsModalVisible(true)}
                    type="primary"
                  >
                    Invite
                  </AriaButton>
                </div>
              ) : null}
            </div>
            {renderUsers()}
          </>
        );
    }
  };

  return (
    <LayoutPage className="eb-user_management" heading={renderHeading()}>
      <Toast />
      {renderChildren()}

      {/** Modals **/}
      <ModalInviteUser
        hide={(confirmationRequired) =>
          confirmationRequired ? setIsCancelConfirmVisible(true) : setIsModalVisible(false)
        }
        isVisible={isModalVisible}
        onSubmit={() => setIsModalVisible(false)}
        withinCompany={withinCompany}
      />
      <ModalConfirmation
        isVisible={isCancelConfirmVisible}
        onPrimaryButtonClick={() => {
          setIsCancelConfirmVisible(false);
        }}
        onSecondaryButtonClick={() => {
          setIsCancelConfirmVisible(false);
          setIsModalVisible(false);
        }}
      />
      <ModalConfirmation
        isPending={isRemovePending}
        isVisible={isConfirmRemoveModalVisible}
        onPrimaryButtonClick={handleRemoveUser}
        onSecondaryButtonClick={() => setIsConfirmRemoveModalVisible(false)}
        primaryButtonText="Remove"
        secondaryButtonText="Cancel"
        text="This user will no longer be able to access the Portal."
      />
    </LayoutPage>
  );
}

export { UserManagement };
