// Globals
import React, { KeyboardEvent } from 'react';
import './styles.scss';

// Types
import { EbTrackingEventType } from 'services/analytics/types';
import { AriaButtonProps } from './types';

// Misc
import clsx from 'clsx';
import onKeyDown from '@isubscribed/fat-tony/onKeyDown';
import { useTracking } from 'hooks/useTracking';

// Component
const AriaButton: React.FunctionComponent<AriaButtonProps> = (props) => {
  const {
    'aria-label': ariaLabel,
    children,
    className,
    isDisabled,
    onClick = () => ({}),
    tracking,
    trackingDisabled,
    type,
    ...rest
  } = props;

  // Hooks - custom
  const trackEvent = useTracking();

  // Handlers
  const handleOnKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    onKeyDown([
      {
        event,
        key: 'Enter',
        handler: handleOnClick
      }
    ]);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    // Prevent click event from bubbling
    event.stopPropagation();

    // If disabled, do not run click onClick
    if (isDisabled) return;

    if (!trackingDisabled) {
      let action = '';
      if (tracking?.action) action = `btn_${tracking.action}`;
      else if (typeof children === 'string') action = `btn_${children}`;
      else if (props.className) {
        action = `btn_${String(className)}`;
      }

      trackEvent({
        ...tracking,
        action,
        action_name: tracking?.action_name ?? children?.toString(),
        event: EbTrackingEventType.ui_click
      });
    }

    if (onClick) {
      onClick(event);
    }
  };

  // Vars
  const classes = clsx('eb-aria_button', className, {
    // Apply "link" styles if type prop passed
    'eb-aria_button-link': type !== '',
    'eb-aria_button-disabled': isDisabled,
    'eb-aria_button-primary': type === 'primary',
    'eb-aria_button-secondary': type === 'secondary'
  });

  // Render
  return (
    <span
      aria-label={ariaLabel}
      className={classes}
      onClick={handleOnClick}
      onKeyDown={handleOnKeyDown}
      role="button"
      tabIndex={0}
      {...rest}
    >
      {children}
    </span>
  );
};

export { AriaButton };
