export interface DropdownIconProps {
  children: React.ReactNode;
  className?: string;
  hoverText?: string;
  icon: string;
  onClick?: (event: React.MouseEvent) => void;
}
export enum TEST_IDS {
  ICON = 'DROPDOWN_ICON',
  ICON_MSG = 'ICON_MSG'
}
