// Globals
import './styles.scss';
import React from 'react';

// Types
import { ModalBodyActionProps } from './types';

// Misc
import clsx from 'clsx';

// Component
function ModalBodyAction(props: ModalBodyActionProps) {
  const { actionBarButtonAction, actionBarButtonCancel, actionBarButtonSave, children } = props;

  // Vars
  const classes = clsx('eb-modal_body_action', {
    'eb-modal_body_action-buttons-all':
      actionBarButtonAction && actionBarButtonSave && actionBarButtonCancel,
    'eb-modal_body_action-buttons-only-action': !actionBarButtonSave && !actionBarButtonCancel,
    'eb-modal_body_action-buttons-only-save-cancel': !actionBarButtonAction
  });

  // Render
  return (
    <div className={classes}>
      {/* Body */}
      <div className="eb-modal-content">{children}</div>

      {/* Action bar */}
      <div className="eb-modal_body_action-action-bar">
        <div className="eb-modal_body_action-action-bar-button-action">{actionBarButtonAction}</div>
        <div className="eb-modal_body_action-action-bar-spacer" />
        <div className="eb-modal_body_action-action-bar-button-cancel">{actionBarButtonCancel}</div>
        <div className="eb-modal_body_action-action-bar-button-save">{actionBarButtonSave}</div>
      </div>
    </div>
  );
}

export { ModalBodyAction };
