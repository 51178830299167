// Globals
import './styles.scss';
import React from 'react';

// Components
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';

// Assets
import alertIllustration from 'assets/media/alert.svg';

interface ErrorServerProps {
  title?: string;
  subTitle?: string;
}

// Component
function ErrorServer(props: ErrorServerProps) {
  // Render
  return (
    <div className="eb-error_server">
      <img alt="Alert Icon" className="eb-error_server-image" src={alertIllustration} />
      <Heading align="center" className="eb-error_server-heading" size={26} type="h1">
        {props.title ?? 'Oops, looks like a server error.'}
      </Heading>
      <Paragraph align="center">
        {props.subTitle ?? 'Please try again later or contact Customer Support.'}
      </Paragraph>
    </div>
  );
}

export { ErrorServer };
