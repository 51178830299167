import { ApiActionTypes, ApiAllActions, ApiFailureParams, ApiParams } from './types';

// Action Creators
export const setPending = (data: ApiParams) => ({
  type: ApiActionTypes.SET_PENDING,
  ...data
});

export const setSuccess = (data: ApiParams) => ({
  type: ApiActionTypes.SET_SUCCESS,
  ...data
});

export const setFailure = (data: ApiFailureParams) => ({
  type: ApiActionTypes.SET_FAILURE,
  ...data
});

export const clearError = (request: any) => {
  const { data } = request;
  return { type: ApiActionTypes.CLEAR_ERROR, ...data };
};

// Root Reducer
export function api(endpoints: any = {}, action: ApiAllActions) {
  // @ts-ignore
  const { endpoint } = action;
  switch (action.type) {
    case ApiActionTypes.SET_PENDING:
    case ApiActionTypes.SET_SUCCESS:
    case ApiActionTypes.SET_FAILURE:
    case ApiActionTypes.CLEAR_ERROR:
      return {
        ...endpoints,
        [endpoint]: reduceMethods(endpoints[endpoint], action)
      };
    default:
      return endpoints;
  }
}

// Sub-Reducers
function reduceMethods(methods: any = {}, action: ApiAllActions) {
  // @ts-ignore
  const { method } = action;

  return {
    ...methods,
    [method]: reduceRequest(methods[method], action)
  };
}

function reduceRequest(endpoint: any = {}, action: ApiAllActions) {
  return {
    data: data(endpoint.data, action),
    error: error(endpoint.error, action),
    params: params(endpoint.params, action),
    pathParams: pathParams(endpoint.pathParams, action),
    pending: pending(endpoint.pending, action),
    success: success(endpoint.success, action)
  };
}

function data(data = {}, action: ApiAllActions) {
  switch (action.type) {
    case ApiActionTypes.SET_PENDING: {
      return action.data ?? {};
    }
    case ApiActionTypes.SET_FAILURE:
    case ApiActionTypes.SET_SUCCESS:
      return data;
    default:
      return data;
  }
}

function error(error = {}, action: ApiAllActions) {
  switch (action.type) {
    case ApiActionTypes.SET_FAILURE:
      return action.error;
    case ApiActionTypes.SET_PENDING:
    case ApiActionTypes.CLEAR_ERROR:
      return {};
    default:
      return error;
  }
}

function pending(pending = undefined, action: ApiAllActions) {
  switch (action.type) {
    case ApiActionTypes.SET_PENDING:
      return true;
    case ApiActionTypes.SET_FAILURE:
    case ApiActionTypes.SET_SUCCESS:
      return false;
    default:
      return pending;
  }
}

function params(params = {}, action: ApiAllActions) {
  switch (action.type) {
    case ApiActionTypes.SET_PENDING:
      return action.params ?? {};
    case ApiActionTypes.SET_FAILURE:
    case ApiActionTypes.SET_SUCCESS:
      return params;
    default:
      return params;
  }
}

function pathParams(pathParams = [], action: ApiAllActions) {
  switch (action.type) {
    case ApiActionTypes.SET_PENDING:
      return action.pathParams ?? [];
    case ApiActionTypes.SET_FAILURE:
    case ApiActionTypes.SET_SUCCESS:
      return pathParams;
    default:
      return pathParams;
  }
}

function success(success = undefined, action: ApiAllActions) {
  switch (action.type) {
    case ApiActionTypes.SET_FAILURE:
      return false;
    case ApiActionTypes.SET_PENDING:
      return undefined;
    case ApiActionTypes.SET_SUCCESS:
      return true;
    default:
      return success;
  }
}
