// Globals
import './styles.scss';
import React from 'react';

// Components
import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { LayoutAuth } from 'components/LayoutAuth';
import { Paragraph } from 'components/Paragraph';

// Misc
import { useNavigate } from 'react-router-dom';

// Assets
import alert from 'assets/media/alert_outline.svg';

const Error404: React.FC = () => {
  // Hooks
  const navigate = useNavigate();

  // Render
  return (
    <LayoutAuth>
      <img alt="Alert image" className="eb-error_404-image" src={alert} />
      <Heading align="center" size={26} type="h1">
        Page not found
      </Heading>
      <Paragraph align="center" className="eb-error_404-paragraph" size={16}>
        The page you’re looking for cannot be found. Return to home page.
      </Paragraph>
      <Button className="eb-error_404-button" onClick={() => navigate('/')}>
        Back to home page
      </Button>
    </LayoutAuth>
  );
};

export { Error404 };
