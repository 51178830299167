import { FilterOption } from 'components/Filter/types';
import { startOfDay, startOfWeek, startOfMonth } from 'date-fns';

const FILTER_STATUSES = [
  { label: 'Accepted', key: 'green' },
  { label: 'Partially accepted', key: 'yellow' },
  { label: 'Rejected', key: 'red' }
];

export const FILTER_OPTIONS: FilterOption[] = [
  {
    key: 'statusColor',
    label: 'By status',
    values: FILTER_STATUSES,
    type: 'choice'
  },
  {
    key: 'uploadedAt',
    label: 'By uploaded date',
    values: [
      { label: 'Today', key: startOfDay(new Date()).toISOString() },
      {
        label: 'This week',
        key: startOfWeek(new Date()).toISOString()
      },
      {
        label: 'This month',
        key: startOfMonth(new Date()).toISOString()
      }
    ],
    type: 'choice'
  }
];
