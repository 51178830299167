import { string, ref } from 'yup';

const LOWER = /[a-z]/;
const UPPER = /[A-Z]/;
const NUMBER = /\d/;
const SYMBOL = /[^a-z\d]/i;

function validCharacters(val = '') {
  if (val.length < 8) {
    return false;
  }

  if (LOWER.test(val) === false) {
    return false;
  }

  if (NUMBER.test(val) === false) {
    return false;
  }

  if (SYMBOL.test(val) === false) {
    return false;
  }

  if (UPPER.test(val) === false) {
    return false;
  }

  return true;
}

const schemaPassword = string()
  .required('Password is required')
  .min(8, ' ')
  .max(100, ' ')
  .test({
    name: 'validCharacters',
    message: ' ',
    test: validCharacters
  })
  .when('email', {
    is: (val) => val,
    then: string().notOneOf([ref('email')], 'Password cannot be the same as email'),
    otherwise: string()
  });

export { schemaPassword };
