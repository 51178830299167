import * as endpoints from 'endpoints';
import { ApiDomain } from 'services/api/types';
import { amz, eb } from 'services/api/async';
import { EbThunk } from 'store/types';
import { getOrganizationsSelected } from 'services/organizations/selectors';
import {
  CuidUpdateRequest,
  GetCuidFilesResponse,
  GetCuidPresignedUrlResponse,
  UserData
} from './types';
import { setCompanyCuidFiles } from './reducers';
import { getCuidFilesParams } from './selectors';
import { objectToAxiosParams } from 'utils/objectToApiParams';
import config from 'config';

doGetCompanyCuidFiles.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.CUID_FILES,
  method: 'GET'
};

doGetCuidPreSignedUrl.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.CUID_PRESIGNED_URL,
  method: 'POST'
};

doUploadCuidFile.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.CUID_UPLOAD_FILE,
  method: 'PUT'
};

doRequestCuidResponse.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.CUID_RESPONSE,
  method: 'PUT'
};

doUpdateCuid.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.CUID_UPDATE,
  method: 'POST'
};

export function doGetCompanyCuidFiles(): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      data: { endpoint, method }
    } = doGetCompanyCuidFiles;
    const { page, pageSize, search } = getCuidFilesParams(getState());
    const companyCode = getOrganizationsSelected(getState());
    const params = objectToAxiosParams({ page, pageSize, search });

    const files = await dispatch(
      eb<GetCuidFilesResponse>({
        endpoint,
        method,
        params,
        pathParams: [companyCode]
      })
    );
    dispatch(setCompanyCuidFiles(files, companyCode));
  };
}

export function doGetCuidPreSignedUrl(fileName: string): EbThunk<Promise<string>> {
  return async (dispatch, getState) => {
    // Request
    const {
      data: { endpoint, method }
    } = doGetCuidPreSignedUrl;

    const companyCode = getOrganizationsSelected(getState());

    const response = await dispatch(
      eb<GetCuidPresignedUrlResponse>({
        endpoint,
        method,
        data: { fileName, companyCode, operation: 'put' }
      })
    );

    return response?.presignedUrl;
  };
}

export function doUploadCuidFile(file: File): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    // Request Data
    const {
      data: { endpoint, method }
    } = doUploadCuidFile;

    let fileName = file.name;

    const url = await dispatch(doGetCuidPreSignedUrl(fileName));

    const queryParams = url.substring(url.indexOf('?') + 1);
    const params = Object.fromEntries(new URLSearchParams(queryParams).entries());

    const companyCode = getOrganizationsSelected(getState());

    return await dispatch(
      amz({
        baseURL: config.cuid_api_origin,
        endpoint: endpoint,
        method,
        data: file,
        params,
        pathParams: [companyCode, fileName]
      })
    );
  };
}

export function doRequestCuidResponse(id: string): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      data: { endpoint, method }
    } = doRequestCuidResponse;
    const companyCode = getOrganizationsSelected(getState());

    await dispatch(
      eb<GetCuidFilesResponse>({
        endpoint,
        method,
        data: { companyCode, versionId: id }
      })
    );
  };
}

export function doUpdateCuid(
  { userId, companyUserId }: UserData,
  id: string
): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      data: { endpoint, method }
    } = doUpdateCuid;
    const companyCode = getOrganizationsSelected(getState());
    const data: CuidUpdateRequest = {
      userId,
      oldCompanyUserId: companyUserId,
      newCompanyUserId: id,
      companyCode
    };

    await dispatch(
      eb<GetCuidFilesResponse>({
        endpoint,
        method,
        data
      })
    );
  };
}
