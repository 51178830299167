import { Company } from './types';

export const initialParams = {
  page: 0,
  pageSize: 25,
  total: 0,
  totalPages: 0
};

export const initialCompany: Company = {
  org: {
    name: '',
    code: '',
    isProd: false,
    suppressTerminationEmails: false,
    suppressPortabilityEmails: false
  },
  programs: []
};
