import * as endpoints from 'endpoints';
import { ApiDomain } from 'services/api/types';
import { eb } from 'services/api/async';
import { EbThunk } from 'store/types';
import { PresignedUrl } from './types';

doDownloadFile.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.PRESIGNED_URL_DOWNLOAD_GET,
  method: 'POST'
};

requestResponseFile.data = {
  domain: ApiDomain.EB,
  endpoint: '/portal/companies/{1}/files/{2}/presigned_url',
  method: 'GET'
};

export function doDownloadFile(fileId: string): EbThunk<Promise<PresignedUrl>> {
  return async (dispatch) => {
    // Request
    const {
      data: { endpoint, method }
    } = doDownloadFile;

    const result = await dispatch(
      eb<PresignedUrl>({
        endpoint,
        method,
        data: { fileId }
      })
    );
    // do the download
    window.open(result.url);
    return result;
  };
}

export function requestResponseFile(
  fileId: string,
  companyCode: string
): EbThunk<Promise<PresignedUrl>> {
  return async (dispatch) => {
    // Request
    const {
      data: { endpoint, method }
    } = requestResponseFile;

    return await dispatch(
      eb<PresignedUrl>({
        endpoint,
        method,
        pathParams: [companyCode, fileId]
      })
    );
  };
}
