import { allErrors, ebErrors, generalErrors } from './errors';

export const parsePlatformError = ({ message: error }: { message?: string } = {}): string => {
  if (!error) {
    return '';
  }

  return allErrors[error] ?? generalErrors.UnknownError;
};

export const parseEbError = ({ message: error }: { message?: string } = {}): string => {
  if (!error) {
    return '';
  }

  return ebErrors[error] ?? error ?? generalErrors.UnknownError;
};
