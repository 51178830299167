/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// Globals
import './styles.scss';
import React, { useEffect } from 'react';

// Components
import { Icon } from 'components/Icon';
import { Portal } from 'components/Portal';
import { Transition } from 'react-transition-group';

// Services
import { setToastSuccess } from 'services/toast/reducers';

// Types
import { EbTrackingEventType } from 'services/analytics/types';
import { ModalProps } from './types';

// Misc
import clsx from 'clsx';
import onKeyDown from '@isubscribed/fat-tony/onKeyDown';
import { useTracking } from 'hooks/useTracking';
import { useDispatch } from 'react-redux';
import { useEffectDidUpdate } from 'hooks/useEffectDidUpdate';

// Component
function Modal(props: ModalProps) {
  const {
    className = '',
    children,
    hide,
    isClosable = true,
    isSuccess = false,
    isVisible = false,
    successToastMessage = 'Request has been processed successfully',
    tracking,
    trackingEnabled = false
  } = props;

  // Hooks
  const trackEvent = useTracking();

  // Hooks - dispatch
  const dispatch = useDispatch();

  // Hooks - effects

  // Handle effect for hiding modal
  useEffectDidUpdate(() => {
    if (isSuccess) {
      hide();
      dispatch(setToastSuccess(successToastMessage));
    }
  }, [isSuccess]);

  // Handle effect for classList
  useEffect(() => {
    const classList = document.querySelector('html')?.classList!;
    const lockClass = 'eb-modal-lock-scroll';

    // Prevent scroll behind modal when open
    if (isVisible) {
      classList.add(lockClass);
    } else {
      classList.remove(lockClass);
    }

    return () => {
      classList.remove(lockClass);
    };
  }, [isVisible]);

  // Handle effect for trackEvent
  useEffect(() => {
    // Track page enter and leave via analytics service
    if (trackingEnabled && isVisible) {
      trackEvent({
        ...tracking,
        event: EbTrackingEventType.ui_view
      });
    }

    return () => {
      if (trackingEnabled && isVisible) {
        trackEvent({
          ...tracking,
          event: EbTrackingEventType.page_leave
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  // Handlers
  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    onKeyDown([
      {
        event,
        key: 'Enter',
        handler: closeHandler
      }
    ]);
  };

  const stopClickBubble = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation();
  };

  const closeHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    hide();
    if (trackingEnabled && isVisible) {
      trackEvent({
        ...tracking,
        event: EbTrackingEventType.ui_click,
        action: 'btn_close'
      });
    }
  };

  // Vars
  const transitionProps = {
    in: isVisible,
    mountOnEnter: true,
    unmountOnExit: true,
    timeout: 100
  };

  // Render
  return (
    <Transition {...transitionProps}>
      {(state) => {
        const classes = clsx('eb-modal', className, {
          // Transitions
          'eb-modal-entering': state === 'entering',
          'eb-modal-entered': state === 'entered',
          'eb-modal-exiting': state === 'exiting',
          'eb-modal-exited': state === 'exited'
        });

        return (
          <Portal className={classes}>
            <div
              aria-modal="true"
              className="eb-modal-backdrop"
              onClick={stopClickBubble}
              onKeyDown={stopClickBubble}
              role="dialog"
            >
              <div className="eb-modal-container">
                {/* Icon */}
                {isClosable && (
                  <div
                    className="eb-modal-icon-container"
                    onClick={closeHandler}
                    onKeyDown={handleOnKeyDown}
                    role="button"
                    tabIndex={0}
                  >
                    <Icon className="eb-modal-icon" name="close" />
                  </div>
                )}

                {/* Body */}
                {children}
              </div>
            </div>
          </Portal>
        );
      }}
    </Transition>
  );
}

export { Modal };
