import * as endpoints from 'endpoints';
import config from 'config';
import { ApiDomain } from 'services/api/types';
import { amz, eb } from 'services/api/async';
import { EbThunk } from 'store/types';
import { getOrganizationsSelected } from 'services/organizations/selectors';
import { PresignedUrl, EnvType } from './types';

doGetPreSignedUrl.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.PRESIGNED_URL_GET,
  method: 'GET'
};

doUploadFile.data = {
  domain: ApiDomain.FTE,
  endpoint: endpoints.FILE_TRANSFORMER_EXTERNAL_PUT,
  method: 'PUT'
};

export function doGetPreSignedUrl(fileName: string, env?: string): EbThunk<Promise<PresignedUrl>> {
  return async (dispatch, getState) => {
    // Request
    const {
      data: { endpoint, method }
    } = doGetPreSignedUrl;

    const companyCode = getOrganizationsSelected(getState());

    return await dispatch(
      eb<PresignedUrl>({
        endpoint,
        method,
        params:
          env === EnvType.PROD ? { file_name: fileName, prod: true } : { file_name: fileName },
        pathParams: [companyCode]
      })
    );
  };
}

export function doUploadFile(file: File, env?: EnvType): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    // Request Data
    const {
      data: { endpoint, method }
    } = doUploadFile;

    let fileName = file.name;
    // append test_ to TEST env files and remove test_ for PROD env files
    if (env === EnvType.TEST && !fileName.startsWith('test_')) {
      fileName = `test_${fileName}`;
    } else if (env === EnvType.PROD && fileName.startsWith('test_')) {
      fileName = fileName.split('test_')[1];
    }
    const { url } = await dispatch(doGetPreSignedUrl(fileName, env));

    const queryParams = url.substring(url.indexOf('?') + 1);
    const params = Object.fromEntries(new URLSearchParams(queryParams).entries());

    const companyCode = getOrganizationsSelected(getState());

    return await dispatch(
      amz({
        baseURL: config.fte_api_origin,
        endpoint: endpoint,
        method,
        data: file,
        params,
        pathParams: [companyCode, fileName]
      })
    );
  };
}
