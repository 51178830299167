// Misc
import config from 'config';
import { getWindow } from 'ssr-window';
import { v1 as uuidv1, v4 as uuidv4, validate } from 'uuid';

// Utils
import { detectDevice } from 'utils/detectDevice';
import { getCookie, setCookie } from 'utils/cookies';

// Types
import { EbThunk } from 'store/types';

// Services
import { GlobalEbTrackingEventProps } from 'services/analytics/types';

const COOKIE_HASH_KEY = 'eb-hash';

export function getGenericEbTrackingEvent(): EbThunk<GlobalEbTrackingEventProps> {
  return () => {
    // Vars
    const event_id = uuidv4();

    // Referrer and href
    const window = getWindow();
    const source_href = window.location.href;
    const http_referrer = document.referrer;

    // Get page and viewport dimensions
    const { scrollWidth, scrollHeight } = document.body;
    const { clientWidth, clientHeight } = document.documentElement;

    // Visitor hash
    let visitor_hash = getCookie(COOKIE_HASH_KEY);

    // If no visitor hash saved, generate a new one
    if (!visitor_hash || !validate(visitor_hash)) {
      visitor_hash = uuidv1();
      setCookie(COOKIE_HASH_KEY, visitor_hash);
    }

    // User id
    const user_id = 'user_id'; // TODO: get from auth;

    // Event
    const browser = detectDevice.getBrowser();
    const trackingEvent: GlobalEbTrackingEventProps = {
      app_name: 'eb_portal',
      api_request_id: event_id, // remove in future when all clients report event_id
      brand: detectDevice.getDevice().vendor ?? 'Not Available',
      browser: browser?.name ?? '',
      browser_full_version: browser?.version ?? '',
      browser_major_version: browser?.major ?? '',
      country: 'US',
      env: config.env,
      event_id,
      http_referrer,
      model: detectDevice.getDevice().model ?? 'Not Available',
      os: detectDevice.getOSName(),
      os_version: detectDevice.getOsVersion(),
      page_size: {
        h: scrollHeight,
        w: scrollWidth
      },
      platform: detectDevice.getOSName(),
      source_href,
      tracking_version: '0.0.1',
      user_agent: detectDevice.getUserAgent(),
      user_id,
      viewport_size: {
        h: clientHeight,
        w: clientWidth
      },
      visitor_hash
    };

    return trackingEvent;
  };
}
