import { object, string } from 'yup';
import { validLastName, validName } from 'utils/schemaTest';

const schema = object({
  firstName: string()
    .min(2, 'First name must be at least ${min} characters')
    .max(40, 'Maximum ${max} characters')
    .required('First name is required')
    .test(validName),
  lastName: string()
    .min(2, 'Last name must be at least ${min} characters')
    .max(40)
    .required('Last name is required')
    .test(validLastName),
  email: string().email('Must be a valid email').required('Please enter email address'),
  role: string().required('Please select a role')
});

export { schema };
