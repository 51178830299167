import * as endpoints from 'endpoints';
import { ApiDomain } from 'services/api/types';
import { eb } from 'services/api/async';
import { EbThunk } from 'store/types';
import { PartnerExternalIdUpdateRequest } from './types';

doUpdatePartnerExternalId.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.PARTNER_ENROLLMENT_EXTERNAL_UPDATE,
  method: 'POST'
};

export function doUpdatePartnerExternalId(
  data: PartnerExternalIdUpdateRequest
): EbThunk<Promise<void>> {
  return async (dispatch) => {
    const {
      data: { endpoint, method }
    } = doUpdatePartnerExternalId;

    await dispatch(
      eb<any>({
        endpoint,
        method,
        data
      })
    );
  };
}
