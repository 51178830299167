// Globals
import './styles.scss';
import React from 'react';

// Components
import { Heading } from 'components/Heading';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';

// Props
interface ModalFileUploadSuccessProps {
  onBack: () => void;
  onClose: () => void;
}

// Component
const ModalFileUploadSuccess: React.FC<ModalFileUploadSuccessProps> = ({ onBack, onClose }) => {
  return (
    <div className="eb-modal_file_upload_success">
      <Icon className="eb-modal_file_upload_success-img" name="success" />

      <Heading className="eb-modal_file_upload_success-heading" size={22} type="h4">
        File was Successfully uploaded
      </Heading>

      <Button className="eb-modal_file_upload_success-button" onClick={onClose}>
        Done
      </Button>
      <Button className="eb-modal_file_upload_success-button" intent="text" onClick={onBack}>
        Upload more
      </Button>
    </div>
  );
};

export { ModalFileUploadSuccess };
