import './styles.scss';
import React from 'react';
import clsx from 'clsx';

interface Props {
  align?: 'center' | 'right';
  className?: string;
  children?: React.ReactNode;
  color?: 'white';
  size?: 64 | 58 | 48 | 40 | 32 | 26 | 22 | 18 | 16 | 14 | 12;
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  ellipsis?: boolean;
}

const Heading: React.FC<Props> = ({
  align,
  color,
  size = '40',
  type: Type,
  ellipsis = false,
  className: classNameFromProps,
  children,
  ...domProps
}) => {
  const className = clsx(
    'eb-heading',
    size && `eb-heading-${size}`,
    color && `eb-heading-${color}`,
    align && `eb-heading-${align}`,
    ellipsis && 'eb-heading-ellipsis',
    classNameFromProps
  );

  return (
    <Type {...domProps} className={className}>
      {children}
    </Type>
  );
};

export { Heading };
