// Global
import React from 'react';
import './styles.scss';

// Components
import { Heading } from 'components/Heading';

// Types
import { FileDetailsRecordErrorsProps } from './types';

const FileDetailsRecordErrors: React.FC<FileDetailsRecordErrorsProps> = ({ error }) =>
  error ? (
    <div className="eb-file_details_record_errors">
      <Heading className="eb-file_details_record_errors-heading" size={16} type="h6">
        Errors
      </Heading>
      <div className="eb-file_details_record_errors-item">{error}</div>
    </div>
  ) : null;

export { FileDetailsRecordErrors };
