import { ApiSpecLayout } from './Layout';

// Custom plugins for Swagger UI
export const ApiSpecLayoutPlugin = () => {
  return {
    components: {
      ApiSpecLayout
    }
  };
};

export const DisableTryItOutPlugin = function () {
  return {
    statePlugins: {
      spec: {
        wrapSelectors: {
          allowTryItOutFor: () => () => false
        }
      }
    }
  };
};
