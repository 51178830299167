// Globals
import React from 'react';

// Misc
import { InputTextProps } from './types';

// Component
const InputText: React.FC<InputTextProps> = (props) => {
  // Props
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    inputLabel, // Destructure from being passed to input element
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tracking,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    trackingEnabled,
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    thousandSeparator,
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onValueChange,
    ...restProps
  } = props;

  // Render
  return <input {...restProps} />;
};

export { InputText };
