import * as serviceReducers from 'reducers/index';
import {
  AnyAction,
  combineReducers,
  configureStore,
  ThunkDispatch,
  Middleware,
  Reducer,
  StoreEnhancer
} from '@reduxjs/toolkit';
import { AuthActionTypes } from 'services/auth/types';
import { syncLocalStorage } from './enhancers/localStorage';
import { sentryReduxEnhancer } from './enhancers/sentry';

export const enhancers: StoreEnhancer[] = [sentryReduxEnhancer];

if (typeof localStorage !== 'undefined') enhancers.push(syncLocalStorage);

const combinedReducers = combineReducers(serviceReducers);

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === AuthActionTypes.CLEAR_TOKENS) {
    return combinedReducers(undefined, action);
  }
  return combinedReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: [
          'meta.arg', // toolkit default
          'meta.baseQueryMeta', // toolkit default
          'eb.api' // api service error and params are unserialized values
        ]
      }
    }),
  enhancers
});

/**
 * @description Create a store dynamically, **FOR USE WITH TESTS ONLY**
 */
export function makeStore(
  reducer: typeof rootReducer | Partial<typeof serviceReducers> = rootReducer,
  preloadedState = {}
): EbStore {
  return configureStore({
    reducer: reducer as Reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false
      })
  });
}

export type EbState = ReturnType<typeof rootReducer>;
export type EbDispatch = typeof store.dispatch;
export type EbStore = typeof store;
export type EbMiddleware = Middleware<any, EbState, ThunkDispatch<EbState, any, any>>;

export default store;
