export interface ToastProps {
  timeout?: number;
}

export enum ToastTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning'
}

export interface LocationState {
  persistToast?: boolean;
}
