// Global
import React from 'react';
import './styles.scss';

// Components
import { Heading } from 'components/Heading';
import { LayoutAuth } from 'components/LayoutAuth';
import { MfaCode } from 'components/MfaCode';
import { MfaConfigure } from 'components/MfaConfigure';

// Misc
import { AuthChallenges } from 'hooks/useAuthManager/types';
import { useAuthManager } from 'hooks/useAuthManager';

const MfaSetup: React.FC = () => {
  // Hooks - custom
  const { currentChallenge, doConfigureMfa, doProvideMfaCode, pending, error } = useAuthManager();

  const renderChildren = () => {
    switch (currentChallenge) {
      case AuthChallenges.CONFIGURE_MFA:
        return <MfaConfigure configureMfa={doConfigureMfa} error={error} isPending={pending} />;
      case AuthChallenges.PROVIDE_MFA:
        return <MfaCode isPending={pending} sendMfaCode={doProvideMfaCode} />;
      default:
        // In case of reload or navigating to the page without trying to configure MFA it shouldn't allow sending user's phone, so we'll display a fallback message
        return (
          <Heading align="center" className="eb-mfa_setup-heading" size={26} type="h3">
            Sorry, something went wrong
          </Heading>
        );
    }
  };

  return <LayoutAuth className="eb-mfa_setup">{renderChildren()}</LayoutAuth>;
};

export { MfaSetup };
