import { useEffect, useRef } from 'react';

type Function = () => any;

// Hooks aren't that cool, so we have to mimic cDU while excluding cDM...
export function useEffectDidUpdate(fn: Function, deps: any[]) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return fn();
    }

    didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
