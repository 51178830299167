// Globals
import './styles.scss';
import React from 'react';

// Misc
import { EbTrackingEventType } from 'services/analytics/types';
import { useTracking } from 'hooks/useTracking';
import { removeProps } from 'utils/removeProps';
import { InputCheckboxProps } from './types';

const InputCheckbox: React.FC<InputCheckboxProps> = (props) => {
  // Hooks
  const trackEvent = useTracking();

  const {
    children,
    'data-value': dataValue = '',
    checked,
    defaultChecked,
    onChange,
    value,
    tracking,
    trackingEnabled = false,
    ...otherProps
  } = props;

  const trackOnChange = (event: React.ChangeEvent) => {
    onChange(event);
    if (trackingEnabled) {
      trackEvent({
        ...tracking,
        action: tracking?.action && `chb_${tracking?.action}`,
        notes: `Number((event.target as HTMLInputElement).checked)`,
        event: EbTrackingEventType.ui_click
      });
    }
  };

  /* 
    Pick which value to use for checked state, prioritizing to defaultChecked.
    Do not allow both at the same time if given as this is invalid
  */
  const commonInputProps = {
    type: 'checkbox',
    value: dataValue, // Allow for custom value,
    onChange: trackOnChange,
    ...otherProps
  };
  const defaultCheckInputProps = {
    ...commonInputProps,
    defaultChecked
  };
  const nonDefaultCheckInputProps = {
    ...commonInputProps,
    checked: checked ?? Boolean(value)
  };
  const inputProps = removeProps(
    defaultChecked ? defaultCheckInputProps : nonDefaultCheckInputProps,
    ['inputLabel', 'onInput', 'onValueChange', 'isDisabledForAgent', 'thousandSeparator']
  );

  return (
    <div className="eb-input_checkbox">
      <input {...inputProps} />
      <span className="eb-input_checkbox-container eb-input_checkbox-checkmark" />
      <span className="eb-input_checkbox-text">{children}</span>
    </div>
  );
};

export { InputCheckbox };
