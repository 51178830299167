import React from 'react';
import './styles.scss';

// Components
import { AriaButton } from 'components/AriaButton';
import { Button } from 'components/Button';
import { FileListItem } from 'components/FileListItem';
import { Heading } from 'components/Heading';
import { LayoutPage } from 'components/LayoutPage';
import { FileUploadType, NoFiles } from 'components/NoFiles';
import { ModalFileUploadCuid } from 'modals/ModalFileUploadCuid';
import { Paragraph } from 'components/Paragraph';
import { Search } from 'components/Search';
import { TableList } from 'components/Table/List';
import { Toast } from 'components/Toast';

// Services
import { doGetCompanyCuidFiles, doRequestCuidResponse } from 'services/cuid/async';
import {
  getCompanyCuidFiles,
  getCuidFilesParamsPage,
  getCuidFilesParamsPageSize,
  getCuidFilesParamsTotal,
  getCuidFilesParamsTotalPages,
  getCuidFilesParamsTotalWithoutFilters
} from 'services/cuid/selectors';
import { setPage, setPageSize, setSearch, resetFilters } from 'services/cuid/reducers';
import { setToastError, setToastSuccess } from 'services/toast/reducers';

// Misc
import { useDispatch, useSelector } from 'react-redux';
import { CuidFile } from 'services/cuid/types';
import format from 'date-fns/format';
import { EbDispatch } from 'store';
import { useSearchButton } from 'hooks/useSearchButton';

// Const
const DATE_FORMAT = 'MMM d, yyyy, hh:mm a';
const searchSuggestions = (
  <>
    <Paragraph>Suggestions:</Paragraph>
    <Paragraph>Make sure that file name is spelled correctly.</Paragraph>
    <Paragraph>Try different file name.</Paragraph>
  </>
);

const CuidManagement: React.FC = () => {
  // Hooks
  const dispatch: EbDispatch = useDispatch();

  // Hooks - selectors
  const cuidFiles = useSelector(getCompanyCuidFiles);
  const filesTotal = useSelector(getCuidFilesParamsTotal);
  const filesTotalWithoutFilter = useSelector(getCuidFilesParamsTotalWithoutFilters);

  // Hooks - state
  const { searchButton, isSearchInputActive } = useSearchButton();
  const [isUploadFileModalVisible, setIsUploadFileModalVisible] = React.useState(false);

  // Handlers
  const handleGetResponseFile = React.useCallback(
    async (id: string) => {
      try {
        await dispatch(doRequestCuidResponse(id));
        dispatch(
          setToastSuccess(
            'Response file was requested. We will email you the file when it is ready.'
          )
        );
      } catch (_) {
        dispatch(setToastError('An error occurred. Please try again.'));
      }
    },
    [dispatch]
  );

  const onSearchSubmit = (text: string) => {
    const search = text?.toLowerCase();
    void dispatch(setSearch(search));
    void dispatch(doGetCompanyCuidFiles());
  };

  const onSearchCancel = () => {
    void dispatch(resetFilters());
    void dispatch(doGetCompanyCuidFiles());
  };

  // Render
  const renderHeading = () => (
    <div className="eb-cuid_management-heading">
      <Heading size={32} type="h1">
        CUID Management
      </Heading>
      {filesTotalWithoutFilter > 0 && searchButton}
    </div>
  );

  const renderFile = (file: CuidFile) => {
    const uploadedDate = format(new Date(file.fileUploadedAt), DATE_FORMAT);
    const footer = (
      <div className="eb-cuid_management-file-info">
        {uploadedDate && <div>{uploadedDate}</div>}
        <AriaButton
          className="eb-cuid_management-file-info-cta"
          onClick={() => handleGetResponseFile(file.id)}
          type="primary"
        >
          Get response file
        </AriaButton>
      </div>
    );

    return (
      <FileListItem
        fileId={file.id}
        fileName={file.fileName}
        footer={footer}
        icon="file"
        key={file.id}
        status=""
        updated={uploadedDate}
      />
    );
  };

  return (
    <LayoutPage className="eb-cuid_management" heading={renderHeading()}>
      <Toast />

      {filesTotalWithoutFilter > 0 && (
        <>
          <Search
            isVisible={isSearchInputActive}
            onCancel={onSearchCancel}
            onSubmit={onSearchSubmit}
            placeHolder="Search by file name"
            request={doGetCompanyCuidFiles}
            suggestions={searchSuggestions}
            totalResultsFound={filesTotal}
          />
          <Button
            className="eb-cuid_management-upload-button"
            icon="document_add"
            intent="secondary"
            onClick={() => setIsUploadFileModalVisible(true)}
          >
            Upload CUID file
          </Button>
        </>
      )}

      <TableList
        empty={<NoFiles fileUploadType={FileUploadType.CUID} />}
        requests={[doGetCompanyCuidFiles]}
        selectorItemsTotal={getCuidFilesParamsTotal}
        selectorItemsTotalWithoutFilters={getCuidFilesParamsTotalWithoutFilters}
        selectorPage={getCuidFilesParamsPage}
        selectorPageSize={getCuidFilesParamsPageSize}
        selectorPagesTotal={getCuidFilesParamsTotalPages}
        setPage={(page) => dispatch(setPage(page))}
        setPageSize={(pageSize) => dispatch(setPageSize(pageSize))}
        title="File History"
      >
        {cuidFiles.map((file) => renderFile(file))}
      </TableList>

      <ModalFileUploadCuid
        hide={() => setIsUploadFileModalVisible(false)}
        isVisible={isUploadFileModalVisible}
      />
    </LayoutPage>
  );
};

export { CuidManagement };
