import { SafeOmit } from 'utils/typeUtils';

export interface EbTrackingSuperEvent {
  event: EbTrackingEventType;
  ts: number;
  payload: EbUITrackingEvent | EbAPTrackingEvent;
}

export interface GenericEbTrackingEvent {
  app_name: string;
  api_request_id: string;
  brand: string;
  browser: string;
  browser_full_version: string;
  browser_major_version: string;
  config_source: 'live' | 'cache';
  country: string;
  env: string;
  error: string;
  error_code: string | null;
  event_id: string;
  http_referrer: string;
  model: string;
  os: string;
  os_version: string;
  page_size: PageDimensions;
  platform: string;
  source_href: string;
  tracking_version: string;
  user_agent: string;
  user_id: string;
  viewport_size: PageDimensions;
  visitor_hash: string;
}

// Generic event minus the properties that are dependent on implementation
export type GlobalEbTrackingEventProps = SafeOmit<
  GenericEbTrackingEvent,
  'error' | 'error_code' | 'config_source'
>;

export interface EbAPTrackingEvent extends GenericEbTrackingEvent {
  api_request_id: string;
  duration: number;
  reason: string;
  source: 'EbPortal';
}

export interface EbUITrackingEvent extends GenericEbTrackingEvent {
  action: string;
  action_name: string;
  details: string;
  notes: string | Record<string, any>;
  placement: string;
}
interface PageDimensions {
  w: number;
  h: number;
}

// Tracking Events
export enum EbTrackingEventType {
  ui_view = 'ui_view',
  ui_click = 'ui_click',
  page_leave = 'page_leave',
  ui_form_error_validation = 'ui_form_error_validation',
  api_error_validation = 'api_error_validation',
  api_response = 'api_response'
}

// ---------- UI Tracking Specific ----------

// Params for components to send to useTracking
export interface EbTrackingEventParams {
  event: EbTrackingEventType;
  action?: string;
  action_name?: string;
  details?: string;
  error?: string;
  error_code?: string;
  notes?: string | Record<string, any>;
  placement?: string;
}

// Params that can be passed into the component to override behavior (omits)
export type EbTrackingEventOptionalParams = SafeOmit<EbTrackingEventParams, 'event'>;

export interface EbTrackingComponentProps {
  tracking?: EbTrackingEventOptionalParams | null;
  trackingEnabled?: boolean;
}
