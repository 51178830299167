export enum CuidFilesActionTypes {
  SET_FILES = 'eb.cuid.SET_FILES',
  SET_PAGE = 'eb.cuid.SET_PAGE',
  SET_PAGE_SIZE = 'eb.cuid.SET_PAGE_SIZE',
  SET_COUNT = 'eb.cuid.SET_COUNT',
  SET_SEARCH = 'eb.cuid.SET_SEARCH',
  RESET_FILTERS = 'eb.cuid.RESET_FILTERS'
}

// Generic Files Action
export interface CuidFilesAction<P> {
  type: CuidFilesActionTypes;
  data: P;
}

export interface CuidFile {
  id: string;
  fileName: string;
  fileUploadedAt: string;
  submittedBy?: string;
}

export interface GetCuidFilesResponse {
  pageSize?: number;
  page?: number;
  total?: number;
  totalPages?: number;
  files: CuidFile[];
}

export interface GetCuidPresignedUrlResponse {
  presignedUrl: string;
}

export interface CuidFilesSetAction extends CuidFilesAction<GetCuidFilesResponse> {
  type: CuidFilesActionTypes.SET_FILES;
}

export interface CuidFilesSetCount extends CuidFilesAction<number> {
  type: CuidFilesActionTypes.SET_COUNT;
}

export interface CuidFilesSetPageAction extends CuidFilesAction<number> {
  type: CuidFilesActionTypes.SET_PAGE;
}

export interface CuidFilesSetPageSizeAction extends CuidFilesAction<number> {
  type: CuidFilesActionTypes.SET_PAGE_SIZE;
}

export interface CuidFileSetSearchAction extends CuidFilesAction<string> {
  type: CuidFilesActionTypes.SET_SEARCH;
}

export interface CuidFilesResetFiltersAction extends CuidFilesAction<null> {
  type: CuidFilesActionTypes.RESET_FILTERS;
}

export type CuidFilesAllAction =
  | CuidFilesSetAction
  | CuidFilesSetPageAction
  | CuidFilesSetPageSizeAction
  | CuidFilesSetCount
  | CuidFileSetSearchAction
  | CuidFilesResetFiltersAction;

export interface CuidFilesParamState {
  page: number;
  pageSize: number;
  totalPages: number;
  total: number;
  totalWithoutFilters: number;
  search?: string;
}

export interface CuidFilesRequestParams {
  page: number;
  pageSize: number;
  search?: string;
}

export interface CuidFilesState {
  list: CuidFile[];
  params: CuidFilesParamState;
}

export interface UserData {
  userId: string;
  companyUserId: string;
}

export interface CuidUpdateRequest {
  userId: string;
  oldCompanyUserId: string;
  newCompanyUserId: string;
  companyCode: String;
}
