// Action types
export enum ToastActionTypes {
  SET_TOAST_ERROR = 'toast.SET_TOAST_ERROR',
  SET_TOAST_SUCCESS = 'toast.SET_TOAST_SUCCESS',
  SET_TOAST_VISIBLE = 'toast.SET_TOAST_VISIBLE',
  SET_TOAST_WARNING = `toast.SET_TOAST_WARNING`
}

export interface ToastState {
  message: string;
  isVisible: boolean;
  type: string;
}

// Generic toast action
export interface ToastAction<P> {
  type: ToastActionTypes;
  data: P;
}

// Toast actions
export interface ToastSetErrorToastsAction extends ToastAction<string> {
  type: ToastActionTypes.SET_TOAST_ERROR;
}

export interface ToastSetSuccessAction extends ToastAction<string> {
  type: ToastActionTypes.SET_TOAST_SUCCESS;
}

export interface ToastSetWarningAction extends ToastAction<string> {
  type: ToastActionTypes.SET_TOAST_WARNING;
}

export interface ToastSetVisibleAction extends ToastAction<boolean> {
  type: ToastActionTypes.SET_TOAST_VISIBLE;
}

export type ToastAllAction =
  | ToastSetErrorToastsAction
  | ToastSetSuccessAction
  | ToastSetWarningAction
  | ToastSetVisibleAction;
