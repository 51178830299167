import { combineReducers } from '@reduxjs/toolkit';
import {
  UserSettingsActionTypes,
  UserSettingsAllActions,
  UserSettingsGetResponse,
  UserSettingsNotificationsState,
  UserSettingsState
} from './types';
import { initialNotifications } from './initialState';

// Action Creators
export const setUserSettings = (data: UserSettingsGetResponse) => ({
  type: UserSettingsActionTypes.SET_USER_SETTINGS,
  data
});

export const addCompanyNotifications = (data: string) => ({
  type: UserSettingsActionTypes.ADD_COMPANY_NOTIFICATIONS,
  data
});

export const removeCompanyNotifications = (data: string) => ({
  type: UserSettingsActionTypes.REMOVE_COMPANY_NOTIFICATIONS,
  data
});

// Sub-reducers
function notifications(
  notificationsState: UserSettingsNotificationsState = initialNotifications,
  action: UserSettingsAllActions
) {
  switch (action.type) {
    case UserSettingsActionTypes.SET_USER_SETTINGS:
      return action.data?.notifications ?? notificationsState;
    case UserSettingsActionTypes.ADD_COMPANY_NOTIFICATIONS: {
      const companyCode = action.data;
      const fileFailedCompanyCodes = [...notificationsState.fileFailedCompanyCodes, companyCode];
      const fileSuccessCompanyCodes = [...notificationsState.fileSuccessCompanyCodes, companyCode];

      return { ...notificationsState, fileFailedCompanyCodes, fileSuccessCompanyCodes };
    }
    case UserSettingsActionTypes.REMOVE_COMPANY_NOTIFICATIONS: {
      const companyCode = action.data;
      const fileFailedCompanyCodes = notificationsState.fileFailedCompanyCodes.filter(
        (code) => code !== companyCode
      );
      const fileSuccessCompanyCodes = notificationsState.fileSuccessCompanyCodes.filter(
        (code) => code !== companyCode
      );
      return {
        ...notificationsState,
        fileFailedCompanyCodes,
        fileSuccessCompanyCodes
      };
    }
    default:
      return notificationsState;
  }
}

export const userSettings = combineReducers<UserSettingsState>({
  notifications
});
