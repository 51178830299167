import React from 'react';
import './styles.scss';
import clsx from 'clsx';

// Components
import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { Pagination } from 'components/Pagination';
import RcTable from 'rc-table';

// Types
import { RenderExpandIconProps, TableColumn, TableProps } from './types';
import { RowCheckbox } from './RowCheckbox';
import { AriaButton } from '../AriaButton';
import { Icon } from '../Icon';

function Table<DataType extends Object>({
  className,
  columns,
  data,
  expandable,
  handleSort,
  onRowClick,
  rowKey,
  title,
  selectable,
  sortKeys = [],
  activeSortKey,
  sortOrder,
  sortMapping,
  isLoading,
  ...paginationProps
}: TableProps<DataType>) {
  if (selectable && !rowKey) {
    throw Error('Table component requires `rowKey` property when `withRowSelection=true`');
  }

  // Props
  const tableRowKey = rowKey ?? ((_, index) => `row${index}`);
  const { onExpandClick } = expandable ?? {};

  // Hooks - state
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<string[]>(
    selectable?.selectedRowKeys ?? []
  );

  // Vars
  const defaultExpandIcon = ({
    expanded,
    expandable,
    onExpand,
    record
  }: RenderExpandIconProps<DataType>) =>
    expandable ? (
      <Button
        icon={expanded ? 'arrow_up' : 'arrow_down'}
        intent="text"
        onClick={(e) => {
          onExpand(record, e);
          if (onExpandClick) onExpandClick(expanded, e);
        }}
        type="icon"
      />
    ) : null;

  const DEFAULT_EXPAND_CONFIG = {
    expandIconColumnIndex: columns.length,
    expandIcon: defaultExpandIcon
  };

  const getRowKeyString = React.useCallback(
    (rowItem: DataType): keyof DataType => {
      return (typeof rowKey === 'function' ? rowKey(rowItem) : rowKey) as keyof DataType;
    },
    [rowKey]
  );

  const allSelectableRowKeys = React.useMemo(
    () =>
      data.reduce((keys: string[], item) => {
        const key = getRowKeyString(item);
        const isDisabled = selectable?.rowSelectDisabled
          ? selectable.rowSelectDisabled(item)
          : false;

        if (key && !isDisabled) {
          return [...keys, item[key] as string];
        }
        return keys;
      }, []),
    [data, getRowKeyString, selectable]
  );

  // Handlers
  const onRowProp = onRowClick
    ? {
        onRow: (record: DataType) => ({
          onClick: onRowClick.bind(null, record)
        })
      }
    : {};

  const toggleAllRowsSelection = React.useCallback(
    (checked: boolean) => {
      const newValue = checked ? allSelectableRowKeys : [];

      setSelectedRowKeys(newValue);
      selectable?.onRowSelect(newValue);
    },
    [selectable, allSelectableRowKeys]
  );

  const toggleRowSelection = React.useCallback(
    (checked: boolean, key: string) => {
      const newValue = checked
        ? [...selectedRowKeys, key]
        : selectedRowKeys.filter((selectedKey) => selectedKey !== key);

      setSelectedRowKeys(newValue);
      selectable?.onRowSelect(newValue);
    },
    [selectable, selectedRowKeys]
  );

  // Hooks - effects
  React.useEffect(() => {
    if (selectable?.selectedRowKeys) setSelectedRowKeys(selectable.selectedRowKeys);
  }, [selectable?.selectedRowKeys]);

  // Render
  const renderHeaderCheckBox = React.useCallback(() => {
    const isAllSelected = allSelectableRowKeys.length === selectedRowKeys.length;

    return <RowCheckbox checked={isAllSelected} name="all" onChange={toggleAllRowsSelection} />;
  }, [allSelectableRowKeys.length, selectedRowKeys.length, toggleAllRowsSelection]);

  const renderRowCheckBox = React.useCallback(
    (row: DataType) => {
      const rowKey = getRowKeyString(row);
      const keyValue = row[rowKey] as string;
      const isRowSelected = selectedRowKeys.includes(keyValue);

      return (
        <RowCheckbox
          checked={isRowSelected}
          disabled={selectable?.rowSelectDisabled ? selectable.rowSelectDisabled(row) : false}
          name={keyValue}
          onChange={(checked) => toggleRowSelection(checked, keyValue)}
        />
      );
    },
    [getRowKeyString, selectable, selectedRowKeys, toggleRowSelection]
  );

  const displayColumns = React.useMemo(() => {
    let result = [...columns];

    if (sortKeys?.length > 0) {
      result = result.map((item) => {
        const key = item.dataIndex ?? '';
        const sortKey = sortMapping?.[key] ?? key;

        if (key && handleSort && sortKeys.includes(sortKey)) {
          const isActiveSort = activeSortKey === sortKey;
          const sortIcon = isActiveSort ? 'arrow_up_default' : 'arrow_swap_diagonal';
          return {
            ...item,
            title: (
              <AriaButton
                className={clsx('eb-table-heading-button', {
                  ['eb-table-heading-button-active']: isActiveSort
                })}
                onClick={() => handleSort(sortKey)}
              >
                <Icon
                  className={clsx('eb-table-heading-button-sort-icon', {
                    ['eb-table-heading-button-sort-icon-desc']: sortOrder === 1
                  })}
                  name={sortIcon}
                />
                <span>{item.title}</span>
              </AriaButton>
            )
          };
        }
        return item;
      });
    }
    if (selectable) {
      const checkBoxColumn: TableColumn<DataType> = {
        title: renderHeaderCheckBox(),
        width: 20,
        render: (_, row) => renderRowCheckBox(row)
      };
      return [checkBoxColumn, ...result];
    }

    return result;
  }, [
    selectable,
    columns,
    renderHeaderCheckBox,
    renderRowCheckBox,
    sortOrder,
    activeSortKey,
    handleSort,
    sortKeys,
    sortMapping
  ]);

  const renderHeading = () => {
    if (!title) return null;

    return typeof title === 'string' ? (
      <Heading size={22} type="h2">
        {title}
      </Heading>
    ) : (
      <>{title}</>
    );
  };

  return (
    <>
      {renderHeading()}
      {isLoading && (
        <div className="eb-table-loading">
          {Array(5)
            .fill('')
            .map((_, index) => (
              <div className="eb-table-loading-row" key={index} />
            ))}
        </div>
      )}
      {!isLoading && (
        <RcTable
          className={className}
          columns={displayColumns}
          data={data}
          emptyText="No records found"
          expandable={expandable ? { ...DEFAULT_EXPAND_CONFIG, ...expandable } : expandable}
          prefixCls="eb-table"
          rowKey={tableRowKey}
          {...onRowProp}
        />
      )}
      {paginationProps.itemsTotal > 0 && <Pagination {...paginationProps} />}
    </>
  );
}

export { Table };
