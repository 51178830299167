// Globals
import './styles.scss';
import React from 'react';

// Components
import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { Modal } from 'components/Modal';
import { ModalBodyText } from 'components/Modal/BodyText';
import { Paragraph } from 'components/Paragraph';

// Services
import { doLogout, doRefreshTokens } from 'services/auth/async';
import { getEndpointPending } from 'services/api/selectors';
import { getRefreshTokenExpires } from 'services/auth/selectors';

// Misc
import clsx from 'clsx';
import { EbDispatch, EbState } from 'store';
import { useDispatch, useSelector } from 'react-redux';

// Types
import { TEST_IDS, ModalSessionExpirationProps } from './types';

// Components
const ModalSessionExpiration: React.FC<ModalSessionExpirationProps> = ({ isVisible, hide }) => {
  // Hooks
  const dispatch: EbDispatch = useDispatch();

  // Hooks - state
  const [expirationTime, setExpirationTime] = React.useState<number | null>(null);
  const [timeToLive, setTimeToLive] = React.useState<number | null>(null);

  // Hooks - selectors
  const isPending = useSelector((state: EbState) => getEndpointPending(state, doRefreshTokens));
  const refreshTokenExpires = useSelector(getRefreshTokenExpires);

  // Handlers
  const onExtendSession = async () => {
    await dispatch(doRefreshTokens());
  };

  const onSignOut = React.useCallback(() => {
    hide();
    void dispatch(doLogout());
  }, [dispatch, hide]);

  React.useEffect(() => {
    setExpirationTime(refreshTokenExpires.getTime());
  }, [refreshTokenExpires]);

  React.useEffect(() => {
    if (expirationTime !== null) {
      const intervalId: EbTimeout = setInterval(() => {
        const currentTime = Math.floor(Date.now() / 1000);
        const expiration = Math.floor(expirationTime / 1000);
        const remaining = expiration - currentTime;

        if (remaining > 0) {
          setTimeToLive(remaining);
        } else {
          setTimeToLive(0);
          onSignOut();
          clearInterval(intervalId);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }

    return;
  }, [expirationTime, onSignOut]);

  const formatTime = (seconds: number | null) => {
    if (seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      const displayMinutes = minutes > 0 ? `${minutes} minutes ` : '';
      const classes = clsx({
        'eb-modal_session_expiration-header-critical': minutes < 1
      });
      const labelSeconds = remainingSeconds > 1 ? 'seconds' : 'second';

      return (
        <span>
          {displayMinutes}
          <span className={classes}>
            {remainingSeconds} {labelSeconds}
          </span>
        </span>
      );
    }

    return null;
  };

  // Render
  return (
    <Modal
      className="eb-modal_session_expiration"
      hide={hide}
      isClosable={false}
      isVisible={isVisible}
    >
      <ModalBodyText aria-label="Your session is about to expire">
        <div className="eb-modal_session_expiration-content-wrapper">
          <div className="eb-modal_session_expiration-icon-wrapper">
            <Icon aria-hidden name="timer" />
          </div>
          <Heading
            align="center"
            className="eb-modal_session_expiration-header"
            size={26}
            type="h1"
          >
            Your session expires in <br className="eb-modal_session_expiration-break" />
            {formatTime(timeToLive)}
          </Heading>
          <Paragraph align="center" className="eb-modal_session_expiration-description">
            You&apos;re about to be signed out for security. Tap to extend your session.
          </Paragraph>

          <div className="eb-modal_session_expiration-action-buttons">
            <Button
              data-test={TEST_IDS.SIGN_OUT}
              intent="secondary"
              isDisabled={isPending}
              onClick={onSignOut}
            >
              Sign out
            </Button>
            <Button
              data-test={TEST_IDS.EXTEND_SESSION}
              isDisabled={isPending}
              onClick={onExtendSession}
            >
              Extend session
            </Button>
          </div>
        </div>
      </ModalBodyText>
    </Modal>
  );
};

export { ModalSessionExpiration };
