// Global
import './styles.scss';
import React from 'react';

// Misc
import clsx from 'clsx';
import onKeyDown from '@isubscribed/fat-tony/onKeyDown';

interface ButtonToggleProps {
  active: string;
  disabled: boolean;
  onClick: (value: string) => void;
  options: Array<{
    label: string | React.ReactNode;
    value: string;
  }>;
}

const ButtonToggle: React.FunctionComponent<ButtonToggleProps> = ({
  active,
  disabled,
  options,
  onClick
}) => {
  // Vars
  const classes = (value: string) =>
    clsx({
      'eb-button_toggle-button': true,
      'eb-button_toggle-button-selected': value === active,
      'eb-button_toggle-button-disabled': disabled
    });
  return (
    <div className="eb-button_toggle">
      {options.map(({ label, value }) => {
        return (
          <button
            className={classes(value)}
            disabled={disabled}
            key={value}
            onClick={() => onClick(value)}
            onKeyDown={(event) =>
              onKeyDown([{ event, key: 'Enter', handler: () => onClick(value) }])
            }
            type="button"
          >
            {label}
          </button>
        );
      })}
    </div>
  );
};

export { ButtonToggle };
