// Globals
import React from 'react';
import './styles.scss';

interface ApiSpecLayoutProps {
  getComponent(name: string, isContainer?: boolean): React.FC<any>;
}

export const ApiSpecLayout: React.FC<ApiSpecLayoutProps> = ({ getComponent, ...props }) => {
  const Operations = getComponent('operations', true);

  return (
    <div className="swagger-ui eb-api_spec_layout">
      <Operations {...props} />
    </div>
  );
};
