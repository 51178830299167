export enum TEST_IDS {
  SIGN_OUT = 'SIGN_OUT',
  EXTEND_SESSION = 'EXTEND_SESSION'
}

export interface ModalSessionExpirationProps {
  isVisible: boolean;

  hide(): void;
}
