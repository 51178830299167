import { createSelector } from 'reselect';
import { EbState } from 'store';
import { CuidFile, CuidFilesParamState, CuidFilesState } from './types';

export const initialParams: CuidFilesParamState = {
  pageSize: 25,
  page: 0,
  totalPages: 0,
  total: 0,
  totalWithoutFilters: 0
};

export const initialState: CuidFilesState = {
  params: initialParams,
  list: []
};

export const getCuidFiles = createSelector(
  (state: EbState) => state?.cuidFiles,
  (filesState): CuidFilesState => filesState
);

export const getCompanyCuidFiles = createSelector(
  getCuidFiles,
  (filesState): CuidFile[] => filesState?.list || []
);

export const getCuidFilesParams = createSelector(
  getCuidFiles,
  (filesState): CuidFilesParamState => filesState?.params ?? initialParams
);

export const getCuidFilesParamsPageSize = createSelector(
  getCuidFilesParams,
  (params): number => params.pageSize
);

export const getCuidFilesParamsPage = createSelector(
  getCuidFilesParams,
  (params): number => params.page
);

export const getCuidFilesParamsTotal = createSelector(
  getCuidFilesParams,
  (params): number => params.total || 0
);

export const getCuidFilesParamsTotalWithoutFilters = createSelector(
  getCuidFilesParams,
  (params): number => params.totalWithoutFilters || 0
);

export const getCuidFilesParamsTotalPages = createSelector(
  getCuidFilesParams,
  (params): number => params.totalPages
);
