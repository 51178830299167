import './styles.scss';
import React from 'react';

// Components
import { LayoutPage } from 'components/LayoutPage';
import { OrganizationList } from 'components/OrganizationList';
import { OrganizationsHeader } from './Header';
import { TableList } from 'components/Table/List';
import { Toast } from 'components/Toast';

// Services
import { doGetOrganizations } from 'services/organizations/async';
import { doGetUserSettings } from 'services/userSettings/async';
import {
  getOrganizationsParamsOrgTotal,
  getOrganizationsParamsPage,
  getOrganizationsParamsPageSize,
  getOrganizationsParamsPageTotal
} from 'services/organizations/selectors';
import { resetParams, setPage, setPageSize } from 'services/organizations/reducers';

// Misc
import { EbDispatch } from 'store';
import { useDispatch } from 'react-redux';
import { useSearchButton } from 'hooks/useSearchButton';

const requests = [doGetOrganizations];

function Organizations() {
  // Hooks
  const dispatch: EbDispatch = useDispatch();
  const { isSearchInputActive } = useSearchButton();

  // Hooks - effects
  React.useEffect(() => {
    void dispatch(doGetUserSettings());
  });

  React.useEffect(() => {
    // Reset params on page unmount
    return () => {
      dispatch(resetParams());
    };
  });

  // Handlers
  const handleSetPage = (page: number) => dispatch(setPage(page));
  const handleSetPageSize = (pageSize: number) => dispatch(setPageSize(pageSize));

  const loadingText = isSearchInputActive ? 'Searching...' : 'Loading...';

  return (
    <LayoutPage className="eb-organizations" heading={<OrganizationsHeader />}>
      <Toast />
      <TableList
        heading="Organization file transfer update"
        loadingText={loadingText}
        requests={requests}
        selectorItemsTotal={getOrganizationsParamsOrgTotal}
        selectorPage={getOrganizationsParamsPage}
        selectorPageSize={getOrganizationsParamsPageSize}
        selectorPagesTotal={getOrganizationsParamsPageTotal}
        setPage={handleSetPage}
        setPageSize={handleSetPageSize}
      >
        <OrganizationList />
      </TableList>
    </LayoutPage>
  );
}

export { Organizations };
