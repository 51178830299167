// Globals
import React from 'react';
import './styles.scss';

// Components
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';

// Types
import { ButtonType } from 'components/Button/types';
import { CalloutProps } from './types';

// Misc
import clsx from 'clsx';

const Callout: React.FC<CalloutProps> = ({
  children,
  className = '',
  icon = '',
  status = 'info',
  onDismiss
}) => {
  const classes = clsx(className, `eb-callout eb-callout-${status}`, {
    'eb-callout-with-dismiss': Boolean(onDismiss),
    'eb-callout-with-icon': icon
  });

  return (
    <div className={classes}>
      {icon && <Icon className="eb-callout-icon" name={icon} />}
      <div className="eb-callout-content">{children}</div>
      {onDismiss && (
        <div className="eb-callout-button">
          <Button icon="close" intent="text" onClick={onDismiss} type={ButtonType.ICON} />
        </div>
      )}
    </div>
  );
};

export { Callout };
