// Globals
import './styles.scss';
import React from 'react';

// Components
import { AriaButton } from 'components/AriaButton';

// Misc
import AccordionContext from './context';
import clsx from 'clsx';

// Types
import { AccordionProps, TEST_IDS } from './types';

// Component
const Accordion: React.FunctionComponent<AccordionProps> = ({
  children,
  className,
  isDisabled = false,
  isOpen = false,
  onClick = () => null,
  topBar = true
}) => {
  // Hooks
  const [isToggleOpen, setIsToggleOpen] = React.useState(isOpen);

  // Hooks - effects
  React.useEffect(() => {
    setIsToggleOpen(isOpen);
  }, [isOpen]);

  // Handlers
  const toggleIsOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (isDisabled) {
      return;
    }

    setIsToggleOpen((prevIsOpen) => !prevIsOpen);

    if (onClick) {
      onClick(event);
    }
  };

  // Vars
  const classes = clsx('eb-accordion', className, {
    'eb-accordion-disabled': isDisabled,
    'eb-accordion-active': isToggleOpen
  });

  // Render
  return (
    <AccordionContext.Provider value={{ isToggleOpen }}>
      <div className={classes}>
        <AriaButton
          aria-expanded={isToggleOpen}
          aria-label={isToggleOpen ? 'Collapse item' : 'Expand item'}
          className="eb-accordion-heading-container"
          data-element-type="link-text"
          data-test={TEST_IDS.LIST_ITEM}
          isDisabled={isDisabled}
          onClick={toggleIsOpen}
        >
          <div className="eb-accordion-top-bar">{topBar}</div>
          <div className="eb-accordion-icon" data-test={TEST_IDS.LIST_ICON}>
            {isToggleOpen ? '-' : '+'}
          </div>
        </AriaButton>
        {isToggleOpen && <div aria-hidden={!isToggleOpen}>{children}</div>}
      </div>
    </AccordionContext.Provider>
  );
};

export { Accordion };
