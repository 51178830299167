import { combineReducers } from '@reduxjs/toolkit';
import { ToastAllAction, ToastActionTypes, ToastState } from './types';

// Action Creators
export const setToastError = (data: string) => ({ type: ToastActionTypes.SET_TOAST_ERROR, data });
export const setToastSuccess = (data: string) => ({
  type: ToastActionTypes.SET_TOAST_SUCCESS,
  data
});
export const setToastWarning = (data: string) => ({
  type: ToastActionTypes.SET_TOAST_WARNING,
  data
});
export const setToastIsVisible = (data: boolean) => ({
  type: ToastActionTypes.SET_TOAST_VISIBLE,
  data
});

// Sub-reducers
function message(message = '', action: ToastAllAction) {
  switch (action.type) {
    case ToastActionTypes.SET_TOAST_ERROR:
    case ToastActionTypes.SET_TOAST_SUCCESS:
    case ToastActionTypes.SET_TOAST_WARNING:
      return action.data;
    default:
      return message;
  }
}

function isVisible(isVisible = false, action: ToastAllAction) {
  switch (action.type) {
    case ToastActionTypes.SET_TOAST_ERROR:
    case ToastActionTypes.SET_TOAST_SUCCESS:
    case ToastActionTypes.SET_TOAST_WARNING:
      return true;
    case ToastActionTypes.SET_TOAST_VISIBLE:
      return action.data;
    default:
      return isVisible;
  }
}

function type(type = '', action: ToastAllAction) {
  switch (action.type) {
    case ToastActionTypes.SET_TOAST_ERROR:
      return 'error';
    case ToastActionTypes.SET_TOAST_SUCCESS:
      return 'success';
    case ToastActionTypes.SET_TOAST_WARNING:
      return 'warning';
    default:
      return type;
  }
}

// Root Reducer
export const toast = combineReducers<ToastState>({
  message,
  isVisible,
  type
});
