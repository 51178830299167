// Global
import './styles.scss';
import React from 'react';

// Components
import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { Modal } from 'components/Modal';
import { ModalBodyText } from 'components/Modal/BodyText';
import { Paragraph } from 'components/Paragraph';

// Types
import { ModalConfirmationProps } from './types';

// Assets
import alert from 'assets/media/alert_outline.svg';

const ModalConfirmation: React.FC<ModalConfirmationProps> = ({
  isPending,
  secondaryButtonText = 'Leave',
  primaryButtonText = 'Stay',
  text = "You will lose your progress. This can't be undone.",
  isVisible,
  onPrimaryButtonClick,
  onSecondaryButtonClick
}) => {
  return (
    <Modal
      className="eb-modal_confirmation"
      hide={onPrimaryButtonClick}
      isClosable={false}
      isVisible={isVisible}
    >
      <ModalBodyText>
        <img alt="Alert image" className="eb-modal_confirmation-image" src={alert} />
        <Heading align="center" className="eb-modal_confirmation-heading" size={26} type="h1">
          Are you sure?
        </Heading>
        <Paragraph align="center" className="eb-modal_confirmation-text">
          {text}
        </Paragraph>
        <div className="eb-modal_confirmation-buttons">
          <Button intent="secondary" isDisabled={isPending} onClick={onSecondaryButtonClick}>
            {secondaryButtonText}
          </Button>
          <Button isLoading={isPending} onClick={onPrimaryButtonClick}>
            {primaryButtonText}
          </Button>
        </div>
      </ModalBodyText>
    </Modal>
  );
};

export { ModalConfirmation };
