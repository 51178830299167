// Globals
import './styles.scss';
import React from 'react';

// Components
import { Anchor } from 'components/Anchor';
import { Dot } from 'components/Dot';
import { Paragraph } from 'components/Paragraph';
import { Heading } from 'components/Heading';

// Service
import { doEndOrganizationOnboarding } from 'services/organizations/async';
import { getEndpointPending } from 'services/api/selectors';
import { setToastError, setToastSuccess } from 'services/toast/reducers';

// Misc
import { EbDispatch, EbState } from 'store';
import { isValid, format } from 'date-fns';
import { OrganizationExpandedDataProps } from '../types';
import { useDispatch, useSelector } from 'react-redux';

const DATE_FORMAT = 'MMM d, yyyy, hh:mm a';
const validationField: Record<string, string> = {
  validation_code: 'Validation Code',
  birth_date: 'Birth Date'
};

const OrganizationExpandedData: React.FC<OrganizationExpandedDataProps> = ({
  org,
  programs,
  uploadedAt
}) => {
  // Hooks
  const dispatch: EbDispatch = useDispatch();

  // Hooks - selectors
  const isEndOnboardingPending = useSelector((state: EbState) =>
    getEndpointPending(state, doEndOrganizationOnboarding)
  );

  // Vars
  const uploadedDate = isValid(new Date(uploadedAt)) && format(new Date(uploadedAt), DATE_FORMAT);

  // Handler
  const handleOnboarding = async () => {
    try {
      await dispatch(doEndOrganizationOnboarding(org?.code));
      dispatch(
        setToastSuccess(
          `Successfully ended onboarding for ${org.name ?? org.alternateName ?? org.code}`
        )
      );
    } catch (error) {
      dispatch(setToastError(`An error occurred. Please try again.`));
    }
  };

  // Render
  const renderEnrollmentBillingList = () => {
    if (!programs || programs.length === 0) {
      return null;
    }

    return (
      <ul className="eb-organiztion-details-list">
        {programs.map((program, index) => {
          return (
            <div key={index}>
              <li>{program.enrollmentType?.toLowerCase()}</li>
              <Dot />
              <li>{program.billingSource?.toLowerCase()}</li>
              <br />
            </div>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="eb-organization_expanded_data">
      {org?.alternateName && (
        <div className="eb-organization_expanded_data-values">
          <Paragraph>Alternate Name</Paragraph>
          <Heading className="eb-organization-date" size={16} type="h6">
            {org?.alternateName}
          </Heading>
        </div>
      )}
      {uploadedDate && (
        <div className="eb-organization_expanded_data-values">
          <Paragraph size={16}>Last updated</Paragraph>
          <Heading className="eb-organization-date" size={16} type="h6">
            {uploadedDate}
          </Heading>
        </div>
      )}
      {programs?.length > 0 ? (
        <div className="eb-organization_expanded_data-values">
          <Paragraph>Programs</Paragraph>
          {renderEnrollmentBillingList()}
        </div>
      ) : null}
      {org?.validationField && (
        <div className="eb-organization_expanded_data-values">
          <Paragraph>Validation Field</Paragraph>
          <Heading className="eb-organization-date" size={16} type="h6">
            {validationField[org?.validationField]}
          </Heading>
        </div>
      )}
      {!org?.isProd && (
        <div className="eb-organization_expanded_data-values">
          <Paragraph>Onboarding</Paragraph>
          <Anchor
            className="eb-organization_expanded_onboarding-link"
            isDisabled={isEndOnboardingPending}
            onClick={handleOnboarding}
          >
            Start Enrollment
          </Anchor>
        </div>
      )}
    </div>
  );
};

export { OrganizationExpandedData };
