import './styles.scss';
import React from 'react';

// Components
import { Input } from 'components/Input';

// Misc
import clsx from 'clsx';
import { InputCodeProps } from './types';
import { NumberFormatValues } from 'react-number-format';

const InputCode: React.FunctionComponent<InputCodeProps> = ({
  className,
  isDisabled,
  error,
  numberOfInputs = 6,
  tracking,
  trackingEnabled,
  isControlled,
  value,
  onChange
}) => {
  const [valuesArray, setValuesArray] = React.useState<string[]>(
    value ? value.split('') : new Array(numberOfInputs).fill('')
  );
  const codeInputArray = React.useRef<NodeListOf<HTMLDivElement>>();
  const inputsContainerRef = React.useRef<HTMLDivElement>(null);

  // Hooks - effects
  React.useEffect(() => {
    codeInputArray.current = document.querySelectorAll('.eb-input_code-input');
  }, []);

  React.useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      const activeInput: Element | null = document.activeElement;

      if (activeInput?.tagName === 'INPUT' && event.clipboardData) {
        const text = event.clipboardData.getData('text');
        const numericText = text.replace(/\D/g, '');

        if (numericText) {
          event.preventDefault();
          event.stopPropagation();

          const activeInputIndex = Number((activeInput as HTMLInputElement).name);
          const pastedValues = numericText.slice(0, numberOfInputs - activeInputIndex).split('');
          const inputValues = [...valuesArray.slice(0, activeInputIndex), ...pastedValues];

          setValuesArray(inputValues);
          if (onChange) onChange(inputValues);

          const lastPasteIndex = activeInputIndex + pastedValues.length - 1;
          const lastPasteInput = codeInputArray.current?.[lastPasteIndex];
          if (lastPasteInput) focusFieldInput(lastPasteInput);
        }
      }
    };

    // handlePaste is only for controlled input
    if (isControlled && inputsContainerRef.current) {
      inputsContainerRef.current.addEventListener('paste', handlePaste);
    }
    return () => {
      if (inputsContainerRef.current)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        inputsContainerRef.current.removeEventListener('paste', handlePaste);
    };
  }, [isControlled, valuesArray, numberOfInputs, onChange]);

  React.useEffect(() => {
    const inputArray = codeInputArray.current ?? [];
    if (!isDisabled && inputArray.length > 0) {
      focusFieldInput(inputArray[0]);
    }
  }, [isDisabled]);

  // Handlers
  const focusFieldInput = (field: HTMLDivElement) => {
    if (!field) return;
    field.querySelector<HTMLInputElement>('.eb-input-control')?.focus();
  };

  const focusNext = (event: React.KeyboardEvent, increment = 1) => {
    const {
      shiftKey,
      key,
      target: { name, value }
    } = event as React.KeyboardEvent & { target: HTMLInputElement };

    // Return if "Shift" for edge case in which user is slow to type shift + tab
    if ((shiftKey && key === 'Tab') || key === 'Tab' || key === 'Shift') {
      return;
    }

    // Once value is filled, find next element
    if (value.length > 0 || increment < 0) {
      const inputArray = codeInputArray.current ?? [];
      const inputIndex = Number(name);
      const nextInput = inputIndex + increment;
      const nextField = inputArray[nextInput];

      focusFieldInput(nextField);
    }
  };

  const handleChange = (event: NumberFormatValues, inputIndex: number) => {
    const inputValue = event.value;
    const newValue = [...valuesArray];

    newValue[inputIndex] = inputValue;
    setValuesArray(newValue);
    if (onChange) onChange(newValue);
  };

  const handleKeyUp = (event: React.KeyboardEvent, inputIndex: number) => {
    if (event.code === 'Backspace') {
      focusNext(event, -1);
    } else if (inputIndex < numberOfInputs) {
      focusNext(event);
    }
  };

  const inputs: JSX.Element[] = new Array(numberOfInputs).fill('').map((_, index) => {
    const classes: string = clsx('eb-input_code-input', {
      'eb-input_code-input-error': Boolean(error),
      'eb-input_code-input-final': index + 1 === numberOfInputs
    });
    const controlledInputProps = isControlled
      ? {
          onValueChange: (e: NumberFormatValues) => handleChange(e, index),
          value: valuesArray[index]
        }
      : null;
    return (
      <Input
        autoComplete="off"
        className={classes}
        disabled={isDisabled}
        key={index}
        maxLength="1"
        name={String(index)}
        onKeyUp={(e) => handleKeyUp(e, index + 1)}
        tracking={tracking}
        trackingEnabled={trackingEnabled}
        type="number"
        {...controlledInputProps}
      />
    );
  });

  const classes = clsx(['eb-input_code', className]);

  return (
    <div className={classes} ref={inputsContainerRef}>
      {inputs}
    </div>
  );
};

export { InputCode };
