import { Paragraph } from 'components/Paragraph';

interface FaqDataProps {
  servers?: Array<{ description: string; url: string }>;
}

export const faqs = [
  {
    question: 'Are there different environments for testing and production?',
    answer: ({ servers }: FaqDataProps) => (
      <ul>
        {servers?.map((server) => (
          <li key={server.url}>
            {server.description}: {server.url}
          </li>
        ))}
      </ul>
    )
  },
  {
    question: 'What security measures are in place for the API?',
    answer: (
      <Paragraph>
        The API prioritizes security by using TLS for HTTP requests and relying on a secure cloud
        infrastructure. User data is fully encrypted at rest, and rigorous penetration testing
        ensures the overall security of the API.
      </Paragraph>
    )
  },
  {
    question: 'What are the guidelines for error handling in a sequence of API calls?',
    answer: (
      <>
        <Paragraph>We can divide the error messages into two different groups:</Paragraph>
        <ul>
          <li>
            Synchronous errors: Errors that will be immediately returned in the HTTP response.
          </li>
          <li>
            Asynchronous errors: Errors that happen downstream are communicated back to you via
            webhook enrollment status updates.
          </li>
        </ul>
      </>
    )
  },
  {
    question: 'What are the data validation rules and formatting requirements for API requests?',
    answer: (
      <Paragraph>
        The open api spec details the required fields and data validation rules.
      </Paragraph>
    )
  },
  {
    question: 'Can you provide workflow examples for using the Aura Partner Enrollment API?',
    answer: (
      <ol>
        <li>
          Create a webhook
          <ul>
            <li>
              At any point in time you can list your webhooks to review them. If you need to adjust
              them you’ll need to remove the webhook and add a new one.
            </li>
          </ul>
        </li>
        <li>
          Create an enrollment.
          <ul>
            <li>
              If you need to change the enrollment, you would use the change endpoint. (only
              changing plan, program, or effective date)
            </li>
            <li>
              All enrollment calls are requests, not guarantees (aka it’s asynchronous). You will
              get a notification via webhook on the status of your request.
            </li>
          </ul>
        </li>
      </ol>
    )
  },
  {
    question: 'What are the best practices for using Partner Enrollment APIs?',
    answer: (
      <>
        <Paragraph>
          Most of the partner APIs are async, meaning our servers receive a request, validate it and
          return back the client without necessarily completing the request. The request will be
          passed along to be processed.
        </Paragraph>
        <br />
        <Paragraph>
          You should create a webhook to be notified every time the enrollment state changes. For
          example, if you create a webhook, you would be notified every time an enrollment fails,
          with specific reasons. You’ll also receive notifications when enrollments are successfully
          processed.
        </Paragraph>
        <br />
        <Paragraph>
          If you would like to know about the status of an enrollment without using a webhook, you
          need to keep track of the external id and use the /partner/enrollment/status API.
        </Paragraph>
      </>
    )
  },
  {
    question: 'How can I manage pagination and data effectively?',
    answer: (
      <>
        <Paragraph>We do have pagination for Listing User Enrollments</Paragraph>
        <ul>
          <li>
            The API provides a set of records and a start key for the next page of results should
            more pages exist.
          </li>
        </ul>
      </>
    )
  }
];
