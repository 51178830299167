// Action types
export enum AuthActionTypes {
  CLEAR_TOKENS = 'eb.auth.CLEAR_TOKENS',
  SET_TOKENS = 'eb.auth.SET_TOKENS'
}

// Generic Auth actions
export interface AuthAction<P> {
  type: AuthActionTypes;
  data: P;
}

export interface AuthClearTokenAction extends AuthAction<null> {
  type: AuthActionTypes.CLEAR_TOKENS;
}

export interface AuthSetTokensAction extends AuthAction<AuthTokens> {
  type: AuthActionTypes.SET_TOKENS;
}

export type AllAuthActions = AuthClearTokenAction | AuthSetTokensAction;

export interface LoginAuthParams {
  alias: string;
  password: string;
}

export interface ResetPasswordParams {
  code: string;
  password: string;
}

export interface SetMasqueradeTokensParams {
  user_id: string;
  access_token: string;
  refresh_token: string;
}

export interface AuthTokens {
  access_token: string;
  refresh_token: string;
}

export interface AuthState {
  accessToken: string;
  entitlements: Record<string, any>;
  refreshToken: string;
}

export interface JWTToken {
  alias?: string;
  'aurasvc:directory_key'?: string;
  'aurasvc:entitlements'?: Object;
  'aurasvc:session_id'?: string;
  exp: number;
  iat: number;
  jti: string;
  nbf?: number;
  sub?: string;
}

export enum AccessLevel {
  FULL = 'FULL',
  ORG = 'ORG',
  HR = 'HR',
  HR_SUPER = 'HR_SUPER',
  DEV = 'DEV'
}
