// Global
import './styles.scss';
import React from 'react';

// Components
import { Button } from 'components/Button';
import { IconBadgeCount } from 'components/Icon/BadgeCount';

// Misc
import clsx from 'clsx';

export const useFilter = (filtersAppliedCount: number) => {
  // Hooks - state
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);

  // Handlers
  const toggleFilter = React.useCallback(() => {
    setIsFilterOpen(!isFilterOpen);
  }, [isFilterOpen]);

  // Vars
  const classNames = clsx('eb-filter_toggle', { 'eb-filter_toggle-open': isFilterOpen });

  // Render
  const filterButton = (
    <div>
      <Button
        badge={(!isFilterOpen && <IconBadgeCount count={filtersAppliedCount} />) || null}
        className={classNames}
        icon="filter"
        onClick={toggleFilter}
        type="icon"
      ></Button>
    </div>
  );

  return {
    filterButton,
    isFilterOpen,
    toggleFilter
  };
};
