import jwt_decode from 'jwt-decode';

import { combineReducers } from '@reduxjs/toolkit';
import { AllAuthActions, AuthActionTypes, AuthState, AuthTokens, JWTToken } from './types';

// Action Creators
export const clearTokens = () => ({ type: AuthActionTypes.CLEAR_TOKENS });
export const setTokens = (data: AuthTokens) => ({ type: AuthActionTypes.SET_TOKENS, data });

// Sub-reducers
export function accessToken(accessToken = '', action: AllAuthActions) {
  switch (action.type) {
    case AuthActionTypes.SET_TOKENS:
      return action?.data?.access_token;

    default:
      return accessToken;
  }
}

export function refreshToken(refreshToken = '', action: AllAuthActions) {
  switch (action.type) {
    case AuthActionTypes.SET_TOKENS:
      return action?.data?.refresh_token;

    default:
      return refreshToken;
  }
}

export function entitlements(entitlements = {}, action: AllAuthActions) {
  switch (action.type) {
    case AuthActionTypes.SET_TOKENS: {
      const accessToken: any = jwt_decode(action?.data?.access_token);
      if (typeof accessToken === 'object' && accessToken) {
        return accessToken['aurasvc:entitlements'];
      }
      return {};
    }
    default:
      return entitlements;
  }
}

// Initial state
export const accessTokenDecodedInitial: JWTToken = {
  alias: '',
  'aurasvc:directory_key': '',
  'aurasvc:entitlements': {},
  'aurasvc:session_id': '',
  exp: 0,
  iat: 0,
  jti: '',
  nbf: 0,
  sub: ''
};

export const refreshTokenDecodedInitial: JWTToken = {
  exp: 0,
  iat: 0,
  jti: ''
};

// Root reducer
export const auth = combineReducers<AuthState>({
  accessToken,
  refreshToken,
  entitlements
});
