// Globals
import axios, { Method } from 'axios';
import { getWindow } from 'ssr-window';

// Types
import { ApiParams, RequestApiParams } from './types';
import { EbThunk, PostApiParams } from 'store/types';

// Utils
import {
  authRoutes,
  interpolatePathParams,
  nonAuthRoutes,
  nonBearerRoutes,
  refreshRoutes
} from './utils';
import { matchPath } from 'utils/matchPath';

// Services
import {
  getAccessToken,
  getAccessTokenExpires,
  getRefreshTokenExpires
} from 'services/auth/selectors';
import { clearError, setFailure, setPending, setSuccess } from './reducers';

// Misc
import config from 'config';
import { doRefreshTokens } from '../auth/async';

function getApCredentials() {
  const window = getWindow();

  let apiKey = '';
  let apiSecret = '';

  apiKey = config.ap_api_key;
  apiSecret = config.ap_api_secret;

  return window.btoa(`${apiKey}:${apiSecret}`);
}

function getAuthHeader(
  endpoint: string,
  isFileUpload?: boolean
): EbThunk<
  | { authorization: string; 'content-type': string }
  | { 'Auth-Token': string; 'content-type': string }
  | { 'content-type': string }
  | null
> {
  return (_, getState) => {
    let authHeader = null;

    switch (true) {
      // For File Upload
      case isFileUpload: {
        authHeader = { 'content-type': 'text/csv' };
        break;
      }
      // Non-auth route - no authorization needed
      case matchPath(nonAuthRoutes, endpoint): {
        break;
      }
      case matchPath(authRoutes, endpoint): {
        // Auth route requiring session token
        const prefix = matchPath(nonBearerRoutes, endpoint) ? '' : 'Bearer ';
        const accessToken = getAccessToken(getState());

        const authorization = prefix + accessToken;
        authHeader = { authorization, 'content-type': 'application/json' };
        break;
      }

      // Auth route requiring basic auth
      default: {
        const credentials = getApCredentials();
        const authorization = `Basic ${credentials}`;
        authHeader = { authorization, 'content-type': 'application/json' };
      }
    }

    return authHeader;
  };
}

function request<T>({
  baseURL,
  endpoint,
  data,
  method,
  params,
  isAmzEndpoint
}: RequestApiParams): EbThunk<Promise<T>> {
  return async (dispatch) => {
    try {
      // Request data
      const authorization = dispatch(getAuthHeader(endpoint, isAmzEndpoint));
      // Request
      const response = await axios({
        method: method as Method,
        baseURL,
        url: endpoint,
        data, // Data to be sent as the request body
        params, // URL parameters to be sent with the request
        headers: {
          ...authorization
        }
      });
      return response.data;
    } catch (error: any) {
      if (error.response) {
        // Request successful, status code range outside of 2xx
        // eslint-disable-next-line no-console
        console.log(error.response.data);
        throw error.response.data;
      } else if (error.request) {
        // Request made but no response was received
        // eslint-disable-next-line no-console
        console.log(error.request);
        throw error.request;
      } else {
        // Request not made, issue setting up request
        // eslint-disable-next-line no-console
        console.log(error.message);
        throw new Error(error?.message);
      }
    }
  };
}

function handleProtectedPaths(endpoint: string): EbThunk<Promise<void>> {
  return async (dispatch) => {
    // Not refresh route - skip
    if (!matchPath(refreshRoutes, endpoint)) {
      return;
    }

    // Refresh route
    await dispatch(handleExpiredAuthToken());
  };
}

export function handleExpiredAuthToken(): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();

    const accessTokenExpires = getAccessTokenExpires(state);
    const refreshTokenExpires = getRefreshTokenExpires(state);

    // Invalid parent access token and valid refresh token - refresh parent tokens
    if (accessTokenExpires.getTime() < Date.now() && refreshTokenExpires.getTime() > Date.now()) {
      await dispatch(doRefreshTokens());
    }
  };
}

export function post<T>({
  baseURL,
  endpoint,
  data,
  method = 'POST',
  params,
  pathParams = [],
  isAmzEndpoint = false
}: PostApiParams): EbThunk<Promise<T>> {
  return async (dispatch) => {
    // If endpoint includes placeholders replace with path params
    const fullEndpoint =
      pathParams.length > 0 ? interpolatePathParams(endpoint, pathParams) : endpoint;
    try {
      dispatch(
        setPending({
          endpoint,
          method,
          data: isAmzEndpoint ? {} : data,
          params,
          pathParams
        })
      );
      await dispatch(handleProtectedPaths(fullEndpoint));
      const response = await dispatch(
        request<T>({
          baseURL,
          endpoint: fullEndpoint,
          data,
          method,
          params,
          isAmzEndpoint
        })
      );
      dispatch(
        setSuccess({
          endpoint,
          method,
          pathParams
        })
      );
      return response;
    } catch (error) {
      dispatch(
        setFailure({
          endpoint,
          method,
          error,
          pathParams
        })
      );
      throw error;
    }
  };
}

// Clients:
// Ap - services
export function ap<T>({ endpoint, data }: ApiParams): EbThunk<Promise<T>> {
  return post<T>({
    baseURL: config.ap_api_origin,
    endpoint,
    data
  });
}

// Employee Benefits - services
export function eb<T>({
  endpoint,
  data,
  method,
  params,
  pathParams
}: ApiParams): EbThunk<Promise<T>> {
  return post<T>({
    baseURL: config.eb_api_origin,
    endpoint,
    data,
    method,
    params,
    pathParams
  });
}

// General Purpose Reporting (Analytics)
export function gpr<T>({ endpoint, data, method }: ApiParams): EbThunk<Promise<T>> {
  return post<T>({ baseURL: config.gpr_api_origin, endpoint, data, method });
}

// AWS S3 Object upload/download
export function amz<T>({
  baseURL,
  endpoint,
  method,
  data,
  params,
  pathParams
}: ApiParams): EbThunk<Promise<T>> {
  return post<T>({
    baseURL,
    endpoint,
    method,
    data,
    params,
    pathParams,
    isAmzEndpoint: true
  });
}

export function clearErrors(
  errors: Array<(...props: any) => EbThunk<any>>
): EbThunk<Promise<void>> {
  return async (dispatch) => {
    errors.forEach((error) => {
      dispatch(clearError(error));
    });
  };
}
