// Globals
import React, { useState } from 'react';
import './styles.scss';

// Components
import { Button } from 'components/Button';
import { Form } from 'components/Form';
import { Heading } from 'components/Heading';
import { Input } from 'components/Input';
import { LayoutAuth } from 'components/LayoutAuth';
import { Paragraph } from 'components/Paragraph';

// Services
import { getEndpointFailure, getEndpointPending, getEndpointSuccess } from 'services/api/selectors';
import { doResetPassword } from 'services/auth/async';

// Misc
import { parseQueryString } from 'utils/parseQueryString';
import { schema } from './schema';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { EbDispatch, EbState } from 'store';
import { PasswordTooltip } from 'components/PasswordTooltip';

// Component
const PasswordSet = () => {
  const dispatch: EbDispatch = useDispatch();

  // Selectors
  const isError = useSelector((state: EbState) => getEndpointFailure(state, doResetPassword));
  const pending = useSelector((state: EbState) => getEndpointPending(state, doResetPassword));
  const success = useSelector((state: EbState) => getEndpointSuccess(state, doResetPassword));

  // Hooks - state
  const [passwordFocused, setPasswordFocused] = useState(false);

  // Handlers
  const onSubmit = ({ password }: any) => dispatch(doResetPassword({ password, code }));
  const onPasswordFocus = () => setPasswordFocused(true);

  // Vars
  const { code } = parseQueryString();
  const formProps = {
    className: 'eb-reset_password-form',
    error: isError ? 'An error occurred. Please contact your admin.' : '',
    name: 'reset-password',
    onSubmit,
    schema
  };

  // Render
  // No code query string from reset password email link - redirect to Sign In page
  if (!code) {
    return <Navigate to="/sign-in" />;
  }

  // Reset password success - redirect to Success page
  if (success) {
    return <Navigate to="/reset-password-success" />;
  }

  return (
    <LayoutAuth className="eb-reset_password">
      <Heading align="center" size={26} type="h1">
        Set your password
      </Heading>

      <Paragraph align="center" className="eb-reset_password-sub-heading">
        Set a strong password for enhanced security and to access Aura on other devices
      </Paragraph>

      <Form {...formProps}>
        <Input
          id="password"
          name="password"
          onFocus={onPasswordFocus}
          placeholder="Enter password"
          type="password"
        />
        <Input name="confirm_password" placeholder="Confirm password" type="password" />
        <PasswordTooltip isFocused={passwordFocused} />

        <Button isDisabled={pending} type="submit">
          Save password
        </Button>
      </Form>
    </LayoutAuth>
  );
};

export { PasswordSet };
