import {
  CompanyPeopleRecord,
  GetCompanyPeopleResponse,
  MemberDetails,
  PeopleActionTypes,
  PeopleAllAction,
  PeopleState
} from './types';
import { initialMemberState, initialParams, initialState } from './initialState';

export const setCompanyPeople = (data: GetCompanyPeopleResponse, companyCode: string) => ({
  type: PeopleActionTypes.SET_PEOPLE,
  data,
  companyCode
});

export const updateCompanyPeople = (data: CompanyPeopleRecord[]) => ({
  type: PeopleActionTypes.UPDATE_PEOPLE,
  data
});

export const setMemberDetails = (data: MemberDetails) => ({
  type: PeopleActionTypes.SET_MEMBER_DETAILS,
  data
});

export const clearMemberDetails = () => ({
  type: PeopleActionTypes.CLEAR_MEMBER_DETAILS
});

export const setCount = (data: number) => ({ type: PeopleActionTypes.SET_COUNT, data });

export const setPageSize = (data: number) => ({
  type: PeopleActionTypes.SET_PAGE_SIZE,
  data
});

export const setPage = (data: number) => ({
  type: PeopleActionTypes.SET_PAGE,
  data
});

export const setSearch = (data: string) => ({
  type: PeopleActionTypes.SET_SEARCH,
  data
});

export const setFilter = (data: Record<string, any>) => ({
  type: PeopleActionTypes.SET_FILTER,
  data
});

export const resetFilters = () => ({
  type: PeopleActionTypes.RESET_FILTERS
});

function people(peopleState: PeopleState = initialState, action: PeopleAllAction): PeopleState {
  switch (action.type) {
    case PeopleActionTypes.SET_PEOPLE: {
      const {
        data: { members, eligibilityUserCount, enrolledUserCount, total = 0, ...restParams }
      } = action;
      const { search, filters, totalWithoutFilters } = peopleState.params;

      return {
        ...peopleState,
        list: members,
        eligibilityUserCount: eligibilityUserCount?.count,
        enrolledUserCount: enrolledUserCount?.count,
        params: {
          ...(peopleState?.params || initialParams),
          ...restParams,
          total,
          totalWithoutFilters: filters || search ? totalWithoutFilters : total
        }
      };
    }
    case PeopleActionTypes.UPDATE_PEOPLE: {
      const { data } = action;

      return {
        ...peopleState,
        list: data
      };
    }
    case PeopleActionTypes.SET_MEMBER_DETAILS: {
      const member = action.data;

      return {
        ...peopleState,
        memberDetails: {
          ...member
        }
      };
    }
    case PeopleActionTypes.CLEAR_MEMBER_DETAILS: {
      return {
        ...peopleState,
        memberDetails: initialMemberState
      };
    }
    case PeopleActionTypes.SET_PAGE: {
      const page = action.data;
      return {
        ...peopleState,
        params: {
          ...(peopleState?.params || initialParams),
          page
        }
      };
    }
    case PeopleActionTypes.SET_PAGE_SIZE: {
      const pageSize = action.data;
      return {
        ...peopleState,
        params: {
          ...(peopleState?.params || initialParams),
          pageSize,
          page: 0
        }
      };
    }
    case PeopleActionTypes.SET_SEARCH: {
      const search = action.data;
      return {
        ...peopleState,
        params: {
          ...peopleState.params,
          page: 0,
          totalWithoutFilters: peopleState.params.totalWithoutFilters,
          search
        }
      };
    }
    case PeopleActionTypes.SET_FILTER: {
      const filters = action.data;
      return {
        ...peopleState,
        params: {
          ...peopleState.params,
          page: 0,
          totalWithoutFilters: peopleState.params.totalWithoutFilters,
          filters
        }
      };
    }
    case PeopleActionTypes.RESET_FILTERS: {
      return {
        ...peopleState,
        params: initialParams
      };
    }
    default:
      return peopleState;
  }
}

export { people };
