import * as endpoints from 'endpoints';
import { ap } from 'services/api/async';
import { AuthTokens, ResetPasswordParams } from './types';
import { clearTokens, setTokens } from './reducers';
import { EbThunk } from 'store/types';
import { getRefreshToken } from './selectors';

// Single source of truth for mapping async fns to their endpoints and HTTP methods
doRefreshTokens.data = {
  domain: endpoints.AP,
  endpoint: endpoints.REFRESH_TOKENS,
  method: 'POST'
};

doBeginResetPassword.data = {
  domain: endpoints.AP,
  endpoint: endpoints.USER_PASSWORD_RESET_BEGIN,
  method: 'POST'
};

doResetPassword.data = {
  domain: endpoints.AP,
  endpoint: endpoints.USER_PASSWORD_RESET,
  method: 'POST'
};

// Async action creators - api

export function doRefreshTokens(): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    // Request data
    const {
      data: { endpoint }
    } = doRefreshTokens;

    const refresh_token = getRefreshToken(getState());
    const tokens = await dispatch(
      ap<AuthTokens>({
        endpoint,
        data: {
          refresh_token
        }
      })
    );
    dispatch(setTokens(tokens));
  };
}

export function doLogout(): EbThunk<Promise<void>> {
  return async (dispatch) => {
    dispatch(clearTokens());
  };
}

export function doBeginResetPassword(data: string): EbThunk<Promise<void>> {
  return async (dispatch) => {
    // Request data
    const {
      data: { endpoint }
    } = doBeginResetPassword;

    await dispatch(ap({ endpoint, data }));
  };
}

export function doResetPassword(data: ResetPasswordParams): EbThunk<Promise<void>> {
  return async (dispatch) => {
    // Request data
    const {
      data: { endpoint }
    } = doResetPassword;

    await dispatch(ap({ endpoint, data }));
  };
}
