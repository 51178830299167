export enum UserSettingsActionTypes {
  SET_USER_SETTINGS = 'eb.user_settings.SET_USER_SETTINGS',
  ADD_COMPANY_NOTIFICATIONS = 'eb.user_settings.ADD_COMPANY_NOTIFICATIONS',
  REMOVE_COMPANY_NOTIFICATIONS = 'eb.user_settings.REMOVE_COMPANY_NOTIFICATIONS'
}

// Generic userSettings actions
export interface UserSettingsActions<P> {
  type: UserSettingsActionTypes;
  data: P;
}

export interface UserSettingsGetResponse {
  notifications: { fileFailedCompanyCodes: string[]; fileSuccessCompanyCodes: string[] };
}

export interface UserSettingsSetAction extends UserSettingsActions<UserSettingsGetResponse> {
  type: UserSettingsActionTypes.SET_USER_SETTINGS;
}

export interface UserSettingsAddCompanyNotificationsAction extends UserSettingsActions<string> {
  type: UserSettingsActionTypes.ADD_COMPANY_NOTIFICATIONS;
}
export interface UserSettingsRemoveCompanyNotificationsAction extends UserSettingsActions<string> {
  type: UserSettingsActionTypes.REMOVE_COMPANY_NOTIFICATIONS;
}

// Sub-reducer action union
export type UserSettingsAllActions =
  | UserSettingsSetAction
  | UserSettingsAddCompanyNotificationsAction
  | UserSettingsRemoveCompanyNotificationsAction;

// State types
export interface UserSettingsNotificationsState {
  fileFailedCompanyCodes: string[];
  fileSuccessCompanyCodes: string[];
}

export interface UserSettingsState {
  notifications: UserSettingsNotificationsState;
}
