// Globals
import './styles.scss';
import React from 'react';

// Components
import { Button } from 'components/Button';
import { Form } from 'components/Form';
import { Icon } from 'components/Icon';
import { Input } from 'components/Input';
import { Paragraph } from 'components/Paragraph';

// Services
import { getEndpointPending } from 'services/api/selectors';

// Misc
import clsx from 'clsx';
import { EbState } from 'store';
import { searchSchema } from './schema';
import { useSelector } from 'react-redux';
import { SearchFormValues, SearchProps } from './types';

// Component
const Search: React.FC<SearchProps> = ({
  customSchema = undefined,
  onCancel,
  onSubmit,
  placeHolder = '',
  request,
  suggestions,
  totalResultsFound = 0,
  isVisible = true
}) => {
  // Hooks - Selectors
  const isRequestPending = useSelector((state: EbState) => getEndpointPending(state, request));

  // Hooks - state
  const [searchValue, setSearchValue] = React.useState<string>();

  // Refs
  const inputRef = React.useRef<HTMLDivElement>(null);

  // Handlers
  const focusInput = React.useCallback(() => {
    if (isVisible && inputRef.current) {
      const input = inputRef.current.querySelector('input');
      input?.focus();
    }
  }, [isVisible]);

  const onSearchClear = () => {
    setSearchValue('');

    if (onCancel) {
      onCancel();
    }
    focusInput();
  };

  const onSearchChange = (event: React.ChangeEvent) => {
    const { value } = event.target as HTMLInputElement;
    setSearchValue(value);
  };

  const onSearchSubmit = ({ search }: SearchFormValues) => {
    onSubmit(search);
  };

  // Hooks - useEffect
  React.useEffect(() => {
    focusInput();
  }, [focusInput]);

  React.useEffect(() => {
    if (!isVisible) {
      setSearchValue('');
    }
  }, [isVisible]);

  // Vars
  const cancelIconClasses = clsx('eb-search-icon-close', {
    'eb-search-icon-close-disabled': !searchValue
  });

  const formProps = {
    name: 'search',
    id: 'search',
    onSubmit: onSearchSubmit,
    schema: customSchema ?? searchSchema
  };

  // Render
  const renderResultCount = isRequestPending ? null : (
    <Paragraph>
      <span className="eb-search-total-records">{totalResultsFound}</span> results found
    </Paragraph>
  );

  const renderSuggestions =
    !isRequestPending && totalResultsFound < 1 ? (
      <div className="eb-search-suggestions">{suggestions}</div>
    ) : null;

  if (!isVisible) return null;

  return (
    <div className="eb-search">
      <Form {...formProps}>
        <div className="eb-search-form">
          <div className="eb-search-form-input-text" ref={inputRef}>
            <Icon className="eb-search-icon-search" name="search" />
            <Input
              id="eb-search-input"
              name="search"
              onChange={onSearchChange}
              placeholder={placeHolder}
              type="text"
              value={searchValue}
            />
            {searchValue && (
              <Button
                className={cancelIconClasses}
                icon="erase"
                intent="secondary"
                isDisabled={!searchValue}
                onClick={onSearchClear}
                type="icon"
              />
            )}
          </div>
          <Button
            className="eb-search-button"
            display="inline"
            isDisabled={isRequestPending}
            type="submit"
          >
            Search
          </Button>
        </div>
      </Form>
      {renderResultCount}
      {renderSuggestions}
    </div>
  );
};

export { Search };
