// Globals
import './styles.scss';
import React from 'react';

// Components
import { Anchor } from 'components/Anchor';
import { Icon } from 'components/Icon';

// Services
import { getOrganizationName } from 'services/organizations/selectors';

// Misc
import { applyParamsToPath, getCrumbRoutes } from './util';
import { EbRoute } from 'routes';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Breadcrumbs: React.FC = () => {
  // Hooks
  const location = useLocation();
  const params = useParams();

  // Hooks - selectors
  const selectedOrgName = useSelector(getOrganizationName);

  // Vars
  const crumbs = getCrumbRoutes(location, params);

  // Render
  if (crumbs.length <= 0) {
    return null;
  }

  const renderBreadcrumbItem = ({ name, path }: Partial<EbRoute>, index: number) => {
    const isDisabled = index === crumbs.length - 1;
    if (path === '/' || !path) {
      return;
    }
    return (
      <React.Fragment key={path}>
        <Icon name="arrow_right" />
        <li className="eb-breadcrumbs-list" key={path}>
          <Anchor
            className="eb-breadcrumbs-link"
            intent="secondary"
            isDisabled={isDisabled}
            key={path}
            path={applyParamsToPath(path, params)}
          >
            {name}
          </Anchor>
        </li>
      </React.Fragment>
    );
  };

  return (
    <ul className="eb-breadcrumbs">
      {selectedOrgName &&
        renderBreadcrumbItem(
          { name: selectedOrgName, path: `/${params.companyCode}/dashboard` },
          -1
        )}
      {crumbs.map((item, index) => renderBreadcrumbItem(item, index))}
    </ul>
  );
};

export { Breadcrumbs };
