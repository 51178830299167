// Globals
import React from 'react';

//Components
import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';
import { ModalBodyAction } from 'components/Modal/BodyAction';
import { Form } from 'components/Form';

// Misc
import { useDispatch, useSelector } from 'react-redux';
import { clearApiSpecConfig, setApiSpecConfig } from 'services/apiSpec/reducers';
import { getApiSpecConfig } from 'services/apiSpec/selectors';

interface ApiSpecModalAuthProps {
  hide(): void;

  isVisible: boolean;
}

export const ApiSpecModalAuth: React.FC<ApiSpecModalAuthProps> = ({ hide, isVisible }) => {
  const dispatch = useDispatch();

  const { auth } = useSelector(getApiSpecConfig);

  const config = { username: auth.username, pass: auth.pass, bearer: auth.bearer };

  return (
    <Modal hide={hide} isVisible={isVisible}>
      <ModalBodyAction
        actionBarButtonCancel={
          <Button intent="secondary" onClick={() => dispatch(clearApiSpecConfig())}>
            Clear
          </Button>
        }
        actionBarButtonSave={
          <Button form="auth-form" intent="primary" type="submit">
            Authorize
          </Button>
        }
      >
        <Form
          defaultValuesAsync={config}
          id="auth-form"
          name="auth-form"
          onSubmit={(formValues) => {
            dispatch(setApiSpecConfig({ auth: { ...formValues } }));
            hide();
          }}
        >
          <Heading size={26} type="h1">
            Set credentials
          </Heading>
          <Heading size={18} type="h2">
            Basic auth
          </Heading>
          <Input label="Username" name="username" type="text" />
          <Input label="Password" name="login" type="text" />
          <Heading size={18} type="h2">
            Bearer
          </Heading>
          <Input label="Bearer" name="bearer" type="text" />
        </Form>
      </ModalBodyAction>
    </Modal>
  );
};
