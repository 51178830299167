import * as endpoints from 'endpoints';
import { ApiDomain } from 'services/api/types';
import { eb } from 'services/api/async';
import { EbThunk } from 'store/types';
import { getOrganizationsSelected } from 'services/organizations/selectors';
import { FileDetailsRequestParams, GetFileDetailsResponse } from './types';
import { setFileDetails } from './reducers';
import { getFileDetailsParams } from './selectors';

doGetFileById.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.ORG_FILE_GET,
  method: 'GET'
};

export function doGetFileById(fileId: string): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      data: { endpoint, method }
    } = doGetFileById;
    const { page, pageSize, sortField, sortOrder } = getFileDetailsParams(getState());
    const companyCode = getOrganizationsSelected(getState());

    const urlParams = new URLSearchParams();
    const params: FileDetailsRequestParams = { page, pageSize, sortField, sortOrder };

    Object.keys(params).forEach((param: string) => {
      const paramVal = params[param as keyof FileDetailsRequestParams];
      if (paramVal) urlParams.append(param, paramVal?.toString());
    });

    const response = await dispatch(
      eb<GetFileDetailsResponse>({
        endpoint,
        method,
        params: urlParams,
        pathParams: [companyCode, fileId]
      })
    );
    dispatch(setFileDetails(response, companyCode));
  };
}
