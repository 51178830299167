// Globals
import './styles.scss';

// Components
import { Button } from 'components/Button';
import { Form } from 'components/Form';
import { Heading } from 'components/Heading';
import { Input } from 'components/Input';
import { LayoutAuth } from 'components/LayoutAuth';
import { Paragraph } from 'components/Paragraph';

// Services
import { doBeginResetPassword } from 'services/auth/async';
import { getEndpointError, getEndpointPending, getEndpointSuccess } from 'services/api/selectors';

// Misc
import { schema } from './schema';
import { useDispatch, useSelector } from 'react-redux';
import { EbDispatch, EbState } from 'store';

// Component
const PasswordForgot = () => {
  // Hooks
  const dispatch: EbDispatch = useDispatch();

  // Hooks - selectors
  const error = useSelector((state: EbState) => getEndpointError(state, doBeginResetPassword));
  const pending = useSelector((state: EbState) => getEndpointPending(state, doBeginResetPassword));
  const success = useSelector((state: EbState) => getEndpointSuccess(state, doBeginResetPassword));

  // Handlers
  const onSubmit = (data: string) => {
    dispatch(doBeginResetPassword(data));
  };

  // Vars
  const emailFailure = error
    ? "We're sorry, we were not able to process your request. Please try again or contact support"
    : undefined;
  const emailSuccess = success ? 'Email sent. If it takes a moment, check spam.' : undefined;
  const formProps = {
    className: 'eb-forgot_password-form',
    error: emailFailure,
    name: 'forgot-password',
    onSubmit,
    schema,
    success: emailSuccess
  };

  // Render
  return (
    <LayoutAuth className="eb-forgot_password">
      <Heading align="center" className="eb-forgot_password-heading" size={26} type="h1">
        Forgot your password?
      </Heading>

      <Paragraph align="center" className="eb-forgot_password-sub-heading" size={16}>
        No problem, enter your email and we’ll send you instructions
      </Paragraph>

      <Form {...formProps}>
        <Input name="email" placeholder="Email address" type="text" />
        <Button isDisabled={pending} type="submit">
          Email me a reset link
        </Button>
      </Form>
    </LayoutAuth>
  );
};

export { PasswordForgot };
