// Global
import React from 'react';
import './styles.scss';

// Components
import { Button } from 'components/Button';
import { Form } from 'components/Form';
import { Heading } from 'components/Heading';
import { Input } from 'components/Input';
import { Paragraph } from 'components/Paragraph';

// Types
import { MfaConfigureProps, MfaSetupFormValues } from './types';

// Misc
import { schema } from './schema';

// Assets
import mailImage from 'assets/media/mail.svg';

const MfaConfigure: React.FC<MfaConfigureProps> = ({ error, configureMfa, isPending }) => {
  return (
    <div className="eb-mfa_configure">
      <Heading align="center" className="eb-mfa_configure-heading" size={26} type="h4">
        Enable 2-factor authentication to secure your account
      </Heading>
      <img alt="Email image" className="eb-mfa_configure-image" src={mailImage} />
      <Paragraph align="center">Confirm your email address</Paragraph>
      <Paragraph className="eb-mfa_configure-text">We’ll email you a security code</Paragraph>

      <Form
        className="eb-mfa_configure-form"
        error={error}
        name="eb-mfa_configure"
        onSubmit={({ email }: MfaSetupFormValues) => configureMfa(email)}
        schema={schema}
      >
        <Input
          className="eb-mfa_configure-form-input"
          name="email"
          placeholder="Enter email address"
          type="text"
        />
        <Button display="inline" intent="primary" isFullWidth isLoading={isPending} type="submit">
          Confirm
        </Button>
      </Form>
    </div>
  );
};

export { MfaConfigure };
