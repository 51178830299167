import { combineReducers } from '@reduxjs/toolkit';
import {
  Company,
  DoGetOrganizationDetailsResp,
  DoGetOrganizationsResp,
  OrganizationsActionTypes,
  OrganizationsAllActions,
  OrganizationsState
} from './types';
import { formatCompanyCodes, formatList } from './utils';
import { initialCompany, initialParams } from './initialState';

export const setOrganizations = (data: DoGetOrganizationsResp) => ({
  type: OrganizationsActionTypes.SET_ORGANIZATIONS,
  data
});

export const setOrganizationDetailsSelected = (data: DoGetOrganizationDetailsResp) => ({
  type: OrganizationsActionTypes.SET_ORGANIZATION_DETAILS_SELECTED,
  data
});

export const setPage = (data: number) => ({ type: OrganizationsActionTypes.SET_PAGE, data });

export const setPageSize = (data: number) => ({
  type: OrganizationsActionTypes.SET_PAGE_SIZE,
  data
});

export const setSearch = (data: string) => ({
  type: OrganizationsActionTypes.SET_SEARCH,
  data
});

export const resetParams = () => ({ type: OrganizationsActionTypes.RESET_PARAMS });

export const setOrganizationSelected = (data: string) => ({
  type: OrganizationsActionTypes.SET_ORGANIZATION_SELECTED,
  data
});

// Sub-reducers
export function list(list: Record<string, Company> = {}, action: OrganizationsAllActions) {
  switch (action.type) {
    case OrganizationsActionTypes.SET_ORGANIZATIONS: {
      const newList = formatList(action.data);
      return { ...list, ...newList };
    }
    case OrganizationsActionTypes.SET_ORGANIZATION_DETAILS_SELECTED: {
      const companyCode = action.data.org.code;
      const company = list[companyCode];

      return {
        ...list,
        [companyCode]: { ...company, org: action.data.org }
      };
    }
    default:
      return list;
  }
}

export function companyCodes(companyCodes = {}, action: OrganizationsAllActions) {
  switch (action.type) {
    case OrganizationsActionTypes.SET_ORGANIZATIONS: {
      const newPage = formatCompanyCodes(action.data);
      // Ensure fresh data by spreading newPage, even if page previously requested
      return { ...companyCodes, ...newPage };
    }
    default:
      return companyCodes;
  }
}

export function params(params = initialParams, action: OrganizationsAllActions) {
  switch (action.type) {
    case OrganizationsActionTypes.SET_ORGANIZATIONS: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { companies, total, ...restParams } = action.data;
      return {
        ...params,
        total: total ?? 0,
        ...restParams
      };
    }
    case OrganizationsActionTypes.SET_PAGE:
      return {
        ...params,
        page: action.data
      };
    case OrganizationsActionTypes.SET_PAGE_SIZE:
      return {
        ...params,
        pageSize: action.data
      };
    case OrganizationsActionTypes.SET_SEARCH:
      return {
        ...params,
        page: 0,
        search: action.data
      };
    case OrganizationsActionTypes.RESET_PARAMS:
      return initialParams;
    default:
      return params;
  }
}

export function selected(selected: string = '', action: OrganizationsAllActions) {
  switch (action.type) {
    case OrganizationsActionTypes.SET_ORGANIZATION_SELECTED:
      return action.data;
    default:
      return selected;
  }
}

export function selectedOrganizationDetails(
  details = initialCompany,
  action: OrganizationsAllActions
) {
  switch (action.type) {
    case OrganizationsActionTypes.SET_ORGANIZATION_DETAILS_SELECTED:
      return { ...action.data };
    default:
      return details;
  }
}

// Root Reducer
export const organizations = combineReducers<OrganizationsState>({
  companyCodes,
  list,
  params,
  selected,
  selectedOrganizationDetails
});
