import {
  DoGetAdminUsersResp,
  UsersActionTypes,
  AdminUsersAllActions,
  AdminUsersState,
  User
} from './types';
import { initialParams } from './initialState';
import { combineReducers } from '@reduxjs/toolkit';

export const setAdminUsers = (data: DoGetAdminUsersResp) => ({
  type: UsersActionTypes.SET_ADMIN_USERS,
  data
});

// Sub-reducers
export function users(users: User[] = [], action: AdminUsersAllActions) {
  switch (action.type) {
    case UsersActionTypes.SET_ADMIN_USERS: {
      return action.data.users;
    }
    default:
      return users;
  }
}

export function params(params = initialParams, action: AdminUsersAllActions) {
  switch (action.type) {
    case UsersActionTypes.SET_ADMIN_USERS: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { users, total, ...restParams } = action.data;
      return {
        total: total ?? 0,
        ...restParams
      };
    }
    default:
      return params;
  }
}

// Root Reducer
export const adminUsers = combineReducers<AdminUsersState>({ params, users });
