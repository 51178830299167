import React from 'react';

export interface AccordionProps {
  className?: string;
  children: React.ReactNode;
  isDisabled?: boolean;
  isOpen?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  topBar?: React.ReactNode;
}

export enum TEST_IDS {
  LIST_ITEM = 'LIST_ITEM',
  LIST_ICON = 'LIST_ICON'
}
