// Globals
import './styles.scss';
import React, { useState } from 'react';

// Components
import { AriaButton } from 'components/AriaButton';
import { Button } from 'components/Button';
import { Dot } from 'components/Dot';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { OrganizationExpandedData } from './OrganizationExpandedData';
import { TagStatus } from 'components/TagStatus';

// Services
import {
  doRemoveNotificationSettings,
  doAddNotificationSettings
} from 'services/userSettings/async';
import {
  getOrganizationByCode,
  getOrganizationRecentFileByOrgCode
} from 'services/organizations/selectors';
import { getUserSettingsIsNotificationsEnabledForCompanyCode } from 'services/userSettings/selectors';
import { setToastSuccess } from 'services/toast/reducers';

// Types
import { OrganizationProps } from './types';

// Misc
import clsx from 'clsx';
import { EbDispatch, EbState } from 'store';
import { getFileStatusDisplayName, getFileStatusTag } from 'utils/statuses';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const Organization: React.FC<OrganizationProps> = ({ code }) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch: EbDispatch = useDispatch();

  // Hooks - state
  const [isExpanded, setIsExpanded] = useState(false);

  // Hooks - selectors
  const { org, programs } = useSelector((state: EbState) => getOrganizationByCode(state, code));
  const { fileName, fileStatus, uploadedAt, failureCount } = useSelector((state: EbState) =>
    getOrganizationRecentFileByOrgCode(state, code)
  );
  const isOrgNotificationsEnabled = useSelector((state: EbState) =>
    getUserSettingsIsNotificationsEnabledForCompanyCode(state, code)
  );

  // Handlers
  const onOrgClick = () => {
    navigate(`/${code}/dashboard`);
  };

  const handleExpand = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  };

  const setNotifications = async (e: React.MouseEvent) => {
    e.stopPropagation();

    if (isOrgNotificationsEnabled) {
      await dispatch(doRemoveNotificationSettings(code));
      dispatch(setToastSuccess(`Successfully unsubscribed from ${org.name ?? code} files updates`));
    } else {
      await dispatch(doAddNotificationSettings(code));
      dispatch(setToastSuccess(`Successfully subscribed to ${org.name ?? code} files updates`));
    }
  };

  // Vars
  const tagStatus = getFileStatusTag(fileStatus, failureCount);
  const fileStatusName = getFileStatusDisplayName(fileStatus, 'File');
  const notificationClassName = clsx('eb-organization-details-heading-notification', {
    'eb-organization-details-heading-notification-active': isOrgNotificationsEnabled
  });
  const isOrgExpandable = programs?.length || uploadedAt || (org && Object.values(org).length > 0);

  return (
    <div className="eb-organization">
      <AriaButton className="eb-organization-section" isDisabled={!org.code} onClick={onOrgClick}>
        <div className="eb-organization-details">
          <Heading className="eb-organization-details-heading" size={18} type="h2">
            <div className="eb-organization-details-heading-link">
              <div className="eb-organization-details-heading-name">{org.name ?? 'N/A'} </div>
            </div>
            <Icon className={notificationClassName} name="bell" onClick={setNotifications} />
          </Heading>
          <div className="eb-organization-details-heading-code">
            <span>{org.code ?? 'N/A'}</span>
            {!org?.isProd && (
              <>
                <Dot />
                <span>ONBOARDING</span>
              </>
            )}
          </div>
        </div>
        {fileName && (
          <>
            <TagStatus className="eb-organization-tag" status={tagStatus}>
              {fileStatusName}
            </TagStatus>
          </>
        )}
        {isOrgExpandable && (
          <Button
            icon={isExpanded ? 'arrow_up' : 'arrow_down'}
            intent="text"
            onClick={handleExpand}
            type="icon"
          />
        )}
      </AriaButton>
      {isExpanded ? (
        <OrganizationExpandedData org={org} programs={programs} uploadedAt={uploadedAt} />
      ) : null}
    </div>
  );
};

export { Organization };
