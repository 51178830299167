import { format, isValid } from 'date-fns';

export function formatDate(date?: string) {
  try {
    if (!date) {
      return '';
    }
    return format(new Date(date), 'MMM d, yyyy');
  } catch {
    return '';
  }
}

export function formatBirthDate(date?: string) {
  if (!date) {
    return '';
  }
  const dateObj = new Date(date);

  if (!isValid(dateObj)) return '';

  return dateObj.toLocaleString('en-US', {
    timeZone: 'UTC',
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });
}
