import { parseEbError, parsePlatformError } from 'utils/parseErrors';
import * as endpoints from 'endpoints';
import { ApiDomain } from './types';

export const refreshRoutes = {
  [endpoints.PEOPLE_GET]: true,
  [endpoints.FILES_LIST]: true,
  [endpoints.FILE_UPDATE]: true,
  [endpoints.ORG_FILES_GET]: true,
  [endpoints.ORG_FILE_GET]: true,
  [endpoints.ORGANIZATION_GET]: true,
  [endpoints.PRESIGNED_URL_GET]: true,
  [endpoints.PRESIGNED_URL_DOWNLOAD_GET]: true,
  [endpoints.MEMBER_DETAILS_GET]: true,
  [endpoints.MEMBER_ACTION]: true,
  [endpoints.USER_SETTINGS]: true,
  [endpoints.USERS]: true,
  [endpoints.CUID_FILES]: true,
  [endpoints.CUID_PRESIGNED_URL]: true,
  [endpoints.CUID_UPLOAD_FILE]: true,
  [endpoints.CUID_RESPONSE]: true,
  [endpoints.CUID_UPDATE]: true,
  [endpoints.PARTNER_ENROLLMENT_EXTERNAL_UPDATE]: true,
  [endpoints.PARTNER_SPEC_GET]: true
};

export const nonAuthRoutes = {};

export const authRoutes = {
  ...refreshRoutes
};

export const nonBearerRoutes = {};

export const mapDomainToParser = {
  [ApiDomain.AP]: parsePlatformError,
  [ApiDomain.EB]: parseEbError,
  [ApiDomain.FTE]: parsePlatformError,
  [ApiDomain.CUID]: parsePlatformError
};

export function interpolatePathParams(endpoint: string, pathParams: any[]) {
  // Sequentially replace each placeholder with corresponding value in pathParams array
  return endpoint.split(/{\d}/).reduce((newEndpoint, partialPath, index) => {
    if (partialPath) {
      newEndpoint += partialPath;
    }
    if (pathParams[index]) {
      newEndpoint += pathParams[index];
    }
    return newEndpoint;
  }, '');
}
