// Globals
import './styles.scss';
import React, { FC } from 'react';

// Components
import { Icon } from 'components/Icon';

// Types
import { Status, TagStatusProps } from './types';

// Misc
import clsx from 'clsx';

// Icon map
const icons = {
  error: 'attention',
  success: 'success',
  pending: 'time',
  warning: 'warning'
};

// Component
const TagStatus: FC<TagStatusProps> = ({ children, className, status = Status.SUCCESS }) => {
  const classes = clsx(`eb-tag_status eb-tag_status-${status}`, className);
  const icon = icons[status];

  return (
    <div className={classes}>
      <Icon name={icon} />
      {children}
    </div>
  );
};

export { TagStatus };
