import React from 'react';

export const nestedReactToStringAPI = (element: React.ReactElement | string): string => {
  if (typeof element === 'string') {
    return element;
  }

  if (typeof element.props?.children === 'string') {
    return element.props.children;
  }
  return element.props.children
    .map((child: React.ReactElement) => nestedReactToStringAPI(child))
    .join('');
};

export const nestedReactToStringSchema = (element: Record<string, string[]>): string => {
  const errors = Object.entries(element).reduce(
    (errors: Record<string, string>[], [failedFieldName, errorMessage]) => {
      return [...errors, { [failedFieldName]: errorMessage.join('; ') }];
    },
    []
  );

  return JSON.stringify(errors);
};
