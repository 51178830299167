// Globals
import './styles.scss';
import React from 'react';

// Components
import { Anchor } from 'components/Anchor';
import { AriaLink } from 'components/AriaLink';

// Services
import { getEntitlementEbUserAccessLevel } from 'services/auth/selectors';

// Misc
import { AccessLevel } from 'services/auth/types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

// Assets
import auraLogo from 'assets/media/aura.svg';

// Props
interface LogoProps {
  displayCompanyName: string;
  isSessionValid: Boolean;
}

const Logo: React.FC<LogoProps> = ({ displayCompanyName, isSessionValid }) => {
  // Hooks - selectors
  const userGroupAccessLevel = useSelector(getEntitlementEbUserAccessLevel);

  // Vars
  const classes = clsx('eb-logo-partner', {
    'eb-logo-partner-company': displayCompanyName !== 'PORTAL',
    'eb-logo-partner-portal': !isSessionValid || displayCompanyName === 'PORTAL'
  });

  // Render
  const renderChangeLink =
    isSessionValid &&
    userGroupAccessLevel === AccessLevel.FULL &&
    displayCompanyName !== 'PORTAL' ? (
      <AriaLink className="eb-logo-change-link" path="/organizations">
        Change
      </AriaLink>
    ) : null;

  return (
    <Anchor className="eb-logo" path="/">
      <img alt="aura black" src={auraLogo} />
      <span className={classes}>{displayCompanyName}</span>
      {renderChangeLink}
    </Anchor>
  );
};

export { Logo };
