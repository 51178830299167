// Globals
import './styles.scss';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Icon } from 'components/Icon';
import { Paragraph } from 'components/Paragraph';

// Misc
import clsx from 'clsx';
import { getToastMessage, getToastType, isToastVisible } from 'services/toast/selectors';
import { setToastIsVisible } from 'services/toast/reducers';
import { useLocation } from 'react-router-dom';
import { EbDispatch, EbState } from 'store/types';

// Types
import { ToastProps, ToastTypes, LocationState } from './types';

const Toast: FC<ToastProps> = ({ timeout = 3500 }) => {
  const dispatch = useDispatch<EbDispatch>();
  const { state: locationState } = useLocation();

  const timer = React.useRef<EbTimeout>();

  // Hooks - selectors
  const message = useSelector((state: EbState) => getToastMessage(state));
  const isVisible = useSelector((state: EbState) => isToastVisible(state));
  const type = useSelector((state: EbState) => getToastType(state));

  // Hooks - effects
  useEffect(() => {
    // clear any existing toasts on load or page change unless otherwise specified
    if (!(locationState as LocationState)?.persistToast) {
      dispatch(setToastIsVisible(false));
    }
  }, [dispatch, locationState]);

  useEffect(() => {
    if (isVisible) {
      timer.current = setTimeout(() => {
        dispatch(setToastIsVisible(false));
      }, timeout);

      return () => clearTimeout(timer.current);
    }
  }, [dispatch, isVisible, timeout]);

  // classes
  const classes = clsx('eb-toast', {
    'eb-toast-visible': isVisible,
    'eb-toast-success': type === ToastTypes.SUCCESS,
    'eb-toast-error': type === ToastTypes.ERROR,
    'eb-toast-warning': type === ToastTypes.WARNING
  });

  // component
  return (
    <div className={classes}>
      <Icon
        className="eb-toast-icon"
        name={type === ToastTypes.SUCCESS ? 'checkmark' : 'attention'}
      />
      <Paragraph className="eb-toast-message">{message}</Paragraph>
    </div>
  );
};

export { Toast };
