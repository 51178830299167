import React from 'react';
import './styles.scss';

import Swagger from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

// Components
import { AriaLink } from 'components/AriaLink';
import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { Input } from 'components/Input';
import { LayoutPage } from 'components/LayoutPage';
import { Loading } from 'components/Loading';
import { ApiSpecModalAuth } from './ModalAuth';

// Misc
import { ApiSpecLayoutPlugin, DisableTryItOutPlugin } from './util';
import { useDispatch, useSelector } from 'react-redux';
import { doGetPartnerApiSpec } from 'services/apiSpec/async';
import { EbDispatch, EbState } from 'store';
import {
  getApiSpecConfig,
  getApiSpecEnvironments,
  getApiSpecPlain,
  getApiSpecVersion
} from 'services/apiSpec/selectors';
import { getEndpointPending } from 'services/api/selectors';

export const ApiSpec = () => {
  const dispatch: EbDispatch = useDispatch();

  // Hooks - selectors
  const environments = useSelector(getApiSpecEnvironments);
  const isPending = useSelector((state: EbState) => getEndpointPending(state, doGetPartnerApiSpec));
  const version = useSelector(getApiSpecVersion);
  const spec = useSelector(getApiSpecPlain);
  const { auth } = useSelector(getApiSpecConfig);

  // Refs
  const authRef = React.useRef(auth);

  // Hooks - state
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [selectedEnvironment, setSelectedEnvironment] = React.useState('');

  React.useEffect(() => {
    dispatch(doGetPartnerApiSpec());
  }, [dispatch]);

  React.useEffect(() => {
    authRef.current = auth;
  }, [auth]);

  React.useEffect(() => {
    const stagEnvironment = environments?.find((item) => item.url.includes('.stag'));
    setSelectedEnvironment(stagEnvironment?.url ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environments?.length]);

  const handleServerChange = React.useCallback((event: React.ChangeEvent) => {
    const { dataset } = event.target as HTMLInputElement;
    setSelectedEnvironment(dataset.value ?? '');
    dispatch(doGetPartnerApiSpec());
  }, [dispatch]);

  const interceptor = React.useCallback((request: Request) => {
    const { bearer, username, pass } = authRef.current;

    const authHeader = bearer ? `Bearer ${bearer}` : `Basic ${btoa(`${username}:${pass}`)}`;
    return {
      ...request,
      headers: { ...request.headers, Authorization: authHeader }
    };
  }, []);

  // Vars
  const isTryOutEnabled = selectedEnvironment?.includes('.stag');

  return (
    <LayoutPage
      className="eb-api-spec"
      hasBreadcrumbs
      heading={
        <div className="eb-api-spec-heading">
          <Heading size={32} type="h1">
            Aura Partner Enrollment API Endpoints
          </Heading>
          <span className="eb-api-spec-heading-version">Version {version}</span>
        </div>
      }
    >
      {isPending && <Loading isCentered>Loading...</Loading>}

      {!isPending && (
        <>
          <div className="eb-api_spec_layout-env">
            <div className="eb-api_spec_layout-env-info">
              {environments?.map((item) => (
                <Input
                  checked={selectedEnvironment === item.url}
                  data-value={item.url}
                  key={item.url}
                  label={
                    <>
                      {item.description} - <strong>{item.url}</strong>
                    </>
                  }
                  name={item.description}
                  onChange={handleServerChange}
                  type="radio"
                />
              ))}
            </div>
            {isTryOutEnabled && (
              <Button intent="primary" onClick={() => setIsModalVisible(true)}>
                Authorize
              </Button>
            )}
          </div>

          <Swagger
            layout="ApiSpecLayout"
            plugins={
              isTryOutEnabled ? [ApiSpecLayoutPlugin] : [ApiSpecLayoutPlugin, DisableTryItOutPlugin]
            }
            // @ts-ignore
            requestInterceptor={interceptor}
            spec={spec}
          />
          <div className="eb-api-section">
            <AriaLink className="eb-api-section-link" intent="text" path="/api">
              Back to Overview
            </AriaLink>
          </div>
        </>
      )}
      <ApiSpecModalAuth hide={() => setIsModalVisible(false)} isVisible={isModalVisible} />
    </LayoutPage>
  );
};
