// Globals
import React from 'react';
import './styles.scss';

// Components
import { Anchor } from 'components/Anchor';
import { Button } from 'components/Button';
import { Form } from 'components/Form';
import { Heading } from 'components/Heading';
import { Input } from 'components/Input';
import { LayoutAuth } from 'components/LayoutAuth';
import { Loading } from 'components/Loading';
import { MfaConfigure } from 'components/MfaConfigure';
import { MfaCode } from 'components/MfaCode';

// Misc
import { schema } from './schema';
import { useAuthManager } from 'hooks/useAuthManager';

// Types
import { SignInFormValues } from './types';
import { AuthChallenges } from 'hooks/useAuthManager/types';

const SignIn: React.FC = () => {
  // Hooks - custom
  const { currentChallenge, doConfigureMfa, doProvideMfaCode, doSignIn, pending, error } =
    useAuthManager();

  // Handlers
  const onSubmit = async (values: SignInFormValues) => {
    await doSignIn(values);
  };

  // Vars
  const formProps = {
    className: 'eb-sign_in-form',
    error: error,
    name: 'eb-sign_in',
    onSubmit,
    schema
  };

  // Render
  const renderChildren = () => {
    if (pending) {
      return <Loading>Signing in</Loading>;
    }

    switch (currentChallenge) {
      case AuthChallenges.CONFIGURE_MFA:
        return <MfaConfigure configureMfa={doConfigureMfa} error={error} isPending={pending} />;
      case AuthChallenges.PROVIDE_MFA:
        return <MfaCode isPending={pending} sendMfaCode={doProvideMfaCode} />;
      default:
        return (
          <>
            <Heading align="center" className="eb-sign_in-heading" size={26} type="h3">
              Sign in to your admin account
            </Heading>

            <Form {...formProps}>
              <Input name="alias" placeholder="Email address" type="text" />
              <Input name="password" placeholder="Password" type="password" />

              <Button className="eb-sign_in-button" type="submit">
                Sign in
              </Button>

              {/* Alternate links */}
              <div className="eb-sign_in-links-container">
                <Anchor path="/forgot-password">Forgot your password?</Anchor>
              </div>
            </Form>
          </>
        );
    }
  };

  return <LayoutAuth className="eb-sign_in">{renderChildren()}</LayoutAuth>;
};

export { SignIn };
