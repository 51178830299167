// Globals
import './styles.scss';
import React from 'react';

// Misc
import clsx from 'clsx';
import { DotProps } from './types';

// Component
const Dot: React.FunctionComponent<DotProps> = ({ className, color }) => {
  const classes = clsx('eb-dot', className, {
    'eb-dot-gray': color === 'gray',
    'eb-dot-red': color === 'red'
  });

  // Render
  return <span className={classes}>•</span>;
};

export { Dot };
