// Globals
import './styles.scss';
import React from 'react';

// Components
import { Heading } from 'components/Heading';

// Misc
import clsx from 'clsx';

// Types
import { LoadingProps } from 'components/Loading/types';

const Loading: React.FC<LoadingProps> = ({
  className = '',
  children = null,
  isCentered = false,
  intent = 'primary',
  size = 40
}) => {
  const classes = clsx('eb-loading', className, {
    'eb-loading-primary': intent === 'primary',
    'eb-loading-secondary': intent === 'secondary',
    'eb-loading-centered': isCentered
  });

  return (
    <div className={classes}>
      <svg
        className="eb-loading-spinner"
        fill="none"
        height={size}
        viewBox="0 0 24 24"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm0-3a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
          fill="currentColor"
          fillRule="evenodd"
          opacity=".2"
        />
        <path
          clipRule="evenodd"
          d="M10.5 1.5A1.5 1.5 0 0 1 12 0c6.627 0 12 5.373 12 12a1.5 1.5 0 0 1-3 0 9 9 0 0 0-9-9 1.5 1.5 0 0 1-1.5-1.5Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>

      {children && (
        <Heading align="center" className="eb-loading-heading" size={18} type="h6">
          {children}
        </Heading>
      )}
    </div>
  );
};

export { Loading };
