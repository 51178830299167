/*
Endpoints
*/

// Analytics
export const ANALYTICS_POST = '/api/report/identity_clog';

// Auth
export const REFRESH_TOKENS = '/auth/session/refresh_token';
export const SIGN_IN = '/auth/session/signin';
export const SIGN_OUT = '/auth/session/signout';
export const USER_PASSWORD_RESET_BEGIN = '/auth/user/begin_reset_password';
export const USER_PASSWORD_RESET = '/auth/user/reset_password';

// People
export const PEOPLE_GET = '/portal/members/{1}';
export const MEMBER_DETAILS_GET = '/portal/members/{1}/{2}';
export const MEMBERS_BULK_ACTION = '/portal/members/{1}/bulk_action';
export const MEMBER_ACTION = '/portal/members/{1}/{2}';

// Organization
export const ORGANIZATION_GET = '/portal/companies';
export const ORGANIZATION_DETAILS = '/portal/companies/{1}';

// Files
export const ORG_FILES_GET = '/portal/companies/{1}/files';
export const ORG_FILE_GET = '/portal/companies/{1}/files/{2}';
export const FILES_LIST = '/portal/file/list';
export const FILE_UPDATE = '/portal/file/update';

// Upload
export const PRESIGNED_URL_GET = '/portal/companies/{1}/presigned_url';
export const FILE_TRANSFORMER_EXTERNAL_PUT = '/{1}/{2}';

// Download
export const PRESIGNED_URL_DOWNLOAD_GET = '/portal/file/get_error_response_url';

// User settings
export const USER_SETTINGS = '/portal/user/settings';

// Admin users
export const USERS = '/portal/users';
export const ADMIN_USER = '/portal/users/{1}';

// CUID Management
export const CUID_RESPONSE = '/portal/cuid-update-files/response';
export const CUID_FILES = '/portal/cuid-update-files/{1}';
export const CUID_PRESIGNED_URL = '/file-processor/cuid-update-request/presigned-url';
export const CUID_UPLOAD_FILE = '/{1}/request/{2}';
export const CUID_UPDATE = '/portal/members/update_cuid';

// Partner External
export const PARTNER_ENROLLMENT_EXTERNAL_UPDATE = '/partner/enrollment/admin_update_external';

// API Spec
export const PARTNER_SPEC_GET = '/partner/api_spec/get';

/*
Domains
*/
export const AP = 'AP';
export const EB = 'EB';
