import React from 'react';
import './styles.scss';

// Components
import { Anchor } from 'components/Anchor';
import { AriaLink } from 'components/AriaLink';
import { LayoutPage } from 'components/LayoutPage';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { Paragraph } from 'components/Paragraph';

// Misc
import { doGetPartnerApiSpec } from 'services/apiSpec/async';
import { EbDispatch } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { getApiSpecByTag, getApiSpecEnvironments } from 'services/apiSpec/selectors';
import { Accordion } from '../../components/Accordion';
import { faqs } from './data';

export const Api = () => {
  const dispatch: EbDispatch = useDispatch();

  // Hooks - selectors
  const servers = useSelector(getApiSpecEnvironments);
  const specs = useSelector(getApiSpecByTag);

  React.useEffect(() => {
    dispatch(doGetPartnerApiSpec());
  }, [dispatch]);

  return (
    <LayoutPage className="eb-api" heading="Aura Partner Enrollment APIs">
      <div className="eb-api-page-nav">
        <Anchor className="eb-api-page-nav-link" href="#introduction">
          Introduction
        </Anchor>
        <Anchor className="eb-api-page-nav-link" href="#auth">
          Authentication
        </Anchor>
        <Anchor className="eb-api-page-nav-link" href="#getting-started">
          Getting Started
        </Anchor>
        <Anchor className="eb-api-page-nav-link" href="#endpoints">
          Endpoints
        </Anchor>
        <Anchor className="eb-api-page-nav-link" href="#faq">
          FAQs
        </Anchor>
      </div>
      <section className="eb-api-section" id="introduction">
        <Heading size={26} type="h2">
          1. Introduction
        </Heading>
        <Paragraph>
          The Partner Enrollment API allows you to create, modify, terminate, and view end user
          subscriptions to Aura, an intelligent online safety solution for individuals and families.
          This documentation includes a description of each endpoint and shows how to get started.
        </Paragraph>
        <br />
        <Paragraph>To set up and use the API, you should be familiar with:</Paragraph>
        <ul>
          <li>HTTP requests</li>
          <li>Webhooks</li>
        </ul>
      </section>

      <section className="eb-api-section" id="auth">
        <Heading size={26} type="h2">
          2. Authentication
        </Heading>
        <Paragraph>
          You&apos;ll be assigned credentials that allow you to access the Portal and perform tasks:
        </Paragraph>
        <ul>
          <li>
            <strong>Portal credentials:</strong> Use to access the Portal.
          </li>
          <li>
            <strong>Staging API credentials:</strong> Use to access the Staging API via Basic
            Authentication.
          </li>
          <li>
            <strong>Production API credentials:</strong> Use to access Production API via Basic
            Authentication.
          </li>
        </ul>
        <Paragraph>
          Please authenticate your requests using the{' '}
          <Anchor
            className="eb-api-section-link"
            href="https://datatracker.ietf.org/doc/html/rfc2617#page-5"
            intent="primary"
          >
            HTTP Basic Authentication Scheme
          </Anchor>
          .
        </Paragraph>
      </section>

      <section className="eb-api-section" id="getting-started">
        <Heading size={26} type="h2">
          3. Getting Started
        </Heading>
        <Heading size={18} type="h3">
          Step 1: Sign in to the Portal
        </Heading>
        <ul>
          <li>
            Sign in with your Portal credentials and navigate to the API documentation. You can use
            your Staging API credentials to try out the API in the API spec viewer.
          </li>
          <li>Review the API spec to get familiar with the API endpoints and request formats.</li>
        </ul>
        <Heading size={18} type="h3">
          Step 2: Test endpoints
        </Heading>
        <ul>
          <li>Using the Staging API, make a call to /webhook/create.</li>
          <li>
            Make a call to /enrollment/create.
            <ul>
              <li>
                Use sample data to simulate a new enrollment. Do not test with real personal data.
              </li>
              <li>Replace the placeholder with your credentials.</li>
            </ul>
          </li>
          <li>Check that you get a response to the URL specified in your /webhook/create call.</li>
        </ul>
        <Heading size={18} type="h3">
          Step 3: Enable API access for production
        </Heading>
        <ul>
          <li>
            When the Aura team verifies that your endpoints are working properly, we&apos;ll contact
            you about enabling production access. When enabled, you can proceed to the final step.
          </li>
        </ul>
        <Heading size={18} type="h3">
          Step 4: Prepare for production
        </Heading>
        <ul>
          <li>
            In the production environment, create a webhook by calling the /webhook/create endpoint.
            You only need to do this once.
          </li>
          <li>When the webhook is in place, you can start managing enrollments.</li>
        </ul>
      </section>

      <section className="eb-api-section" id="endpoints">
        <Heading size={26} type="h2">
          4. Endpoints
        </Heading>
        <AriaLink className="eb-api-section-link-block" intent="none" path="/api/spec">
          <div>
            <div className="eb-api-section-link-title">See detailed API specs</div>
            <div>Learn more about endpoint requests and possible responses.</div>
          </div>
          <Icon name="arrow_up_default" />
        </AriaLink>

        {Object.entries(specs).map(([tag, spec]) => (
          <div className="eb-api-section-tag" key={tag}>
            <Heading size={22} type="h3">
              {tag}
            </Heading>

            {spec.map((item, i) => (
              <div className="eb-api-section-endpoint-block" key={i}>
                <strong>{item.summary}</strong>
                <div>Endpoint: {item.path}</div>
                <div>Method: {item.method}</div>
              </div>
            ))}
          </div>
        ))}
      </section>

      <section className="eb-api-section" id="faq">
        <Heading size={26} type="h2">
          5. Frequently asked questions
        </Heading>

        {faqs.map((item) => (
          <Accordion key={item.question} topBar={item.question}>
            {typeof item.answer === 'function' ? item.answer({ servers }) : item.answer}
          </Accordion>
        ))}
      </section>

      <div>
        For additional help and support with your Aura Partner API, please contact{' '}
        <Anchor href="mailto:support@aura.com" intent="primary">
          support@aura.com
        </Anchor>
      </div>
    </LayoutPage>
  );
};
