import { EbThunk } from 'store/types';

export interface ApiParams {
  baseURL?: string;
  endpoint: string;
  data?: any;
  method?: string;
  params?: any;
  pathParams?: any[];
  isFileUpload?: boolean;
}

export interface ApiFailureParams {
  endpoint: string;
  method?: string;
  error?: string | unknown;
  pathParams?: any[];
}

export interface RequestApiParams {
  baseURL?: string;
  endpoint: string;
  data?: any;
  method?: string;
  params?: any;
  pathParams?: any[];
  isAmzEndpoint?: boolean;
}

// Action Types
export enum ApiActionTypes {
  /* eslint-disable no-unused-vars */
  SET_PENDING = 'eb.api.SET_PENDING',
  SET_SUCCESS = 'eb.api.SET_SUCCESS',
  SET_FAILURE = 'eb.api.SET_FAILURE',
  CLEAR_ERROR = 'eb.api.CLEAR_ERROR'
}

// Generic API actions
export interface ApiActions<P> {
  type: ApiActionTypes;
  data: P;
}

// API actions
export interface ApiSetPending extends ApiActions<ApiParams> {
  type: ApiActionTypes.SET_PENDING;
  params: any;
  pathParams: any[];
}

export interface ApiSetSuccess extends ApiActions<ApiParams> {
  type: ApiActionTypes.SET_SUCCESS;
}

export interface ApiSetFailure extends ApiActions<ApiFailureParams> {
  type: ApiActionTypes.SET_FAILURE;
  error: string | unknown;
}

export interface ApiClearError extends ApiActions<ApiParams> {
  type: ApiActionTypes.CLEAR_ERROR;
}

// Subreducer action union
export type ApiAllActions = ApiSetPending | ApiSetSuccess | ApiSetFailure | ApiClearError;

export type ApiRequest = (...args: any) => EbThunk<Promise<any>>;

export enum ApiDomain {
  AP = 'AP',
  EB = 'EB',
  FTE = 'FTE',
  CUID = 'CUID'
}
