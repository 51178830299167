// Globals
import './styles.scss';
import React from 'react';

// Components
import { FileUpload } from 'components/FileUpload';

// Props
interface ModalFileUploadInitialProps {
  footer?: React.ReactNode;
  onUpload: (fileList: FileList) => void;
}

// Component
const ModalFileUploadInitial: React.FC<ModalFileUploadInitialProps> = ({ footer, onUpload }) => {
  return (
    <>
      {/* File Drop & Upload Zone */}
      <FileUpload label="Drag and drop a CSV file" onUpload={onUpload} />
      <div className="eb-modal_file_upload_initial-footer">{footer}</div>
    </>
  );
};

export { ModalFileUploadInitial };
