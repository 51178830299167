export enum ApiSpecActionTypes {
  SET_API_SPECS = 'eb.apiSpec.SET_API_SPECS',
  SET_API_SPECS_CONFIG = 'eb.apiSpec.SET_API_SPECS_CONFIG',
  CLEAR_API_SPECS_CONFIG = 'eb.apiSpec.CLEAR_API_SPECS_CONFIG'
}

// Generic Action
export interface ApiSpecAction<P> {
  type: ApiSpecActionTypes;
  data: P;
}

export interface ApiSpecSetAction extends ApiSpecAction<ApiSpecGetResponse> {
  type: ApiSpecActionTypes.SET_API_SPECS;
}

export interface ApiSpecSetConfigAction extends ApiSpecAction<ApiSpecConfig> {
  type: ApiSpecActionTypes.SET_API_SPECS_CONFIG;
}

export interface ApiSpecClearConfigAction extends ApiSpecAction<void> {
  type: ApiSpecActionTypes.CLEAR_API_SPECS_CONFIG;
}

export type ApiSpecAllAction = ApiSpecSetAction | ApiSpecSetConfigAction | ApiSpecClearConfigAction;

export interface ApiSpecEnvironment {
  description: string;
  url: string;
}

export interface ApiSpecState {
  byTag: Record<string, ApiSpecItem[]>;
  version?: string;
  plain?: object;
  environments?: ApiSpecEnvironment[];
  config: ApiSpecConfig;
}

export type ApiSpecResponseItem = {
  requestBody: {};
  responses: {};
  security: {}[];
  tags: string[];
  summary: string;
};

export type ApiSpecItem = {
  requestBody: {};
  responses: {};
  security: {}[];
  path: string;
  method: string;
  summary: string;
};

export interface ApiSpecGetResponse {
  info: { version: string };
  paths: Record<string, Record<string, ApiSpecResponseItem>>;
  servers: ApiSpecEnvironment[];
}

export interface ApiSpecConfig {
  auth: { bearer: string; username: string; pass: string };
}

export const initialState: ApiSpecState = {
  byTag: {},
  version: '',
  environments: [],
  config: { auth: { bearer: '', username: '', pass: '' } }
};
