const VALID_FIRSTNAME = /^[a-zA-Z\s]*$/;

function isValidName(val = '') {
  if (val[0] === ' ') {
    return false;
  }

  return VALID_FIRSTNAME.test(val);
}

export const validName = {
  name: 'validName',
  message: 'Please use only letters and spaces',
  test: isValidName
};

// Valid LastName
const VALID_LASTNAME = /^[a-zA-Z\-' ]*$/;

function isValidLastName(val = '') {
  return VALID_LASTNAME.test(val);
}
const validLastName = {
  name: 'validLastName',
  message: 'Please use only letters, spaces, apostrophes, and hyphens',
  test: isValidLastName
};

export { validLastName };
