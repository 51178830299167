// Globals
import React from 'react';

// Components
import { Icon } from 'components/Icon';

// Misc
import onKeyDown from '@isubscribed/fat-tony/onKeyDown';
import { InputSelectOptionProps } from './types';

const InputSelectOption: React.FC<InputSelectOptionProps> = ({
  children,
  disabled,
  label,
  value,
  selectOption,
  selectedLabel,
  tabIndex
}) => {
  // Handlers
  const handleSelectOption = (event: React.MouseEvent<HTMLDivElement>) => {
    // @ts-ignore
    return !disabled && selectOption && selectOption(label, value, event);
  };

  const handleArrowDown = () => {
    // @ts-ignore
    const nextElementSibling = document.activeElement?.parentNode?.nextElementSibling;

    if (!nextElementSibling) {
      return;
    }

    const nextChildSibling = nextElementSibling.firstChild;
    return nextChildSibling && nextChildSibling.focus();
  };

  const handleArrowUp = () => {
    // @ts-ignore
    const previousElementSibling = document.activeElement?.parentNode?.previousElementSibling;

    if (!previousElementSibling) {
      return;
    }
    const previousChildSibling = previousElementSibling.firstChild;
    return (
      previousChildSibling && previousChildSibling.tabIndex > -1 && previousChildSibling.focus()
    );
  };

  const handleOptionKeys = (event: React.KeyboardEvent<HTMLDivElement>) => {
    onKeyDown([
      {
        event,
        key: 'Enter',
        handler: handleSelectOption
      },
      {
        event,
        key: 'Tab'
      },
      {
        event,
        key: 'ArrowDown',
        handler: handleArrowDown
      },
      {
        event,
        key: 'ArrowUp',
        handler: handleArrowUp
      }
    ]);
  };

  // Render
  return (
    <li>
      <div
        className="eb-input_select-list-item"
        data-label={selectedLabel}
        data-type="input-autocomplete"
        onClick={handleSelectOption}
        onKeyDown={handleOptionKeys}
        role="button"
        tabIndex={tabIndex}
        // @ts-ignore
        value={value}
      >
        {children}
        {!disabled && label === selectedLabel && (
          <Icon className="eb-input_select-list-item-icon" name="checkmark" title="check" />
        )}
      </div>
    </li>
  );
};

export { InputSelectOption };
