import * as Sentry from '@sentry/react';
import { AnyAction, StoreEnhancer, createNextState } from '@reduxjs/toolkit';
import type { Dictionary } from 'lodash';
import { EbState } from 'store/types';
import type { WritableDraft } from 'immer/dist/internal';
import { get } from 'lodash';

const PII_REPLACEMENT = 'PII_REMOVED';

/**
 * Use action types to strip entire payloads from actions
 * aura.SERVICE_NAME.ACTION_NAME
 */
const PII_ACTION_TYPE_PATTERNS = /^eb\.(api|auth).*/;

// Used to strip any given action type manually
const ACTION_SCRUBBERS: Record<string, (action: WritableDraft<AnyAction>) => typeof action> = {};

// Used to strip state at these paths and flag their values as PII
const PII_STATE_PATHS: string[] = ['auth.accessToken', 'auth.refreshToken'];

// Used to find & replace values we suspect might be pii but haven't been specifically flagged
const GENERIC_PII_PATTERNS: Dictionary<RegExp> = {
  EMAIL: /^[\w.!#$%&’*+/=?^`{|}~-]+@[a-zA-Z\d-]+(?:\.[a-zA-Z\d-]+)*$/,
  US_PHONE_NUMBER:
    /\b((\+|\b)[1l][-. ])?\(?\b[\dOlZSB]{3,5}([-. ]|\) ?)[\dOlZSB]{3}[-. ][\dOlZSB]{4}\b/,
  US_STREET_ADDRESS:
    /\b\d{1,8}\b[\s\S]{10,100}?\b(AK|AL|AR|AZ|CA|CO|CT|DC|DE|FL|GA|HI|IA|ID|IL|IN|KS|KY|LA|MA|MD|ME|MI|MN|MO|MS|MT|NC|ND|NE|NH|NJ|NM|NV|NY|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VA|VT|WA|WI|WV|WY)\b\s\d{5}\b/,
  EMAIL_ADDRESS: /\b[a-z\d._%+\-—|]+@[a-z\d.\-—|]+\.[a-z|]{2,6}\b/
};

// Track PII values for find & replace
const flaggedPIIValues = new Set<string>();

// Strip sensitive information from actions and state before they're sent to sentry
export const sentryReduxEnhancer: StoreEnhancer = Sentry.createReduxEnhancer({
  actionTransformer(_action) {
    return createNextState(_action, (action) => {
      if (PII_ACTION_TYPE_PATTERNS.test(action.type)) {
        // strip entire action
        action = { type: action.type, payload: PII_REPLACEMENT };
      } else {
        let serializedAction = JSON.stringify(action);

        // Replace values that look like pii even if we haven't flagged them specifically
        for (const [replacement, piiPattern] of Object.entries(GENERIC_PII_PATTERNS)) {
          serializedAction = serializedAction.replace(piiPattern, replacement);
        }

        // Replace previously identified pii values
        for (const piiValue of flaggedPIIValues) {
          serializedAction = serializedAction.replace(piiValue, PII_REPLACEMENT);
        }

        action = JSON.parse(serializedAction);

        // strip action with a specific handler if we have one
        if (action.type in ACTION_SCRUBBERS) {
          action = ACTION_SCRUBBERS[action.type](action);
        }
      }
    });
  },
  stateTransformer(state: EbState) {
    let serializedState = JSON.stringify(state);

    for (const path of PII_STATE_PATHS) {
      const value = get(state, path);
      if (value) flaggedPIIValues.add(value);
    }

    // Replace values at PII paths
    for (const [replacement, piiPattern] of Object.entries(GENERIC_PII_PATTERNS)) {
      serializedState = serializedState.replace(piiPattern, replacement);
    }

    // Replace previously identified pii values
    for (const piiValue of flaggedPIIValues) {
      serializedState = serializedState.replace(piiValue, PII_REPLACEMENT);
    }

    return JSON.parse(serializedState);
  }
});
