// Globals
import './styles.scss';
import React from 'react';

// Components
import { Loading } from 'components/Loading';

// Component
const ModalFileUploadUploading: React.FC = () => {
  return (
    <Loading className="aura-modal_file_upload_uploading" size={24}>
      Uploading...
    </Loading>
  );
};

export { ModalFileUploadUploading };
