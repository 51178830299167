// Global
import React from 'react';
import './styles.scss';

// Components
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';

// Components
import { Toast } from 'components/Toast';

// Misc
import clsx from 'clsx';

// Types
import { LayoutAuthProps } from './types';

const LayoutAuth: React.FC<LayoutAuthProps> = ({ children, className }) => {
  return (
    <div className={clsx(['eb-layout_auth', className])}>
      <Header nonAuth />
      <Toast />
      <div className="eb-layout_auth-content">
        <div className="eb-layout_auth-content-container">{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export { LayoutAuth };
