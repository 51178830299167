// Globals
import './styles.scss';
import React from 'react';

// Misc
import { EbTrackingEventType } from 'services/analytics/types';
import { InputRadioProps } from './types';
import { useTracking } from 'hooks/useTracking';

// Component
const InputRadio: React.FC<InputRadioProps> = (props) => {
  // Props
  const {
    children,
    checked,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    inputLabel, // Destructure from being passed to input element
    onChange,
    tracking,
    trackingEnabled,
    ...otherProps
  } = props;

  // Hooks - custom
  const trackEvent = useTracking();

  // Handlers
  const trackOnChange = (event: React.ChangeEvent) => {
    if (onChange) onChange(event);

    if (trackingEnabled) {
      trackEvent({
        ...tracking,
        action: tracking?.action && `rad_${tracking?.action}`,
        event: EbTrackingEventType.ui_click
      });
    }
  };

  // Vars
  const inputProps = {
    ...otherProps,
    checked,
    onChange: trackOnChange,
    type: 'radio'
  };

  // Render
  return (
    <div className="eb-input_radio">
      <input {...inputProps} />
      <span className="eb-input_radio-container eb-input_radio-button" />
      <span className="eb-input_radio-text">{children}</span>
    </div>
  );
};

export { InputRadio };
