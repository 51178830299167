import {
  ApiSpecActionTypes,
  ApiSpecAllAction,
  ApiSpecConfig,
  ApiSpecEnvironment,
  ApiSpecGetResponse,
  ApiSpecItem,
  ApiSpecState,
  initialState
} from './types';
import { combineReducers } from '@reduxjs/toolkit';

export const setApiSpecs = (data: ApiSpecGetResponse) => ({
  type: ApiSpecActionTypes.SET_API_SPECS,
  data
});

export const setApiSpecConfig = (data: ApiSpecConfig) => ({
  type: ApiSpecActionTypes.SET_API_SPECS_CONFIG,
  data
});

export const clearApiSpecConfig = () => ({
  type: ApiSpecActionTypes.CLEAR_API_SPECS_CONFIG
});

function byTag(
  state = initialState.byTag,
  action: ApiSpecAllAction
): Record<string, ApiSpecItem[]> {
  switch (action.type) {
    case ApiSpecActionTypes.SET_API_SPECS:
      return Object.entries(action.data.paths).reduce(
        (result: Record<string, ApiSpecItem[]>, [path, methods]) => {
          const tag = Object.values(methods)[0]?.tags[0];

          if (!tag) return result;

          const tagItems = result[tag] ?? [];

          const regroupedSpecs = Object.entries(methods).map(([method, spec]) => ({
            ...spec,
            path,
            method
          }));

          return {
            ...result,
            [tag]: [...tagItems, ...regroupedSpecs]
          };
        },
        {}
      );
    default:
      return state;
  }
}

function version(state = initialState.version, action: ApiSpecAllAction): string | undefined {
  switch (action.type) {
    case ApiSpecActionTypes.SET_API_SPECS:
      return action.data.info.version;
    default:
      return state;
  }
}

function environments(
  state = initialState.environments,
  action: ApiSpecAllAction
): ApiSpecEnvironment[] | undefined {
  switch (action.type) {
    case ApiSpecActionTypes.SET_API_SPECS:
      return action.data.servers;
    default:
      return state;
  }
}

function plain(state = initialState.plain, action: ApiSpecAllAction) {
  switch (action.type) {
    case ApiSpecActionTypes.SET_API_SPECS:
      return action.data;
    default:
      return state ?? {};
  }
}

function config(state = initialState.config, action: ApiSpecAllAction) {
  switch (action.type) {
    case ApiSpecActionTypes.SET_API_SPECS_CONFIG:
      return action.data;
    case ApiSpecActionTypes.CLEAR_API_SPECS_CONFIG:
      return initialState.config;
    default:
      return state;
  }
}

// Root Reducer
export const apiSpec = combineReducers<ApiSpecState>({
  version,
  byTag,
  plain,
  environments,
  config
});
