// If changed, keep odd  as its not coded to handle even options and no less than 5
export const MAX_PAGINATION_OPTIONS = 7;

function getOrderedNumbers(min: number, max: number): string[] {
  const orderedNumbers: string[] = [];
  for (let x = min; x <= max; x++) {
    orderedNumbers.push(x.toString());
  }
  return orderedNumbers;
}

export function getPaginationOptions(page: number, pagesTotal: number): string[] {
  // Only need ellipsis if you have more than MAX_PAGINATION_OPTIONS
  if (pagesTotal > MAX_PAGINATION_OPTIONS) {
    // Selected number on left side
    if (page + 1 <= Math.ceil(MAX_PAGINATION_OPTIONS / 2)) {
      const leftSide = getOrderedNumbers(1, MAX_PAGINATION_OPTIONS - 2);
      const rightSide = pagesTotal.toString();
      return [...leftSide, '...', rightSide];
    }
    // Selected number on right side
    if (page + 1 >= pagesTotal - Math.floor(MAX_PAGINATION_OPTIONS / 2)) {
      const leftSide = '1';
      const rightSide = getOrderedNumbers(
        pagesTotal - (MAX_PAGINATION_OPTIONS - 2) + 1,
        pagesTotal
      );
      return [leftSide, '...', ...rightSide];
    }
    // Selected number in center
    const numbersInCenter = MAX_PAGINATION_OPTIONS - 4;
    const numbersOnEitherSide = Math.floor(numbersInCenter / 2);
    const leftEdge = page + 1 - numbersOnEitherSide;
    const rightEdge = page + 1 + numbersOnEitherSide;

    const center = getOrderedNumbers(leftEdge, rightEdge);
    return ['1', '...', ...center, '...', pagesTotal.toString()];
  }
  // No need for ellipsiss
  return getOrderedNumbers(1, pagesTotal);
}
