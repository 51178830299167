import {
  CuidFilesActionTypes,
  CuidFilesAllAction,
  CuidFilesState,
  GetCuidFilesResponse
} from './types';
import { initialParams, initialState } from './selectors';

export const setCompanyCuidFiles = (data: GetCuidFilesResponse, companyCode: string) => ({
  type: CuidFilesActionTypes.SET_FILES,
  data,
  companyCode
});

export const setCount = (data: number) => ({ type: CuidFilesActionTypes.SET_COUNT, data });

export const setPageSize = (data: number) => ({
  type: CuidFilesActionTypes.SET_PAGE_SIZE,
  data
});

export const setPage = (data: number) => ({
  type: CuidFilesActionTypes.SET_PAGE,
  data
});

export const setSearch = (data: string) => ({
  type: CuidFilesActionTypes.SET_SEARCH,
  data
});

export const resetFilters = () => ({
  type: CuidFilesActionTypes.RESET_FILTERS
});

function cuidFiles(
  filesState: CuidFilesState = initialState,
  action: CuidFilesAllAction
): CuidFilesState {
  switch (action.type) {
    case CuidFilesActionTypes.SET_FILES: {
      const {
        data: { files, total = 0, ...restParams }
      } = action;
      const { search, totalWithoutFilters } = filesState.params;

      return {
        ...filesState,
        list: files,
        params: {
          ...(filesState?.params || initialParams),
          ...restParams,
          total,
          totalWithoutFilters: search ? totalWithoutFilters : total
        }
      };
    }
    case CuidFilesActionTypes.SET_PAGE: {
      const page = action.data;
      return {
        ...filesState,
        params: {
          ...(filesState?.params || initialParams),
          page
        }
      };
    }
    case CuidFilesActionTypes.SET_PAGE_SIZE: {
      const pageSize = action.data;
      return {
        ...filesState,
        params: {
          ...(filesState?.params || initialParams),
          page: 0,
          pageSize
        }
      };
    }
    case CuidFilesActionTypes.SET_SEARCH: {
      const search = action.data;
      return {
        ...filesState,
        params: {
          ...initialParams,
          search,
          totalWithoutFilters: filesState.params.totalWithoutFilters
        }
      };
    }
    case CuidFilesActionTypes.RESET_FILTERS: {
      return {
        ...filesState,
        params: initialParams
      };
    }
    default:
      return filesState;
  }
}

export { cuidFiles };
