import { FILE_UPLOAD_ERROR, RawStringType } from './types';

const REMOVE_FIRST_AND_LAST_DOUBLE_QUOTES_REGEXP = /^"?|"?$/g;

export const getFileHeader = (rawString: RawStringType) => {
  if (!rawString || rawString instanceof ArrayBuffer) {
    return;
  }

  const normalizedString = rawString.replace(/(\r\n|\r)/g, '\n');
  return normalizedString.slice(0, normalizedString.indexOf('\n')).split(',');
};

export const compareFileHeader = (fileHeader: string[], requiredCsvHeaders: string[]): Boolean => {
  const transformFileHeader = fileHeader.map((str) =>
    str
      .replace(REMOVE_FIRST_AND_LAST_DOUBLE_QUOTES_REGEXP, '')
      // Allow " ", "-", "_" and case insensitive
      .replace(/( )|-|_/g, '')
      .toLowerCase()
  );

  return requiredCsvHeaders.every((header) => transformFileHeader.includes(header));
};

export const readFileHandler = async (
  file: FileList,
  requiredCsvHeaders: string[] = []
): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      const fileName = file.item(0)?.name;
      const fileReader = new FileReader();
      fileReader.addEventListener('load', function onLoad(event) {
        const text = event.target?.result;
        const unNormalizedFile = getFileHeader(text);

        if (fileName?.endsWith('.csv')) {
          if (!unNormalizedFile) {
            reject(FILE_UPLOAD_ERROR.BAD_FILE_FORMAT);
            return;
          }

          const hasRequiredHeader = compareFileHeader(unNormalizedFile, requiredCsvHeaders);

          if (unNormalizedFile.length === 0 || !hasRequiredHeader) {
            reject(FILE_UPLOAD_ERROR.BAD_FILE_FORMAT);
            return;
          }
        }

        resolve();
        fileReader.removeEventListener('load', onLoad);
      });
      const fileToUpload = file[0];
      fileReader.readAsText(fileToUpload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      throw error;
    }
  });
};
