export function setCookie(name: string, value: string, expireAfterDays?: number) {
  let expiration;

  if (expireAfterDays) {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + expireAfterDays * 24 * 60 * 60 * 1000);
    expiration = `expires=${expirationDate.toUTCString()}`;
  }

  document.cookie = `${name}=${value}; ${expiration ?? ''};path=/`;
}

export function getCookie(name: string) {
  const cookies = document.cookie.split(';');

  let value = '';

  cookies.forEach((cookie) => {
    const trimmedCookie = cookie.trim();
    if (trimmedCookie.startsWith(`${name}=`)) {
      value = trimmedCookie.slice(name.length + 1, trimmedCookie.length);
    }
  });

  return value;
}
