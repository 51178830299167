// Global
import React from 'react';

// Components
import { DropdownItemButton } from './Button';
import { DropdownItemLink } from './Link';

// Types
import { DropdownItemProps } from './types';

// Misc
import DropdownContext from 'components/Dropdown/context';
import modulus from '@isubscribed/fat-tony/modulus';
import { DropdownItemButtonProps } from 'components/DropdownItem/Button/types';
import { DropdownItemLinkProps } from 'components/DropdownItem/Link/types';

// Utils
const handleArrowKeys = (direction: 'down' | 'up') => {
  const children = Array.from(
    document.querySelectorAll('[data-type="eb-dropdown_item-container"]')
  );

  const selectedIndex = children.indexOf(
    children.find((child) => child === document.activeElement)!
  );
  if (selectedIndex === -1) {
    return;
  }

  const numerator = direction === 'down' ? selectedIndex + 1 : selectedIndex - 1;
  const nextElement = children[modulus(numerator, children.length)] as HTMLElement;
  if (nextElement) {
    nextElement.focus();
  }
};

// Component
const DropdownItem: React.FC<DropdownItemProps> = ({ type = 'button', ...props }) => {
  // Hooks - context
  const { setIsOpen } = React.useContext(DropdownContext);

  // Render
  if (type === 'link') {
    const keyDownData = [
      { key: 'ArrowDown', handler: () => handleArrowKeys('down') },
      { key: 'ArrowUp', handler: () => handleArrowKeys('up') },
      { key: 'Escape', handler: () => setIsOpen(false) }
    ];

    return <DropdownItemLink {...{ keyDownData, ...(props as DropdownItemLinkProps) }} />;
  }

  return <DropdownItemButton {...(props as DropdownItemButtonProps)} />;
};

export { DropdownItem };
