import { detectDevice } from 'utils/detectDevice';
import { getWindow } from 'ssr-window';

// This utility looks for a group of elements via class name, finds the position
// of the first element, and scrolls to it if it is not visible in the viewport
const scrollTo = (className: string, targetOffset = 0) => {
  const window = getWindow();

  // Add timeout to allow for dynamic classes to be applied before attempting to scroll to elements
  setTimeout(() => {
    const elements = document.getElementsByClassName(className) as HTMLCollectionOf<HTMLElement>;
    if (elements.length === 0) {
      return;
    }

    const browser = detectDevice.detectPlatformBrowser();

    // check for the existence of the nav bar
    const getNavBarOffset = () => {
      const els = document.getElementsByClassName('eb-nav_bar');
      // if nav bar is not found, or target element is contained within a modal, return 0
      if (els.length === 0 || modalParent) {
        return 0;
      }

      // return the height of the nav bar plus a tiny buffer
      return els[0].getBoundingClientRect().height + 5;
    };
    const navBarOffset = getNavBarOffset();

    // determine whether the target element is currently offscreen
    const targetRect = elements[0].getBoundingClientRect();
    const isElementVisible = (elementRect: DOMRect, navOffset: number) => {
      return (
        elementRect.top >= navOffset &&
        elementRect.left >= 0 &&
        elementRect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        elementRect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    // if the target element is contained within a modal, scroll the modal instead of window
    const modalParent = elements[0].closest('.eb-modal-content');
    const elementToBeScrolled = modalParent ?? window;

    if (!isElementVisible(targetRect, navBarOffset)) {
      // if the target element is contained within a modal, get the offset relative to the modal;
      // otherwise, get the offset relative to the viewport
      const offsetStart = modalParent ? elements[0].offsetTop : targetRect.top;
      const containerTop = (modalParent ?? document.body).getBoundingClientRect().top;

      // calculate the y-coordinate to be scrolled to
      const offsetPosition = offsetStart - containerTop - targetOffset - navBarOffset;

      // do the thing
      if (browser === 'winDefault' || browser === 'winEdge') {
        elementToBeScrolled.scrollTo(0, offsetPosition);
      } else {
        elementToBeScrolled.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  }, 10);
};

export { scrollTo };
