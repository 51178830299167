import { EbState } from 'store/types';
import { createSelector } from 'reselect';

// Selectors
export const getToast = createSelector(
  (state: EbState) => state.toast,
  (toast) => toast
);

// Selectors - sub-reducers
export const getToastMessage = createSelector(getToast, (toast) => toast.message);

export const isToastVisible = createSelector(getToast, (toast) => toast.isVisible);

export const getToastType = createSelector(getToast, (toast) => toast.type);
