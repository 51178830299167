import './styles.scss';
import React from 'react';

// Components
import { IconAccount } from 'components/IconAccount';
import { Paragraph } from 'components/Paragraph';
import { Dot } from 'components/Dot';
import { DropdownIcon } from 'components/DropdownIcon';
import { DropdownItem } from 'components/DropdownItem';
import { Icon } from 'components/Icon';

// Services
import { getEntitlementsCanCreateAdminUser } from 'services/auth/selectors';

// Misc
import { getUserDisplayRole } from './util';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';

interface UserManagementUserProps {
  email: string;
  firstName: string;
  index: number;
  lastName: string;
  loggedInAt: string;
  adminGroups: string[];
  onUserRemoveClick(): void;
}

const UserManagementUser: React.FC<UserManagementUserProps> = ({
  email,
  firstName,
  index,
  lastName,
  loggedInAt,
  adminGroups,
  onUserRemoveClick
}) => {
  // Hooks - selectors
  const canCreateAdmin = useSelector(getEntitlementsCanCreateAdminUser);

  const loggedInDate = loggedInAt ? format(new Date(loggedInAt), 'MMM d, yyyy, hh:mm a') : '';

  // Render More icon
  const renderMoreIcon = canCreateAdmin ? (
    <DropdownIcon className="eb-user_management-user-more" icon="more">
      <DropdownItem onClick={onUserRemoveClick}>
        <Icon name="remove" />
        Remove admin
      </DropdownItem>
    </DropdownIcon>
  ) : null;

  // Render
  return (
    <div className="eb-user_management_user">
      <IconAccount firstName={firstName} id={index} lastName={lastName} />
      <div className="eb-user_management_user-heading">
        <Paragraph className="eb-user_management_user-paragraph">
          {firstName} {lastName}
        </Paragraph>
        <span className="eb-user_management_user-sub-text">{getUserDisplayRole(adminGroups)}</span>
        <Dot className="eb-user_management_user-subheading-dot" />
        <span className="eb-user_management_user-sub-text">{email}</span>
        {loggedInDate ? (
          <Paragraph className="eb-user_management_user-sub-text">
            Last Logged in {loggedInDate}
          </Paragraph>
        ) : null}
      </div>
      {renderMoreIcon}
    </div>
  );
};

export { UserManagementUser };
