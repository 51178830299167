import UAParser from 'ua-parser-js';
import { getWindow } from 'ssr-window';

const window = getWindow();

const UA = new UAParser();

function getOS() {
  return UA.getResult().os ?? '';
}

function getBrowser() {
  return UA.getResult().browser ?? '';
}

function getDevice() {
  return UA.getResult().device ?? '';
}

function getOsVersion() {
  return UA.getResult().os.version ?? '';
}

function getUserAgent() {
  return UA.getResult().ua ?? '';
}

function getOSName() {
  const osName = UA.getResult().os.name ?? '';
  if (/Mac/.test(osName)) {
    if ('ontouchend' in document) {
      // Apple has made iPads running iOS 13+ have a platform of 'MacIntel'
      return 'ios';
    }
    return 'macos';
  }
  if (/Windows/.test(osName)) {
    return 'windows';
  }
  if (/iOS/.test(osName)) {
    return 'ios';
  }
  if (/Android/.test(osName)) {
    return 'android';
  }
  if (/Linux/.test(osName)) {
    return 'linux';
  }
  return '';
}

function getPlatform(platform = window.navigator.platform) {
  if (platform.includes('Mac')) {
    if ('ontouchend' in document) {
      // Apple has made iPads running iOS 13+ have a platform of 'MacIntel'
      return 'ios';
    }
    return 'mac';
  }
  if (platform.includes('Win32')) {
    return 'windows';
  }

  if (/iPad|iPhone|iPod/.test(platform)) {
    return 'ios';
  }

  if (/Android|Linux armv7l|Linux/.test(platform)) {
    return 'android';
  }
  // Return empty string if not recognizable platform
  return '';
}

function detectPlatformBrowser() {
  const { userAgent, platform } = window.navigator;

  if (platform === 'MacIntel') {
    if (userAgent.includes('Firefox')) {
      return 'macFirefox';
    }
    if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
      return 'macDefault';
    }
    if (userAgent.includes('Trident')) {
      return 'macDefault';
    }
    if (userAgent.includes('Edge')) {
      return 'macDefault';
    }
    if (userAgent.includes('Chrome')) {
      return 'macChrome';
    }
    if (userAgent.includes('Safari')) {
      return 'macSafari';
    }
    return 'macDefault';
  }

  if (platform === 'Win32') {
    if (userAgent.includes('Firefox')) {
      return 'winFirefox';
    }
    if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
      return 'winDefault';
    }
    if (userAgent.includes('Trident')) {
      return 'winDefault';
    }
    if (userAgent.includes('Edge')) {
      return 'winEdge';
    }
    if (userAgent.includes('Chrome')) {
      return 'winChrome';
    }
    if (userAgent.includes('Safari')) {
      return 'winDefault';
    }
    return 'winDefault';
  }

  return 'defaultPlatform';
}

function getBrowserVersion() {
  return getBrowser().version ?? '';
}

function isSafari() {
  return getBrowser().name === 'Safari';
}

function isMobileSafari() {
  return getBrowser().name === 'Mobile Safari';
}

function isFirefox() {
  return getBrowser().name === 'Firefox';
}

const detectDevice = {
  getOS,
  getOSName,
  getPlatform,
  detectPlatformBrowser,
  getBrowser,
  getDevice,
  getOsVersion,
  getUserAgent,
  getBrowserVersion,
  isSafari,
  isMobileSafari,
  isFirefox
};

export { detectDevice };
