// Globals
import './styles.scss';
import React from 'react';

// Components
import { Button } from 'components/Button';
import DropdownContext from 'components/Dropdown/context';

// Types
import { DropdownItemButtonProps } from './types';
import { EbTrackingEventType } from 'services/analytics/types';

// Misc
import clsx from 'clsx';
import { useTracking } from 'hooks/useTracking';

// Component
const DropdownItemButton: React.FC<DropdownItemButtonProps> = ({
  children,
  className = '',
  isDisabled = false,
  onClick,
  tracking = null,
  trackingEnabled = false
}) => {
  // Hooks
  const trackEvent = useTracking();

  // Hooks - context
  const { setIsOpen } = React.useContext<{
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }>(DropdownContext);

  // Vars
  const classes = clsx('eb-dropdown_item_button', className);

  // Handlers
  function handleOnClick(event: React.MouseEvent<HTMLElement>) {
    // Prevent click event from bubbling
    event.stopPropagation();

    // Tracking
    if (trackingEnabled && tracking) {
      trackEvent({
        ...tracking,
        action_name: tracking.action_name,
        action: `drp_${tracking.action ?? 'undefined'}`,
        event: EbTrackingEventType.ui_click
      });
    }
    if (onClick) {
      onClick(event);
    }
    setIsOpen(false);
  }

  // Render
  return (
    <li className={classes}>
      <Button
        className="eb-dropdown_item-container"
        data-type="eb-dropdown_item-container"
        intent="text"
        isDisabled={isDisabled}
        onClick={handleOnClick}
        trackingDisabled
      >
        {children}
      </Button>
    </li>
  );
};

export { DropdownItemButton };
