// Globals
import './styles.scss';
import React from 'react';

// Components
import { Button } from 'components/Button';

// Types
import clsx from 'clsx';
import { FileUploadProps } from './types';

// Assets
import uploadFile from 'assets/media/upload-file.svg';
import invalidFile from 'assets/media/upload-file-invalid.svg';

// Consts
const initFileUploadState = {
  isDragOver: false,
  isDragOverValid: true
};

// Component
const FileUpload = React.memo(({ label, accept, onUpload }: FileUploadProps) => {
  const [fileUploadState, setFileUploadState] = React.useState(initFileUploadState);

  const handleDragOver = (e: React.DragEvent<HTMLInputElement>) => {
    const item = e.dataTransfer.items[0];
    setFileUploadState({
      isDragOver: true,
      isDragOverValid: item && accept ? accept.includes(item.type) : true
    });
  };

  const handleDragLeave = () => {
    setFileUploadState(initFileUploadState);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileUploadState(initFileUploadState);

    const fileList = e.target.files;
    if (fileList) {
      onUpload(fileList);
    }
  };

  const handleOnDrop = () => {
    setFileUploadState(initFileUploadState);
  };

  const classes = clsx('eb-file_upload', {
    'eb-file_upload-drag-over': fileUploadState.isDragOver,
    'eb-file_upload-drag-invalid': !fileUploadState.isDragOverValid
  });

  const renderChildren = () => {
    switch (true) {
      case fileUploadState.isDragOver && fileUploadState.isDragOverValid:
        return (
          <>
            <img alt="Upload file" className="eb-file_upload-label-drag-img" src={uploadFile} />
            <div className="eb-file_upload-label-drag-over">Drop your file here</div>
          </>
        );

      case fileUploadState.isDragOver && !fileUploadState.isDragOverValid:
        return (
          <>
            <img alt="Upload file" className="eb-file_upload-label-drag-img" src={invalidFile} />
            <div className="eb-file_upload-label-drag-over-invalid">This file is not supported</div>
          </>
        );
      default:
        return (
          <>
            <div className="eb-file_upload-label">{label}</div>
            <p className="eb-file_upload-divider">or</p>
            <Button className="eb-file_upload-button" intent="primary">
              <span>Upload from computer</span>
            </Button>
          </>
        );
    }
  };

  return (
    <section className={classes}>
      {renderChildren()}
      <input
        accept={accept}
        className="eb-file_upload-field"
        id="eb-file_upload-field"
        multiple={false}
        onChange={handleChange}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleOnDrop}
        type="file"
      />
    </section>
  );
});

export { FileUpload };
