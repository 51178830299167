import { object, string } from 'yup';

const schema = object({
  email: string()
    .max(80, 'Email cannot exceed 80 characters in length')
    .email('Please enter a valid email address')
    .required('Email is required')
});

export { schema };
