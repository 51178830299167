// Globals
import React from 'react';
import './styles.scss';

// Types
import { AnchorProps } from './types';
import { EbTrackingEventType } from 'services/analytics/types';

// Misc
import clsx from 'clsx';
import onKeyDown from '@isubscribed/fat-tony/onKeyDown';
import { useTracking } from 'hooks/useTracking';
import { useNavigate } from 'react-router-dom';
import { useLink } from 'react-aria';

// Component
const Anchor: React.FC<AnchorProps> = (props) => {
  const {
    children,
    className = '',
    href,
    intent = 'primary',
    isDisabled = false,
    isNewTab = false,
    onClick,
    path,
    tracking = null,
    trackingEnabled = false,
    ...ariaLinkOptions
  } = props;
  // PropType error
  if ((href && onClick) || (href && path) || (onClick && path)) {
    throw new Error('Anchor can only use one of href, onClick, or path');
  }

  // Hooks - refs
  const linkRef = React.useRef<HTMLAnchorElement>(null);

  // Hooks
  const navigate = useNavigate();
  const { linkProps } = useLink(
    {
      ...ariaLinkOptions,
      isDisabled,
      onKeyDown: handleOnKeyDown
    },
    linkRef
  );

  // Hooks - custom
  const trackEvent = useTracking();

  // Vars
  const classes = clsx('eb-anchor', className, {
    'eb-anchor-primary': intent === 'primary',
    'eb-anchor-secondary': intent === 'secondary',
    'eb-anchor-tertiary': intent === 'tertiary',
    'eb-anchor-inverted': intent === 'inverted',
    'eb-anchor-disabled': isDisabled
  });

  const newTabProps = isNewTab ? { rel: 'noopener noreferrer', target: '_blank' } : {};

  // Handlers
  function handleOnKeyDown(event: React.KeyboardEvent) {
    if (path) {
      onKeyDown([
        {
          event,
          key: 'Enter',
          handler: handleOnClick
        }
      ]);
    }
  }

  const handleOnClick = (event: React.MouseEvent) => {
    if (isDisabled) return;

    if (trackingEnabled) {
      let action;
      if (tracking?.action) action = `lnk_${tracking?.action}`;
      else if (typeof children === 'string') action = `lnk_${children}`;
      else {
        // Construct default unique id from props
        const actionPayload = {
          className,
          path: path ?? '',
          href: href ?? '',
          intent
        };

        action = 'lnk_' + btoa(JSON.stringify(actionPayload));
      }

      trackEvent({
        ...tracking,
        action_name: tracking?.action_name ?? children?.toString(),
        action,
        event: EbTrackingEventType.ui_click
      });
    }

    event.stopPropagation();
    if (path) {
      navigate(path);
    }

    if (onClick) {
      onClick(event);
    }
  };

  // Render
  return (
    <a
      {...linkProps}
      {...newTabProps}
      className={classes}
      href={href}
      onClick={handleOnClick}
      tabIndex={0}
    >
      {children}
    </a>
  );
};

export { Anchor };
