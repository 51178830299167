// Globals
import React from 'react';

// Components
import { ButtonIcon } from './Icon';
import { ButtonText } from './Text';

// Types
import { EbTrackingEventType } from 'services/analytics/types';
import { ButtonProps, ButtonType } from './types';

// Misc
import { useTracking } from 'hooks/useTracking';
import { useNavigate } from 'react-router-dom';

// Constants
const ButtonTypes = {
  icon: ButtonIcon,
  submit: ButtonText,
  button: ButtonText
};

// Component
const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  className,
  goto,
  hoverText,
  icon,
  isDisabled,
  onClick,
  type = ButtonType.BUTTON,
  tracking,
  trackingDisabled,
  isFullWidth,
  ...passedProps
}) => {
  // Hooks
  const navigate = useNavigate();

  // Hooks - custom
  const trackEvent = useTracking();

  // Handlers
  const click = (event: React.MouseEvent<HTMLElement>) => {
    // Click event for submit is handled in Form Component
    if (type !== 'submit' && !trackingDisabled) {
      let action;
      if (tracking?.action?.startsWith('drp_')) action = tracking.action;
      else if (tracking?.action) action = `btn_${tracking.action}`;
      else if (typeof children === 'string') action = `btn_${children}`;
      else {
        // Construct default unique id from props
        const actionPayload = {
          className: className ?? '',
          goto: goto ?? '',
          form: passedProps.form ?? '',
          icon: icon ?? ''
        };

        action = 'btn_' + btoa(JSON.stringify(actionPayload));
      }

      trackEvent({
        ...tracking,
        action_name: tracking?.action_name ?? children?.toString(),
        action,
        event: EbTrackingEventType.ui_click
      });
    }

    // Optional handler
    if (onClick) {
      onClick(event);
    }

    // Update the router
    if (goto) {
      navigate(goto);
    }
  };

  // Vars
  const Component = ButtonTypes[type];
  // eslint-disable-next-line no-negated-condition
  const widthProp = Component !== ButtonTypes.icon ? { isFullWidth } : {};

  const buttonProps = {
    children,
    className,
    disabled: isDisabled,
    hoverText,
    icon: icon ?? '',
    onClick: click,
    type,
    ...passedProps,
    ...widthProp
  };

  // Render
  return <Component {...buttonProps} />;
};

export { Button };
